import React, { useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Bedtime, CalendarMonth } from "@mui/icons-material";
import keysIcon from "../../assets/icons/svg/keys.svg";
import { useSelector } from "react-redux";
import ListingRentBuyItem from "./ListingRentBuyItem";
import ListingHotelItem from "./ListingHotelItem";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import ListingAirbnbItem from "./ListingAirbnbItem";

const ListingsView = ({ toggleAvailabilityView }) => {
  const { cityCenter, fetchCityCenter } = useGooglePlaces();
  const filterState = useSelector((state) => state.filter);
  const listings = useSelector((state) => state.hotels?.hotels);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;

  useEffect(() => {
    fetchCityCenter(filterState?.searchText?.text);
  }, [filterState.searchText]);

  const typeMapData = {
    hotels: {
      text: "Per night",
      icon: (
        <LocationOnIcon
          sx={{
            color: "#29A8E2",
            fontSize: 20,
          }}
        />
      ),
      color: "#29A8E2",
    },
    Hotel: {
      text: "Per night",
      icon: (
        <LocationOnIcon
          sx={{
            color: "#29A8E2",
            fontSize: 20,
          }}
        />
      ),
      color: "#29A8E2",
    },
    monthlyStay: {
      text: "Short term",
      icon: (
        <Bedtime
          sx={{
            color: "#D61D5C",
            fontSize: 20,
          }}
        />
      ),
      color: "#D61D5C",
    },
    rent: {
      text: "Rent",
      icon: (
        <CalendarMonth
          sx={{
            color: "#F6AA3C",
            fontSize: 20,
          }}
        />
      ),
      color: "#F6AA3C",
    },
    buy: {
      text: "Buy",
      icon: <img src={keysIcon} alt="key" />,
      color: "#2ab529",
    },
  };

  return (
    <div className="flex-1 overflow-y-auto  mb-[5rem]">
      {listings?.features?.length > 0 &&
        listings.features.map((list, index) => (
          <div
            key={`${list?.id}${index}`}
            className="flex bg-[#fff] rounded-lg shadow-lg border border-solid border-[#E7E7E7] overflow-hidden p-2"
          >
            {["rent", "buy"].includes(list?.properties?.listingKey) ? (
              <ListingRentBuyItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
              />
            ) : list?.properties?.listingKey === "monthlyStay" ? (
              <ListingAirbnbItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
              />
            ) : (
              <ListingHotelItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
              />
            )}
          </div>
        ))}
    </div>
  );
};
export default ListingsView;
