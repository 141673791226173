import React from "react";

const ListingAvailability = ({ listingDetail }) => {
  const { hotel_name, address, price, room_info, checkin, checkout } =
    listingDetail;

  return (
    <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg border">
      {/* Room Details */}
      {room_info &&
        room_info.map((room, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded mb-4">
            <h3 className="font-semibold text-lg">{room?.room_name}</h3>
            <p className="text-gray-700">
              Max Occupancy: {room?.max_occupancy}
            </p>
            <p className="text-gray-700">{room?.room_description}</p>
            <div className="flex justify-between items-center mt-2">
              <span className="font-bold text-xl">
                {room?.price?.amount} {room?.price?.currency}
              </span>
              <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                Book Now
              </button>
            </div>
          </div>
        ))}

      {/* Price & Dates */}
      <div className="flex justify-between items-center mt-6 p-4 bg-gray-50 rounded">
        <div>
          <p className="font-semibold">
            Check-in: <span className="text-gray-700">{checkin}</span>
          </p>
          <p className="font-semibold">
            Check-out: <span className="text-gray-700">{checkout}</span>
          </p>
        </div>
        <div>
          <span className="font-bold text-2xl">
            {price?.amount} {price?.currency}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListingAvailability;
