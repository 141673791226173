function CustomChip({ text, icon, chipBgClr = "#E7E7E7" }) {
  return (
    <div
      style={{ background: chipBgClr, border: `1px solid #E7E7E7` }}
      className="flex items-center justify-center py-[10px] px-[20px] shadow-md rounded-10"
    >
      {icon && <div>{icon}</div>}
      <div className="text-[#413D3D] font-thin text-[18px] font-sans">
        {text}
      </div>
    </div>
  );
}
export default CustomChip;
