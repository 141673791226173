import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PublicRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>loading...</div>;
  }
  return !isAuthenticated ? children : <Navigate to="/home" />;
};

export default PublicRoute;
