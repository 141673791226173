import React, { useState, useRef, useCallback, useMemo } from "react";
import { Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useSelector } from "react-redux";
import "./Map.css";
import { Button } from "@mui/material";
import BottomSheetTabs from "../../BottomSheetTabs";
import LMGeojson from "./LMGeojson";
import DrawPlaces from "./DrawPlaces";
import Tools from "./Tools";
import DistanceSlider from "../../DistanceSlider";
import DrawingOverlay from "./DrawingOverlay";
import { Polyline } from "./Polyline";
import NearbyPlacesFetcher from "./NearbyPlaceFetcher";
import CurrentLocationMarker from "./CurrentLocationMarker";
import mapType from "../../../assets/icons/svg/map-type.svg";
import fullscreenIcon from "../../../assets/icons/svg/fullscreen.svg";
import { Check } from "@mui/icons-material";
import MapWith3DMarker from "../../../views/Area/MapWith3DMarker";

const LMMap = ({ isListingScreen = false }) => {
  const center = useSelector((state) => state.map.center);
  const zoom = useSelector((state) => state.map.zoom);
  const mapID = useSelector((state) => state.map.mapID);
  const [isRadiusActive, setIsRadiusActive] = useState(false);
  const { hotels } = useSelector((state) => state.hotels);
  const filterState = useSelector((state) => state.filter);
  const [range, setRange] = useState(0);
  const [open, setOpen] = useState(false);
  const [isShowThreeDMap, setThreeDMap] = useState(false);
  const [isShowMapType, setShowMapType] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const containerRef = useRef(null);
  const [beRadius, setBeRadius] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const [path, setPath] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectBounds, setSelectBounds] = useState(null);
  const map = useMap();
  const markerLib = useMapsLibrary("marker"); // Load the marker library
  const activeItem = hotels?.features?.[0];

  const handleApply = useCallback((paths) => {
    setDrawing(false);
    setPath(paths);
  }, []);

  const handleCancel = useCallback(() => {
    setDrawing(false);
  }, []);

  const handleOpen = useCallback(() => setOpen(true), []);
  const showMapType = () => setShowMapType(!isShowMapType);
  const handleClose = useCallback(() => setOpen(false), []);

  const onRadiusCheckBoxChange = useCallback(
    (isChecked) => {
      if (!isChecked) {
        map.setCenter({
          lat: filterState?.latitude,
          lng: filterState?.longitude,
        });
        map.setZoom(10);
        setSelectBounds(null);
      }
      setIsRadiusActive(isChecked);
    },
    [filterState, map]
  );

  const removeBoundary = useCallback(() => {
    setPath(null);
    setSelectBounds(null);
  }, []);

  const handleBoundsChange = useCallback((bounds) => {
    setSelectBounds(bounds);
  }, []);

  const onPlacesFetched = useCallback((fetchedPlaces) => {
    setPlaces(fetchedPlaces);
  }, []);

  function birdEyeRadiusChange(radius) {
    setBeRadius(radius);
  }

  function handleThreeDMap(value) {
    setThreeDMap(value);
  }

  const memoizedPlaces = useMemo(() => places, [places]);
  const mapCore = useMapsLibrary("core");
  console.log("3DDDD", activeItem);
  return (
    <div
      className="relative flex flex-col w-full h-full my-map rounded-10"
      ref={containerRef}
    >
      {/* Top bar with divs 1, 2, 3 */}
      {isListingScreen ? (
        <React.Fragment>
          <div
            className={`flex justify-end items-center bg-transparent absolute  z-50 w-full top-[90px] right-2`}
          >
            <div
              className="bg-[#fff] rounded-full p-2 text-center cursor-pointer w-[25px] h-[25px] flex justify-center items-center"
              onClick={showMapType}
            >
              <img alt="fullscreen" src={mapType} />
            </div>
          </div>
          <div
            className={`flex items-center bg-transparent absolute  z-10 w-full bottom-[20px]`}
          >
            <div className="flex items-center w-full gap-30 justify-center">
              <Tools
                clear={drawing}
                onDrawChange={setDrawing}
                onRadiusChange={onRadiusCheckBoxChange}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div
          className={`flex items-center bg-transparent absolute  z-10 w-full top-[10px]`}
        >
          <div className="w-[40%]"></div>
          <div className="flex items-center w-[50%] gap-30">
            <Tools
              clear={drawing}
              onDrawChange={setDrawing}
              onRadiusChange={onRadiusCheckBoxChange}
            />
            <div className="flex-none mr-2">
              <div
                className="bg-[#fff] rounded-full p-2 text-center cursor-pointer w-[25px] h-[25px] flex justify-center items-center"
                onClick={showMapType}
              >
                <img alt="fullscreen" src={mapType} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="z-10 bottom-8 right-2 absolute">
        <div
          className="bg-[#FC848A] rounded-full p-2 text-center cursor-pointer mb-2 w-[25px] h-[25px] flex justify-center items-center"
          onClick={handleOpen}
        >
          <Check
            style={{ fontSize: "32px", fontWeight: "bold", color: "#fff" }}
          />
        </div>
      </div> */}

      {isRadiusActive && (
        <DistanceSlider
          onRadiusValue={handleBoundsChange}
          onPlacesUpdate={setSearchText}
        />
      )}

      {path && path.length && (
        <Button
          variant="contained"
          className="z-10 top-[88px] right-[70px]"
          sx={{
            borderRadius: "5px",
            padding: "5px 5px",
            fontSize: "10px",
            backgroundColor: "#f44336",
            ":hover": { backgroundColor: "#f44336" },
            position: "absolute",
          }}
          onClick={removeBoundary}
        >
          Remove Boundary
        </Button>
      )}
      {mapCore && !isShowThreeDMap && (
        <Map
          disableDefaultUI={true}
          fullscreenControl={true}
          streetViewControl={false}
          zoomControl={false}
          defaultCenter={center}
          defaultZoom={zoom}
          mapId={mapID}
          zoomControlOptions={{
            position: mapCore.ControlPosition.RIGHT_CENTER,
          }}
          streetViewControlOptions={{
            position: mapCore.ControlPosition.RIGHT_CENTER,
          }}
        >
          {markerLib && (
            <CurrentLocationMarker
              markerLib={markerLib}
            ></CurrentLocationMarker>
          )}

          <NearbyPlacesFetcher
            placeType={searchText}
            onPlacesFetched={onPlacesFetched}
          />
          {hotels && <LMGeojson geojson={hotels} selectBounds={selectBounds} />}
          {memoizedPlaces.length > 0 && markerLib && (
            <DrawPlaces places={memoizedPlaces} markerLib={markerLib} />
          )}
          <DrawingOverlay
            drawing={drawing}
            onApply={handleApply}
            onCancel={handleCancel}
          />
          {path && path.length && (
            <Polyline
              path={path}
              onBoundsChange={handleBoundsChange}
              options={{
                strokeColor: "blue",
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
          )}
        </Map>
      )}
      {isShowThreeDMap && (
        <div className="h-full w-full">
          <MapWith3DMarker
            lat={activeItem?.properties?.latitude}
            lng={activeItem?.properties?.longitude}
            defaultRange={range}
            radiusChange={birdEyeRadiusChange}
            tiltChange={() => console.log()}
          ></MapWith3DMarker>
        </div>
      )}

      <BottomSheetTabs
        open={open}
        isShowMapType={isShowMapType}
        onClose={handleClose}
        onPlacesUpdate={setSearchText}
        handleThreeDMap={handleThreeDMap}
        isShowThreeDMap={isShowThreeDMap}
        isListingScreen={isListingScreen}
      />
    </div>
  );
};

export default LMMap;
