import React, { useEffect } from "react";
import { Card, CardMedia } from "@mui/material";
import { trimString } from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import { Close } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";

const PlacesListView = ({ place, handleMarkerClick, distance }) => {
  const { rating, name } = place;
  const { fetchPlaceDetails, placeDetails } = useGooglePlaces();

  useEffect(() => {
    if (place) {
      fetchPlaceDetails(place?.place_id, {
        latitude: place?.latitude,
        longitude: place?.longitude,
        propertyName: place?.name,
      });
    }
  }, [place]);

  console.log("placesss", placeDetails);
  return (
    <div className="w-full h-full relative">
      <div className="flex px-4 justify-between items-center">
        <div className="flex gap-2 items-center">
          <div className="font-sans font-medium text-[24px]">
            {trimString(name, 10)}
          </div>
          <div className="flex items-center font-sans font-normal text-[18px]">
            {rating} <StarIcon fontSize="small" style={{ color: "#FFD700" }} />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="font-sans font-normal text-[18px]">{distance} km</div>
          <div
            onClick={() => handleMarkerClick(null)}
            className="flex items-center cursor-pointer justify-center rounded-full border border-solid w-[20px] h-[20px]"
          >
            <Close sx={{ fontSize: 15 }} />
          </div>
        </div>
      </div>
      <div className="flex-1 h-full ml-6 items-center flex-col w-[100%] overflow-y-auto hide-scrollbar">
        <div className="w-[90%] flex flex-col gap-1 items-center">
          {placeDetails?.photos?.length > 0 &&
            placeDetails.photos.map((photo, index) => {
              return (
                <div
                  className=" mb-2 w-full  max-h-[314px] max-w-[390px]"
                  key={index}
                >
                  <Card
                    sx={{
                      borderRadius: "5px",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "390px",
                        height: "314px",
                      }}
                      image={photo?.getUrl()}
                      alt={`pl-${index}`}
                    />
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
      <div className="absolute bottom-10 left-10">
        <div className="bg-[#fff] p-2 rounded-10">
          <div>{placeDetails?.reviews?.[1]?.author_name}</div>
          <div className="flex gap-2">
            {placeDetails?.reviews?.[1]?.rating}
            {placeDetails?.reviews?.[1]?.rating &&
              [...Array(placeDetails?.reviews?.[1]?.rating)].map((_, i) => (
                <StarIcon
                  key={i}
                  fontSize="small"
                  style={{ color: "#FFD700" }}
                />
              ))}
          </div>
          <div>{trimString(placeDetails?.reviews?.[1]?.text, 20)}</div>
        </div>
      </div>
      <div className="absolute bottom-5 right-7">
        <div className="bg-[#fff] p-2 rounded-10">
          <div>{placeDetails?.reviews?.[0]?.author_name}</div>
          <div className="flex gap-2">
            {placeDetails?.reviews?.[0]?.rating}
            {placeDetails?.reviews?.[0]?.rating &&
              [...Array(placeDetails?.reviews?.[0]?.rating)].map((_, i) => (
                <StarIcon
                  key={i}
                  fontSize="small"
                  style={{ color: "#FFD700" }}
                />
              ))}
          </div>
          <div>{trimString(placeDetails?.reviews?.[0]?.text, 20)}</div>
        </div>
      </div>
    </div>
  );
};

export default PlacesListView;
