import React, { useState, useEffect } from "react";
import { Box, Typography, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiSlider from "../MuiSlider";
import { useSelector } from "react-redux";
import { listType } from "../../common/Helpers";

const WhiteSlider = styled(Slider)({
  color: "#fff", // Set slider color to white
  "& .MuiSlider-thumb": {
    borderRadius: "50%", // Optional: round thumb
  },
  "& .MuiSlider-track": {
    borderColor: "#fff", // Track color
  },
  "& .MuiSlider-rail": {
    color: "#fff", // Rail color (unfilled portion)
  },
});

const BudgetRange = ({
  initialValue = [50, 200],
  onChange,
  title,
  minKey,
  maxKey,
  minValue,
  maxValue,
  filterKey,
}) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [value, setValue] = useState(initialValue);
  const [updatedFilters, setFilters] = useState(
    filters?.[filterKey]?.categories_filter_ids || ""
  );

  useEffect(() => {
    setValue(initialValue); // Initialize with correct state
  }, [initialValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange({ [minKey]: newValue[0], [maxKey]: newValue[1] });
    }
  };

  function operateFilter(e, value) {
    let currentCategories = updatedFilters;
    let categoriesArray = currentCategories ? currentCategories.split(",") : [];
    categoriesArray = categoriesArray.filter(
      (category) => !category.includes("price::USD-")
    );
    if (value[0] > minValue || value[1] < maxValue) {
      categoriesArray.push(`price::USD-${value[0]}-${value[1]}`);
    }
    const updatedCategories = categoriesArray.join(",");
    setFilters(updatedCategories);
    if (onChange) {
      onChange({ categories_filter_ids: updatedCategories });
    }
  }

  return (
    <React.Fragment>
      <Box className="p-4 mb-2">
        <div className="mb-2 text-[#000]/80 font-semibold">{title}</div>
        <Box className="flex justify-between text-sm text-[#000]/80 ">
          <span>Low</span>
          <span>High</span>
        </Box>
        <Box className="relative">
          <Slider
            value={value}
            onChange={handleChange}
            onChangeCommitted={operateFilter}
            valueLabelDisplay="off"
            min={minValue || 0}
            max={maxValue || 250}
            step={1}
            sx={{
              color: listType[filterKey]?.color || listType.Hotel.color,
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
                backgroundColor:
                  listType[filterKey]?.color || listType.Hotel.color,
                "&:before": {
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                },
              },
              "& .MuiSlider-track": {
                backgroundColor:
                  listType[filterKey]?.color || listType.Hotel.color,
              },
              "& .MuiSlider-rail": {
                backgroundColor:
                  listType[filterKey]?.color || listType.Hotel.color,
              },
            }}
          />
          {value.map((val, index) => (
            <Box
              key={index}
              className={`absolute flex justify-center items-center ${
                listType[filterKey]?.bg || listType.Hotel.bg
              } text-white py-1 px-2 rounded-md shadow-md text-xs`}
              sx={{
                bottom: "-20px",
                left: `${((val - minValue) / (maxValue - minValue)) * 100}%`,
                transform: "translateX(-50%)",
              }}
            >
              ${val === maxValue ? `${val}+` : val}
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BudgetRange;
