import React, { useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import universities from "./university.json";
import companies from "./companies.json";
import myLocation from "./location.json";

const SearchableList = ({ onSelectMajorLocation }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchTerm(""); // Reset search when switching tabs
  };

  const handleSearch = () => {
    // This function triggers when search is clicked or "Enter" is pressed
  };

  const getFilteredItems = () => {
    let list = companies;
    if (activeTab === 1) {
      list = universities;
    }
    if (activeTab === 2) {
      list = myLocation;
    }
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div style={{ padding: "0px", width: "100%", margin: "0 auto" }}>
      <div className="px-2">
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    backgroundColor: "#007bff", // Blue background
                    color: "#fff", // White icon color
                    "&:hover": { backgroundColor: "#0056b3" }, // Darker blue on hover
                    borderRadius: 0,
                    borderTopRightRadius: 18,
                    borderBottomRightRadius: 16,
                    padding: "8px", // Adjust padding for size
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              paddingRight: 0, // Set right padding of TextField to 0
              borderRadius: 4,
            },
          }}
          style={{ marginBottom: "10px" }}
        />
      </div>

      <AppBar
        position="static"
        style={{ background: "#007bff", boxShadow: "none" }} // Blue background for AppBar
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          indicatorColor="primary"
          textColor="inherit"
        >
          <Tab label="Work" />
          <Tab label="Education" />
          <Tab label="My location" />
        </Tabs>
      </AppBar>

      <Box style={{ marginTop: "20px" }}>
        <Grid container spacing={3} justifyContent="center">
          {getFilteredItems().map((item, index) => (
            <Grid item key={index}>
              <Avatar
                onClick={() => onSelectMajorLocation(item)}
                src={item.logo}
                alt={item.name}
                sx={{ width: 60, height: 60, cursor: "pointer" }}
                variant="circular"
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default SearchableList;
