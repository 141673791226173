import React from "react";
import MapSearch from "../../components/LeftCol/MapSearch";
import LMMap from "../../components/LeftCol/Map/Map";
import ImageSlider from "../../components/Listing/ImageSlider";
import HotelInfo from "../../components/Listing/HotelInfo";
import AirbnbInfo from "../../components/Listing/AirbnbInfo";
import IdealistaInfo from "../../components/Listing/IdealistaInfo";
import IdealistaImageSlider from "../../components/Listing/IdealistaImageSlider";

const ListingOverview = ({
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  toggleFullscreen,
  isFullScreen,
}) => {
  return (
    <div
      className={`flex ${isFullScreen && "justify-center items-center w-full"}`}
    >
      <div className={`${isFullScreen ? "w-full" : "w-[60%] max-w-[60%]"}`}>
        {["rent", "buy"].includes(property?.properties?.listingKey) ? (
          <IdealistaInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
          />
        ) : property?.properties?.listingKey === "monthlyStay" ? (
          <AirbnbInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
          />
        ) : (
          <HotelInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
          />
        )}
        {["rent", "buy"].includes(property?.properties?.listingKey) ? (
          <IdealistaImageSlider
            listingMedia={listingMedia}
            toggleFullscreen={toggleFullscreen}
            isFullscreen={isFullScreen}
          />
        ) : (
          <ImageSlider
            listingMedia={listingMedia}
            toggleFullscreen={toggleFullscreen}
            isFullscreen={isFullScreen}
          />
        )}
      </div>
      {!isFullScreen && (
        <div className={`w-[40%] relative`}>
          <div className="absolute left-4 top-[15px] z-50 w-full">
            <div className="w-full max-w-[400px]">
              <MapSearch onPlaceChanged={() => console.log("Place changed")} />
            </div>
          </div>
          <LMMap isListingScreen={true} />
        </div>
      )}
    </div>
  );
};

export default ListingOverview;
