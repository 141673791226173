import React, { Fragment } from "react";
import { ReactComponent as Microsoft } from "../../assets/icons/svg/microsoft.svg";
import { ReactComponent as Google } from "../../assets/icons/svg/google.svg";
import { ReactComponent as Apple } from "../../assets/icons/svg/apple.svg";

function SocialMediaProvider({ loginWithProvider }) {
  return (
    <Fragment>
      <Microsoft
        onClick={() => loginWithProvider("azure")}
        className="w-[30px] h-[30px] cursor-pointer animate-flipRightToLeft"
      />
      <Google
        onClick={() => loginWithProvider("google")}
        className="w-8 h-8  cursor-pointer animate-flipRightToLeft"
      />
      <Apple
        onClick={() => loginWithProvider("apple")}
        className="w-8 h-8  cursor-pointer animate-flipRightToLeft"
      />
    </Fragment>
  );
}

export default SocialMediaProvider;
