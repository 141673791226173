import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingStore: null,
  userStore: {
    favoriteList: [],
    myLocations: [],
    userStarList: {
      placeTypes: [
        { type: "cafe" },
        { type: "subway_station" },
        { type: "park" },
        { type: "school" },
        { type: "hospital" },
      ],
    },
  },
  blackList: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserStore: (state, action) => {
      state.userStore = { ...state.userStore, ...action.payload };
    },
    setRequestStatus: (state, action) => {
      state.loadingStore = { ...state.loadingStore, ...action.payload };
    },
    setBlackList: (state, action) => {
      state.blackList = { ...state.blackList, ...action.payload };
    },
  },
});
export const { setUserStore, setRequestStatus, setBlackList } =
  userSlice.actions;
export default userSlice.reducer;
