import {
  getHotelsUrl,
  getShorttermUrl,
  getRentUrl,
  getBuyUrl,
} from "@/utils/apis/hotel";
import httpClient from "@/utils/apis/httpClient";
import { setHotels, setRequestStatus } from "@/store/Listing/listingReducers";
import {
  setHotelFilters,
  setShortTermFilters,
  setBuyFilters,
  setRentFilters,
} from "@/store/Filter/filterReducers";
import {
  getSavedFilter,
  listingDataMap,
  listingDetailMap,
} from "../../common/Helpers";
import store from "../index";
import {
  getMonthlyStayUrl,
  listingDetailUrl,
  listingInfoPriceUrl,
  listingNearbyPlaceUrls,
  listingOverviewUrl,
} from "../../utils/apis/hotel";
import {
  setListingDetail,
  setListingInfoPrice,
  setListingMedia,
  setListingMediaVideo,
  setListingOverview,
  setNearbyPlace,
  setSwipeActiveList,
} from "./listingReducers";
import { setMonthlyFilters } from "../Filter/filterReducers";
import { setActiveStepAction } from "../Map/mapActions";

export const getHotelsAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(getHotelsUrl, {
      ...params,
      page_number: 0,
    });
    dispatch(setHotels(appendResult(response, params, "Hotel", true)));
    dispatch(setHotelFilters({ isRefatched: false }));
    if (response?.data?.totalCount > 20) {
      let response2 = await httpClient.post(getHotelsUrl, {
        ...params,
        page_number: 1,
      });
      dispatch(setHotels(appendResult(response2, params, "Hotel")));
      if (response2?.data?.totalCount > 20) {
        let response3 = await httpClient.post(getHotelsUrl, {
          ...params,
          page_number: 2,
        });
        dispatch(setHotels(appendResult(response3, params, "Hotel")));
      }
    }
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
    dispatch(setHotelFilters({ isRefatched: false }));
  }
};
export const getShorttermAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(getShorttermUrl, {
      ...params,
      page_number: 0,
    });
    dispatch(setHotels(appendResult(response, params, "Shortterm", true)));
    dispatch(setShortTermFilters({ isRefatched: false }));
    if (params?.searchText?.text) {
      localStorage.setItem("searchText", JSON.stringify(params?.searchText));
    }
    if (response?.data?.totalCount > 20) {
      let response2 = await httpClient.post(getShorttermUrl, {
        ...params,
        page_number: 1,
      });
      dispatch(setHotels(appendResult(response2, params, "Shortterm")));
      if (response2?.data?.totalCount > 20) {
        let response3 = await httpClient.post(getShorttermUrl, {
          ...params,
          page_number: 2,
        });
        dispatch(setHotels(appendResult(response3, params, "Shortterm")));
      }
    }
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
    dispatch(setShortTermFilters({ isRefatched: false }));
  }
};
export const getMonthlyStayAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(getMonthlyStayUrl, {
      ...params,
    });
    dispatch(setHotels(appendResult(response, params, "monthlyStay", true)));
    dispatch(setMonthlyFilters({ isRefatched: false }));
    if (params?.searchText?.text) {
      localStorage.setItem("searchText", JSON.stringify(params?.searchText));
    }
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
    dispatch(setMonthlyFilters({ isRefatched: false }));
  }
};
export const getRentAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(getRentUrl, params);
    dispatch(setHotels(appendResult(response, params, "rent", true)));
    dispatch(setRentFilters({ isRefatched: false }));
    if (params?.searchText?.text) {
      localStorage.setItem("searchText", JSON.stringify(params?.searchText));
    }
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
    dispatch(setRentFilters({ isRefatched: false }));
  }
};
export const getBuyAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(getBuyUrl, params);
    dispatch(setHotels(appendResult(response, params, "buy", true)));
    dispatch(setBuyFilters({ isRefatched: false }));
    if (params?.searchText?.text) {
      localStorage.setItem("searchText", JSON.stringify(params?.searchText));
    }
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
    dispatch(setBuyFilters({ isRefatched: false }));
  }
};
export const getListingDetailAction =
  (params, listingType, isSaveListing = false) =>
  async (dispatch) => {
    dispatch(setRequestStatus({ isLoading: true }));
    dispatch(setListingMediaVideo([]));
    try {
      let response = await httpClient.post(listingDetailUrl, {
        ...params,
        listingType,
      });
      if (listingType === "rent" || listingType === "buy") {
        dispatch(setListingMedia(response?.data?.multimedia?.images));
        dispatch(setListingMediaVideo(response?.data?.multimedia?.videos));
        dispatch(
          setNearbyPlace({
            neighbourhood: response?.data?.ubication?.administrativeAreaLevel3,
          })
        );
      }
      if (listingType === "monthlyStay") {
        dispatch(
          setListingMedia(response?.data?.sections?.hero?.previewImages)
        );
        dispatch(
          setNearbyPlace({
            neighbourhood:
              response?.data?.sections?.location?.subtitle ||
              response?.data?.sections?.location?.previewLocationDetails?.[0]
                ?.title,
          })
        );
      }
      dispatch(setListingDetail(listingDetailMap(response?.data, listingType)));
      if (isSaveListing) {
        setActiveStepAction(dispatch, params?.listingId);
      }
      dispatch(setRequestStatus({ isLoading: false }));
    } catch (error) {
      console.error(error);
      dispatch(setRequestStatus({ isLoading: false }));
    }
  };
export const getListingOverviewAction =
  (params, listingType) => async (dispatch) => {
    dispatch(setRequestStatus({ isLoading: true }));
    try {
      let response = await httpClient.post(listingOverviewUrl, {
        ...params,
        listingType,
      });
      dispatch(
        setListingOverview({ ...response?.data, listingId: params?.listingId })
      );
      dispatch(setRequestStatus({ isLoading: false }));
    } catch (error) {
      console.error(error);
      dispatch(setRequestStatus({ isLoading: false }));
    }
  };
export const getListingPriceAction =
  (params, listingType) => async (dispatch) => {
    dispatch(setRequestStatus({ isLoading: true }));
    try {
      let response = await httpClient.post(listingInfoPriceUrl, {
        ...params,
        listingType,
      });
      dispatch(
        setListingInfoPrice({ ...response?.data, listingId: params?.listingId })
      );
      dispatch(setRequestStatus({ isLoading: false }));
    } catch (error) {
      console.error(error);
      dispatch(setRequestStatus({ isLoading: false }));
    }
  };
export const getlistingNearbyPlaceAction = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(listingNearbyPlaceUrls, params);
    dispatch(
      setNearbyPlace({
        ...response?.data,
        neighbourhood: response?.data?.landmarks?.populars?.[0]?.landmark_name,
      })
    );
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
export const getlistingPhotosAction = (id) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(`api/listing/${id}/images`);
    dispatch(setListingMedia(response?.data));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    console.error(error);
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

function mapResult(result, accommodation_type_name) {
  let blockList = store.getState()?.user?.blackList?.blackList?.items;
  let filteredResult = result;
  if (blockList?.length > 0 && result?.features?.length > 0) {
    const blockIds = blockList.map((item) => Number(item.listingId));
    filteredResult = {
      features: result?.features.filter(
        (item) => !blockIds.includes(Number(item.id))
      ),
    };
  }

  return filteredResult?.features?.length > 0
    ? {
        type: "FeatureCollection",
        features: filteredResult?.features.map((fe) => ({
          ...fe,
          id: fe?.id || fe?.properties?.propertyCode,
          properties: listingDataMap({
            ...fe.properties,
            accommodation_type_name:
              fe?.properties?.accommodation_type_name ||
              accommodation_type_name,
            listingKey:
              fe?.properties?.accommodation_type_name ||
              accommodation_type_name,
          }),
        })),
      }
    : result;
}

function appendResult(
  response,
  params,
  accommodation_type_name,
  isFirstResponse
) {
  let previousSearchText = localStorage.getItem("searchText");
  if (previousSearchText && previousSearchText !== "undefined") {
    previousSearchText = JSON.parse(previousSearchText);
  }
  if (
    !previousSearchText ||
    previousSearchText?.text !== params?.searchText?.text
  ) {
    localStorage.setItem("searchText", JSON.stringify(params?.searchText));
    return mapResult(response?.data, accommodation_type_name);
  }
  let previousHotels = store.getState()?.hotels?.hotels;
  let result = previousHotels;
  if (previousHotels?.features?.length > 0) {
    previousHotels = isFirstResponse
      ? {
          type: "FeatureCollection",
          features: previousHotels?.features.filter(
            (feat) =>
              feat?.properties?.accommodationType !== accommodation_type_name
          ),
        }
      : previousHotels;
    result = {
      type: "FeatureCollection",
      features: [...previousHotels?.features],
    };
    if (response?.data?.features?.length > 0) {
      result = {
        type: "FeatureCollection",
        features: [...result?.features, ...response?.data?.features],
      };
    }
  } else {
    if (
      (!previousHotels?.features || previousHotels?.features?.length <= 0) &&
      response?.data?.features?.length > 0
    ) {
      result = response?.data;
    }
  }
  result = mapResult(result, accommodation_type_name);
  return result;
}
export const removeListingByType = (listingType) => (dispatch) => {
  const keyMap = {
    hotels: "Hotel",
    shortTerm: "Shortterm",
    monthlyStay: "monthlyStay",
    rent: "rent",
    buy: "buy",
  };
  const listings = store.getState()?.hotels?.hotels;
  let filterListings = [];
  let savedFilters = getSavedFilter();
  if (listings?.features?.length > 0) {
    filterListings = listings?.features.filter(
      (list) => list?.properties?.accommodationType !== keyMap?.[listingType]
    );
  }
  if (savedFilters) {
    let selectedMainFilters = savedFilters?.selectedMainFilters
      ? savedFilters?.selectedMainFilters.filter((sF) => sF !== listingType)
      : [];
    localStorage.setItem(
      "userFilter",
      JSON.stringify({
        ...savedFilters,
        selectedMainFilters: selectedMainFilters,
      })
    );
  }
  dispatch(setHotels({ type: "FeatureCollection", features: filterListings }));
};
export const swipeScreenActiveListing = (listing) => (dispatch) => {
  dispatch(setSwipeActiveList(listing));
};
