import React, { useState } from "react";
import {
  TextField,
  Tabs,
  Tab,
  Card,
  CardContent,
  Paper,
  IconButton,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const InputSearch = () => {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
        width: 400,
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
      />
    </Paper>
  );
};
const PlacesTab = ({ placeDetails, places }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [lastDirection, setLastDirection] = useState();

  const swiped = (direction, nameToDelete) => {
    console.log("removing: " + nameToDelete);
    setLastDirection(direction);
  };

  const outOfFrame = (name) => {
    console.log(name + " left the screen!");
  };
  const [activeImage, setImage] = React.useState(
    placeDetails?.photos?.[0]?.getUrl() || ""
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="flex flex-col h-[80vh] bg-gray-100">
      {/* Header */}
      <div className="flex items-center bg-[#193569] text-white px-2 ">
        <div className="flex justify-center p-4 max-w-[400px]">
          <InputSearch />
        </div>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit" // This allows overriding the default color with custom styles
          indicatorColor="secondary" // We'll make the indicator white using custom styles
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "white", // Set the indicator color to white
            },
            "& .MuiTab-root": {
              color: "white", // Tab text color (inactive state)
            },
            "& .Mui-selected": {
              color: "white", // Tab text color when selected
            },
          }}
        >
          <Tab
            sx={{
              margin: "0px 120px !important", // Adds 20px gap between tabs
              color: "white",
              "&.Mui-selected": {
                color: "white",
              },
            }}
            label="Our Combinations"
          />
          <Tab label="Mark Specific Places" />
        </Tabs>
      </div>
      {/* Main Section */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Sidebar */}
        <div className="w-1/5 bg-gray-200 p-4 overflow-y-auto">
          <div className="flex flex-col space-y-4">
            {placeDetails?.photos?.length > 0
              ? placeDetails.photos.map((photo, index) => (
                  <img
                    onClick={() => {
                      setImage(photo.getUrl());
                    }}
                    key={index}
                    src={photo.getUrl() || ""}
                    alt={`Place ${index + 1}`}
                    className="rounded-md shadow-md w-full h-full max-w-[250px] max-h-[150px] object-fill"
                  />
                ))
              : null}
          </div>
        </div>

        {/* Middle Section */}
        <div className="w-1/2 bg-white overflow-y-auto h-full">
          <div className="relative w-full h-full">
            {places?.length > 0 &&
              places.map((place, index) => (
                <img
                  key={index}
                  src={place?.photos?.[0]?.getUrl()}
                  alt="Main"
                  className="rounded-md shadow-md w-full h-full max-h-[80vh]"
                />
              ))}
          </div>
        </div>

        {/* Right Sidebar */}
        <div className="w-1/3 bg-gray-100 p-4 overflow-y-auto">
          <Card className="mb-4">
            <CardContent>
              <h2 className="font-bold text-lg">Fabrica Coffee</h2>
              <p className="text-yellow-500">4.8 ★</p>
              <p>
                AI recently visited this café and was impressed by the quality
                and delicacy...
              </p>
            </CardContent>
          </Card>

          <div>
            <img
              src="https://via.placeholder.com/200x150?text=Map"
              alt="Map"
              className="rounded-md shadow-md w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlacesTab;
