export const hotelFacilitiesMapping = {
  422: "Free Wi-Fi",
  462: "Parking",
  22: "Air Conditioning",
  454: "Non-Smoking Rooms",
  444: "Room Service",
  184: "24-hour Front Desk",
  435: "Bar",
  500: "Fitness Center",
  7: "Swimming Pool",
  437: "Spa",
  20: "Gym",
  158: "Restaurant",
  501: "Pet-Friendly",
  203: "Laundry Service",
  494: "Shuttle Service",
  488: "Business Center",
  64: "Elevator",
  101: "Airport Shuttle",
  449: "Free Parking",
  459: "Safety Deposit Box",
  160: "Concierge",
  2: "Wheelchair Accessible",
  96: "Hairdryer",
  426: "Daily Housekeeping",
  466: "Sauna",
  133: "Conference Room",
  107: "Family Rooms",
  3: "Babysitting",
  423: "Laundry",
  440: "Terrace",
  411: "Garden",
  450: "Heating",
  210: "Library",
  486: "Currency Exchange",
  48: "Car Rental",
  495: "Soundproof Rooms",
  468: "Steam Room",
  491: "Fire Extinguishers",
  81: "Picnic Area",
  439: "Luggage Storage",
  47: "Tour Desk",
  457: "Fax/Photocopying",
  28: "Free Breakfast",
  11: "Mini Bar",
  181: "Ironing Facilities",
  124: "Bathrobe",
  451: "Non-smoking throughout",
  445: "VIP Room Facilities",
  455: "Private Check-in/Check-out",
  441: "Bicycle Rental",
  109: "Coffee/Tea Maker",
};
export const rentBuyTags = [
  "roomNumber",
  "bathNumber",
  "constructedArea",
  "floor",
  "isStudio",
  "isPenthouse",
  "swimmingPool",
  "lift",
  "garden",
];
