export const hotelOptions = [
  { label: "Price: Lowest First", value: "price_lowest" },
  { label: "Price: Highest First", value: "price_highest" },
  { label: "Property Rating: High to Low", value: "rating_high_to_low" },
  { label: "Property Rating: Low to High", value: "rating_low_to_high" },
  {
    label: "Proximity to personal location: Closest First",
    value: "proximity_personal_closest",
  },
  {
    label: "Proximity to City Center: Closest First",
    value: "proximity_city_closest",
  },
  {
    label: "Proximity to City Center: Farthest First",
    value: "proximity_city_farthest",
  },
  { label: "Entire homes & apartments first", value: "entire_homes" },
  { label: "Most Relevant First", value: "most_relevant" },
];
export const shortStayOptions = [
  { label: "Most Relevant First", value: "most_relevant" },
  { label: "Price Lowest First", value: "price_lowest" },
  { label: "Price Highest First", value: "price_highest" },
  { label: "Popularity", value: "popularity" },
  {
    label: "Proximity to personal location: Closest First",
    value: "proximity_personal_closest",
  },
  {
    label: "Proximity to City Center: Closest First",
    value: "proximity_city_closest",
  },
  {
    label: "Proximity to City Center: Farthest First",
    value: "proximity_city_farthest",
  },
  { label: "Most Luxurious First", value: "most_luxurious" },
  { label: "Most Unique Stays First", value: "most_unique_stays" },
];
export const rentBuyOptions = [
  { label: "Lowest Price First", value: "price_asc" },
  { label: "Highest Price First", value: "price_desc" },
  {
    label: "Proximity to personal location: Closest First",
    value: "distance_personal_asc",
  },
  {
    label: "Proximity to City Center: Closest First",
    value: "distance_city_center_asc",
  },
  {
    label: "Proximity to City Center: Farthest First",
    value: "distance_city_center_desc",
  },
  {
    label: "Price per square Meter (m²): Lowest First",
    value: "price_per_sqm_asc",
  },
  {
    label: "Price per square Meter (m²): Highest First",
    value: "price_per_sqm_desc",
  },
  { label: "Size of Property: Biggest First", value: "size_desc" },
  { label: "Size of Property: Smallest First", value: "size_asc" },
  { label: "Floor Level: Highest Floor First", value: "floor_level_desc" },
  { label: "Floor Level: Lowest Floor First", value: "floor_level_asc" },
];
