import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import ListingOverview from "../../views/Listings/ListingOverview";
import DetailContent from "../../views/Listings/DetailContent";
import DetailContentAirbnb from "../../views/Listings/DetailContentAirbnb";
import DetailContentIdealista from "../../views/Listings/DetailContentIdealista";
import ListingMyLocation from "../../views/Listings/ListingMyLocation";
import NeighborhoodTab from "../../views/Listings/NeighborhoodTab";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import BirdEyeTab from "../../views/Listings/BirdEyeTab";
import StreetTab from "../../views/Listings/StreetTab";
import PlacesTab from "../../views/Listings/PlacesTab";
import PlacesView from "../../views/Area/Places/PlacesView";

const DetailTabs = (props) => {
  const {
    onClose,
    property,
    majorLocation,
    myLocation,
    listingDetail,
    listingMedia,
    toggleFullscreen,
    cityCenter,
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const { neighborhood, fetchNeighborhood } = useGooglePlaces();
  const geometry = property?.geometry;

  useEffect(() => {
    fetchNeighborhood(geometry?.coordinates?.[0], geometry?.coordinates?.[1]);
  }, [geometry]);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Custom styled Tabs with Tailwind
  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      gap: "70px",
      borderBottom: "1px solid #E7E7E7",
    },
    "& .MuiTabs-scroller": {
      overflow: "auto", // Enable horizontal scrolling
      "&::-webkit-scrollbar": {
        height: "6px", // Tailwind scrollbar height
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#ccc", // Customize scrollbar color
        borderRadius: "3px",
      },
    },
  });

  const tabData = [
    { id: "overview", label: "Overview" },
    { id: "myLocation", label: "My locations" },
    { id: "neighbr", label: "The neighborhood" },
    { id: "places", label: "Places 360" },
    { id: "bird", label: "Bird’s eye" },
    { id: "street", label: "Walk in the streets" },
  ];

  return (
    <div className="w-full h-full">
      <CustomTabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className="" // Tailwind styling for bottom border
      >
        {tabData.map((tab, index) => (
          <Tab
            key={tab.id}
            label={tab.label}
            className={`text-[18px] !normal-case font-medium ${
              selectedTab === index
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
          />
        ))}
        <div
          onClick={onClose}
          className="flex cursor-pointer gap-1 text-[15px] rounded-lg bg-[#D43838] h-[30px] text-[#fff] justify-center items-center px-2"
        >
          <div>Close</div>
          <Close />
        </div>
      </CustomTabs>
      <div
        className={
          tabData[selectedTab]?.id === "overview"
            ? "p-4 overflow-y-auto hide-scrollbar"
            : "p-4 h-[82%]"
        }
        style={{
          height:
            tabData[selectedTab]?.id === "overview"
              ? `calc(100vh - 170px)`
              : "",
        }}
      >
        {["rent", "buy"].includes(property?.properties?.listingKey) ? (
          <React.Fragment>
            {tabData[selectedTab]?.id === "overview" && (
              <DetailContentIdealista {...props} />
            )}
            {tabData[selectedTab]?.id === "myLocation" && <ListingMyLocation />}
            {tabData[selectedTab]?.id === "neighbr" && (
              <NeighborhoodTab placeName={neighborhood || ""} />
            )}
            {tabData[selectedTab]?.id === "bird" && (
              <BirdEyeTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "street" && (
              <StreetTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "places" && (
              <PlacesTab activeItem={property} />
            )}
          </React.Fragment>
        ) : property?.properties?.listingKey === "monthlyStay" ? (
          <React.Fragment>
            {tabData[selectedTab]?.id === "overview" && (
              <DetailContentAirbnb {...props} />
            )}
            {tabData[selectedTab]?.id === "myLocation" && <ListingMyLocation />}
            {tabData[selectedTab]?.id === "neighbr" && (
              <NeighborhoodTab placeName={neighborhood || ""} />
            )}
            {tabData[selectedTab]?.id === "bird" && (
              <BirdEyeTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "street" && (
              <StreetTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "places" && (
              <PlacesTab activeItem={property} />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {tabData[selectedTab]?.id === "overview" && (
              <DetailContent {...props} />
            )}
            {tabData[selectedTab]?.id === "myLocation" && <ListingMyLocation />}
            {tabData[selectedTab]?.id === "neighbr" && (
              <NeighborhoodTab placeName={neighborhood || ""} />
            )}
            {tabData[selectedTab]?.id === "bird" && (
              <BirdEyeTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "street" && (
              <StreetTab activeItem={property} />
            )}
            {tabData[selectedTab]?.id === "places" && (
              // <PlacesTab activeItem={property} />
              <PlacesView
                activeItem={property}
                isMobileScreen={false}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default DetailTabs;
