import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHotelsAction,
  getShorttermAction,
  getRentAction,
  getBuyAction,
  getMonthlyStayAction,
} from "../store/Listing/listingAction";
import dayjs from "dayjs";
import { getCityAndCountry, getSavedFilter } from "../common/Helpers";
import {
  setDefaultFilters,
  setSearchText,
} from "../store/Filter/filterReducers";

var listingApiCalled = false;
const useFilter = () => {
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);
  const { hotels } = useSelector((state) => state.hotels);
  let cachedFilters = getSavedFilter();

  useEffect(() => {
    if (cachedFilters && !listingApiCalled) {
      listingApiCalled = true;
      getProperties(true, cachedFilters);
    }
  }, []);

  useEffect(() => {
    if (
      filterState?.latitude &&
      filterState?.longitude &&
      filterState?.selectedMainFilters?.length > 0
    ) {
      getProperties();
    }
  }, [filterState]);

  function getProperties(isFirstRender = false, defaultPayload = null) {
    let payload = null;
    const filterPayload = isFirstRender ? defaultPayload : filterState;
    const startDate = formatDate("hotels", filterPayload)?.startDate;
    const endDate = formatDate("hotels", filterPayload)?.endDate;
    const sStartDate = formatDate("shortTerm", filterPayload)?.startDate;
    const sEndDate = formatDate("shortTerm", filterPayload)?.endDate;
    const mStartDate = formatDate("monthlyStay", filterPayload)?.startDate;
    const mEndDate = formatDate("monthlyStay", filterPayload)?.endDate;
    payload = {
      ...filterPayload,
      filters: {
        ...filterPayload.filters,
        hotels: { ...filterPayload.filters.hotels, startDate, endDate },
        shortTerm: {
          ...filterPayload.filters.shortTerm,
          startDate: sStartDate,
          endDate: sEndDate,
        },
        monthlyStay: {
          ...filterPayload.filters.monthlyStay,
          startDate: mStartDate,
          endDate: mEndDate,
        },
      },
    };
    if (isFirstRender) {
      dispatch(setSearchText(payload?.searchText));
    }
    console.log("payloadd>>>>>", payload);
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("hotels") &&
      payload?.filters?.hotels?.isRefatched
    ) {
      setCachedFilters("hotels", cachedFilters, payload);
      dispatch(getHotelsAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("shortTerm") &&
      payload?.filters?.shortTerm?.isRefatched
    ) {
      setCachedFilters("shortTerm", cachedFilters, payload);
      dispatch(getShorttermAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("monthlyStay") &&
      payload?.filters?.monthlyStay?.isRefatched
    ) {
      setCachedFilters("monthlyStay", cachedFilters, payload);
      dispatch(getMonthlyStayAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("rent") &&
      payload?.filters?.rent?.isRefatched
    ) {
      setCachedFilters("rent", cachedFilters, payload);
      dispatch(
        getRentAction({
          ...payload,
          ...getCityAndCountry(payload?.searchText?.text),
        })
      );
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("buy") &&
      payload?.filters?.buy?.isRefatched
    ) {
      setCachedFilters("buy", cachedFilters, payload);
      dispatch(
        getBuyAction({
          ...payload,
          ...getCityAndCountry(payload?.searchText?.text),
        })
      );
    }
  }

  function setCachedFilters(key, savedFilters, payload) {
    let updatedFilters = savedFilters ? savedFilters : payload;
    let selectedMainFilters = updatedFilters.selectedMainFilters;
    if (!selectedMainFilters.includes(key)) {
      selectedMainFilters.push(key);
    }

    localStorage.setItem(
      "userFilter",
      JSON.stringify({
        ...updatedFilters,
        filters: { ...updatedFilters.filters, [key]: payload.filters?.[key] },
        selectedMainFilters: selectedMainFilters,
      })
    );
  }
  function formatDate(key, payload) {
    let startDate = dayjs(payload?.filters?.[key]?.startDate);
    let endDate = dayjs(payload?.filters?.[key]?.endDate);

    // Check if startDate is in the past, and adjust dates accordingly
    if (startDate.isBefore(dayjs(), "day")) {
      startDate = dayjs(); // Set startDate to today
      endDate = dayjs().add(1, "day"); // Set endDate to tomorrow
    }

    return {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    };
  }
};

export default useFilter;
