import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getListingOverviewAction,
  getListingPriceAction,
} from "../../store/Listing/listingAction";
import InfoPrice from "./InfoPrice";
import dayjs from "dayjs";
import ListingAvailability from "./ListingAvailability";

const BottomMenu = ({
  activeItem,
  filterStyle,
  isMobileScreen,
  listingDetail,
}) => {
  const layout = useSelector((state) => state.layout.col);
  const filterState = useSelector((state) => state.filter);
  const infoPrice = useSelector((state) => state.hotels?.infoPrice);
  const listingOverview = useSelector((state) => state.hotels.listingOverview);
  const [activeSheet, setActiveSheet] = useState(null);
  const dispatch = useDispatch();

  const handleOpenSheet = (sheet) => {
    setActiveSheet(sheet);
  };

  const handleCloseSheet = () => {
    setActiveSheet(null);
  };

  const handleBackdropClick = (e) => {
    // If the user clicks directly on the backdrop (not the bottom sheet content), close the sheet.
    if (e.target.id === "backdrop") {
      handleCloseSheet();
    }
  };

  const handleListingInfoPrice = (sheet) => {
    if (
      activeItem?.properties?.accommodationType === "monthlyStay" &&
      activeItem?.properties?.id !== infoPrice?.listingId
    ) {
      dispatch(
        getListingPriceAction(
          {
            listingId: activeItem?.properties?.id,
            checkin: formatDate("monthlyStay")?.startDate,
            checkout: formatDate("monthlyStay")?.endDate,
            adult: filterState?.filters?.monthlyStay?.adults,
            children: filterState?.filters?.monthlyStay?.children,
          },
          activeItem?.properties?.accommodationType
        )
      );
    }
    setActiveSheet(sheet);
  };

  function formatDate(key) {
    const startDate = dayjs(filterState?.filters?.[key]?.startDate).format(
      "YYYY-MM-DD"
    );
    const endDate = dayjs(filterState?.filters?.[key]?.endDate).format(
      "YYYY-MM-DD"
    );
    return { startDate, endDate };
  }

  const handleListingOverview = (sheet) => {
    if (
      activeItem?.properties?.id !== listingOverview?.listingId &&
      (activeItem?.properties?.accommodationType === "Hotel" ||
        activeItem?.properties?.accommodationType === "Shortterm")
    ) {
      dispatch(
        getListingOverviewAction(
          {
            listingId: activeItem?.properties?.id,
          },
          activeItem?.properties?.accommodationType
        )
      );
    }
    setActiveSheet(sheet);
  };
  let overviewDescrition = listingOverview?.description;
  let facilities = listingDetail?.facilities_block?.facilities;
  if (
    activeItem?.properties?.accommodationType === "rent" ||
    activeItem?.properties?.accommodationType === "buy"
  ) {
    overviewDescrition = listingDetail?.propertyComment;
    facilities = [
      { name: `${listingDetail?.moreCharacteristics?.roomNumber} rooms` },
      { name: `${listingDetail?.moreCharacteristics?.bathNumber} bathrooms` },
      { name: `${listingDetail?.moreCharacteristics?.floor} floor` },
      { name: `Condition ${listingDetail?.moreCharacteristics?.status}` },
    ];
  }
  if (activeItem?.properties?.accommodationType === "monthlyStay") {
    overviewDescrition =
      listingDetail?.sections?.descriptionDefault?.descriptionSummary?.htmlText;
    facilities = listingDetail?.sections?.amenities?.seeAllAmenitiesGroups;
  }

  return (
    <Fragment>
      {(layout === 1 || !isMobileScreen) && (
        <div
          className="flex flex-row justify-between text-center p-2 gap-3 overflow-x-scroll shrink-0 hide-scrollbar"
          style={{
            background:
              filterStyle?.[activeItem?.properties?.listingKey]?.bg ||
              filterStyle?.Hotel?.bg,
          }}
        >
          <button
            className={`border-none bg-none bg-transparent font-medium min-w-max text-[#fff] py-2 `}
            onClick={() => handleListingOverview("overview")}
          >
            Overview
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium min-w-max text-[#fff]"
            onClick={() => handleListingInfoPrice("info")}
          >
            Info and Price
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium  min-w-max text-[#fff]"
            onClick={() => handleOpenSheet("facilities")}
          >
            Facilities
          </button>
        </div>
      )}
      {activeSheet && (
        <div
          id="backdrop"
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end bottom-12"
          onClick={handleBackdropClick}
        >
          <div className="bg-secondary-l0 w-full h-1/2 p-4 rounded-t-lg z-10 overflow-y-auto hide-scrollbar">
            <button
              className="text-warn-dark font-bold float-right"
              onClick={handleCloseSheet}
            >
              Close
            </button>
            {activeSheet === "overview" && (
              <div
                className="!text-xs"
                dangerouslySetInnerHTML={{ __html: overviewDescrition || "" }}
                style={{ whiteSpace: "pre-wrap" }}
              ></div>
            )}
            {activeSheet === "info" && (
              <InfoPrice
                listingInfo={listingDetail}
                listingType={activeItem?.properties?.accommodationType}
                activeItem={activeItem}
                infoPrice={infoPrice}
              />
            )}
            {activeSheet === "facilities" && (
              <div>
                <div className="font-bold text-sm mb-1">
                  Most popular facilities
                </div>
                <div className="flex flex-wrap gap-2">
                  {(facilities || []).map((fac) => (
                    <div className="text-xs">{fac?.title || fac?.name},</div>
                  ))}
                </div>
              </div>
            )}
            {activeSheet === "availability" && (
              <ListingAvailability listingDetail={listingDetail} />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BottomMenu;
