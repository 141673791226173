import React, { useEffect, useState } from "react";
import drawIcon from "../../../assets/icons/svg/draw-icon.svg";
import radiusIcon from "../../../assets/icons/svg/radius.svg";
import areaIcon from "../../../assets/icons/svg/area.svg";

const Tools = ({ onDrawChange, onRadiusChange, clear }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isRadiusChecked, setIsRadiusChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    onDrawChange(!isChecked);
  };

  const handleRadiusCheckboxChange = (event) => {
    setIsRadiusChecked(!isRadiusChecked);
    onRadiusChange(!isRadiusChecked);
  };

  useEffect(() => {
    if (!clear) setIsChecked(false);
  }, [clear]);

  return (
    <div className="flex ">
      <div className="gap-20 flex justify-between items-center text-[#000] text-[18px] font-medium">
        <div
          className="p-2 flex justify-center items-center cursor-pointer bg-[#fff]  rounded-full "
          onClick={handleCheckboxChange}
        >
          <div>
            <img alt="draw" src={drawIcon} />
          </div>
          <div>Draw</div>
        </div>
        <div
          className="p-2 flex justify-center items-center cursor-pointer bg-[#fff]  rounded-full"
          onClick={handleRadiusCheckboxChange}
        >
          <div>
            <img alt="radius" src={radiusIcon} />
          </div>
          <div>Radius</div>
        </div>
        <div className="p-2 flex justify-center items-center cursor-pointer bg-[#fff]  rounded-full">
          <div>
            <img alt="area" src={areaIcon} />
          </div>
          <div>Area</div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
