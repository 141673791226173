// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MobileRoutes from "@/routes/MobileRoutes";
import DesktopRoutes from "@/routes/DesktopRoutes";
import { isMobile } from "react-device-detect";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import MobileSplash from "./views/Splash/MobileSplash";
import { ThemeProvider } from "@mui/material";
import materialTheme from "./theme/material/materialTheme";
import SnackbarProvider from "@/components/SnackbarProvider/SnackbarProvider.js";

const AppRoutes = () => {
  return isMobile ? <MobileRoutes /> : <DesktopRoutes />;
};

const App = () => {
  const [isSplashScreen, setSplash] = useState(false);
  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit") === null;
    if (isMobile && isFirstVisit) {
      setSplash(true);
      localStorage.setItem("isFirstVisit", "false");
    }
    if (isMobile) {
      setTimeout(() => {
        setSplash(false);
      }, 500);
    }
  }, []);

  if (isMobile && isSplashScreen) {
    return <MobileSplash />;
  }
  return (
    <ThemeProvider theme={materialTheme}>
      <SnackbarProvider>
        <AuthProvider>
          <Router>
            <AppRoutes />
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
