import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import MapWith3DMarker from "../Area/MapWith3DMarker";
import BirdEyeSlider from "../Area/BirdEyeSlider";

const BirdEyeTab = ({ activeItem }) => {
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const [radius, setRadius] = useState(0);
  const [beRadius, setBeRadius] = useState(0);
  const [range, setRange] = useState(0);
  const [tilt, setTilt] = useState(0);
  const [tabActive, setTabActive] = useState(null);
  const [defaultRadius, setDefaultRadius] = useState(1.6); // Default value of 3 km
  const [locations, setLocations] = useState(myLocation);
  const [panoramaPos, setPanoramaPos] = useState(null);
  const mapCore = useMapsLibrary("core");
  const streetView = useMapsLibrary("streetView");
  const map = useMap();

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }

    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)

    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;

    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    return bounds;
  }

  useEffect(() => {
    if (!mapCore || !window.google) {
      return;
    }
    const bounds = getCircleBounds(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      radius
    );
    if (!bounds) return null;
    const filtered = myLocation.filter((loc) =>
      bounds.contains({ lat: loc.latitude, lng: loc.longitude })
    );

    setLocations(filtered);
    map && map.fitBounds(bounds);
  }, [radius]);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  useEffect(() => {
    if (tabActive === "street" && window.google.maps && streetView) {
      map && map.setStreetView(null);
      const streetViewPosition = {
        lat: activeItem?.properties?.latitude,
        lng: activeItem?.properties?.longitude,
      };
      console.log(document.getElementById("map"), streetViewPosition);
      const panorama = new streetView.StreetViewPanorama(
        document.querySelector(".street-map"),
        {
          position: streetViewPosition,
          pov: { heading: 165, pitch: 0 },
          zoom: 1,
        }
      );
      panorama.addListener("position_changed", () => {
        // Get the new position
        const newPosition = panorama.getPosition();
        setPanoramaPos({ lat: newPosition.lat(), lng: newPosition.lng() });
        const bounds = new window.google.maps.LatLngBounds();
        const pos = new window.google.maps.LatLng(
          activeItem?.properties?.latitude,
          activeItem?.properties?.longitude
        );
        bounds.extend(newPosition);
        bounds.extend(pos);
        console.log(bounds, pos);
        map.fitBounds(bounds);
      });
      map && map.setStreetView(panorama);
      setDefaultRadius(0);
    }
  }, [tabActive]);

  function birdEyeRadiusChange(radius) {
    setBeRadius(radius);
  }

  function tiltChange(tilt) {
    setTilt(tilt);
  }

  function defaultBirdEyeViewRadius() {
    return beRadius ?? 0;
  }

  const setBirdEyeRadius = (radius) => {
    setRange(radius);
  };

  return (
    <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10">
      <MapWith3DMarker
        lat={activeItem?.properties?.latitude}
        lng={activeItem?.properties?.longitude}
        defaultRange={range}
        radiusChange={birdEyeRadiusChange}
        tiltChange={tiltChange}
      ></MapWith3DMarker>
      <BirdEyeSlider
        style={{
          position: "absolute",
          top: "70px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        defaultRadius={defaultBirdEyeViewRadius()}
        valueChange={(radius) => setBirdEyeRadius(radius)}
        activeItem={activeItem?.properties}
      ></BirdEyeSlider>
      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "20px 20px",
          borderRadius: "30px",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          color: "white",
          display: "flex",
          gap: "15px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        <span>Dark</span>
        <span>Light</span>
        <span>Real</span>
        <span>Crime</span>
        <span>Geo</span>
      </div>
    </div>
  );
};
export default BirdEyeTab;
