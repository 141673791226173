import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  commonStore: {
    activeScreen: "search",
    activeFilterView: { filterKey: "rent" },
  },
  isDrawerOpen: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCommonStore: (state, action) => {
      state.commonStore = { ...state.commonStore, ...action.payload };
    },
    setToggleDrawer: (state, action) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
});
export const { setCommonStore, setToggleDrawer } = commonSlice.actions;
export default commonSlice.reducer;
