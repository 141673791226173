import React, { useState } from "react";
import { Slider, Box } from "@mui/material";
import { listType } from "../../common/Helpers";

const PriceRangeSlider = ({
  min,
  max,
  step,
  onChange,
  defaultValue,
  filterKey,
  label,
  leftPrefix,
  rightPrefix,
}) => {
  const [value, setValue] = useState(defaultValue || [min, max]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="p-4 mb-2">
      <div className="mb-2 text-[#000]/80 font-semibold">{label}</div>
      <Box className="flex justify-between text-sm text-[#000]/80 ">
        <span>Low</span>
        <span>High</span>
      </Box>
      <Box className="relative">
        <Slider
          value={value}
          onChange={handleChange}
          onChangeCommitted={onChange}
          valueLabelDisplay="off"
          min={min}
          max={max}
          step={step}
          sx={{
            color: listType[filterKey]?.color || listType.Hotel.color,
            "& .MuiSlider-thumb": {
              width: 16,
              height: 16,
              backgroundColor:
                listType[filterKey]?.color || listType.Hotel.color,
              "&:before": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            },
            "& .MuiSlider-track": {
              backgroundColor:
                listType[filterKey]?.color || listType.Hotel.color,
            },
            "& .MuiSlider-rail": {
              backgroundColor:
                listType[filterKey]?.color || listType.Hotel.color,
            },
          }}
        />
        {value.map((val, index) => (
          <Box
            key={index}
            className={`absolute flex justify-center items-center ${
              listType[filterKey]?.bg || listType.Hotel.bg
            } text-white py-1 px-2 rounded-md shadow-md text-xs`}
            sx={{
              bottom: "-20px",
              left: `${((val - min) / (max - min)) * 100}%`,
              transform: "translateX(-50%)",
            }}
          >
            {leftPrefix ? leftPrefix : ""}
            {val}
            {rightPrefix ? rightPrefix : ""}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PriceRangeSlider;
