import React, { useState } from "react";

const HotelFaqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What kind of breakfast is served at EVOLUTION Lisboa Hotel?",
      answer: "Check the hotel for breakfast options and availability.",
    },
    {
      question:
        "What are the check-in and check-out times at EVOLUTION Lisboa Hotel?",
      answer: "Check-in is at 14:00, and check-out is until 12:00.",
    },
    {
      question: "How much does it cost to stay at EVOLUTION Lisboa Hotel?",
      answer:
        "Prices may vary depending on your stay (e.g., selected dates, hotel's policy, etc.).",
    },
    {
      question: "Does EVOLUTION Lisboa Hotel have a pool?",
      answer:
        "Yes, this hotel has a pool. Find out more details about the pool on this page.",
    },
    {
      question: "Does EVOLUTION Lisboa Hotel have a restaurant on site?",
      answer: "Yes, the hotel has a restaurant available on site.",
    },
    {
      question: "How far is EVOLUTION Lisboa Hotel from the centre of Lisbon?",
      answer: "Check the map for detailed location and distance.",
    },
    {
      question: "What is there to do at EVOLUTION Lisboa Hotel?",
      answer: "Explore amenities and activities offered by the hotel.",
    },
    {
      question: "What type of room can I book at EVOLUTION Lisboa Hotel?",
      answer: "Various room options are available based on your preferences.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-full mx-auto my-8 p-4 flex gap-3">
      <div className="text-xl font-medium w-1/3 bg-[#EDF3FE] text-center pt-4 font-sans text-[#000]/80">
        FAQs about EVOLUTION Lisboa Hotel
      </div>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="">
            <div
              onClick={() => toggleFAQ(index)}
              className="flex font-sans justify-between w-full py-2 text-left font-medium text-sm"
            >
              {faq.question}
              <span
                className={`transform ${
                  activeIndex === index ? "rotate-180" : ""
                } transition-transform`}
              >
                ▼
              </span>
            </div>
            {activeIndex === index && (
              <p className="ml-2 text-[12px] font-sans">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HotelFaqs;
