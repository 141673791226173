import React, { useEffect } from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";
import { Phone } from "@mui/icons-material";

const ListingRentBuyItem = ({
  property,
  typeMapData,
  majorLocation,
  toggleAvailabilityView,
}) => {
  const { neighborhood, fetchNeighborhood } = useGooglePlaces();
  const {
    thumbnail,
    propertyName,
    listingKey,
    contactInfo,
    price,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    unit_configuration_label,
  } = property.properties;
  const geometry = property?.geometry;

  useEffect(() => {
    fetchNeighborhood(geometry?.coordinates?.[0], geometry?.coordinates?.[1]);
  }, []);
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;
  const phoneNumber = contactInfo?.phone1?.phoneNumberForMobileDialing;

  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <React.Fragment>
      <div className="relative">
        <img
          src={thumbnail}
          alt={propertyName}
          className="rounded-lg h-[300px] w-[300px] max-w-[300px] max-h-[300px]"
        />
        <div className="flex justify-center items-center w-full absolute top-2 z-50">
          <div className="flex px-3 py-1 justify-center  items-center gap-1 rounded-[30px] bg-[#fff]/90 shadow-2xl">
            <div>
              <LocationOnIcon
                sx={{
                  color: "#8B0C0C",
                  fontSize: 20,
                }}
              />
            </div>
            <div className="text-[18px] text-[#0067E8] font-medium">
              {neighborhood}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center w-full absolute bottom-2 z-50">
          <div className="flex px-3 py-1 justify-center  items-center gap-1 rounded-[10px] bg-[#fff]/80 shadow-2xl">
            <div>{typeMapData?.[listingKey]?.icon}</div>
            <div className="text-[18px] text-[#0067E8] font-medium">
              {typeMapData?.[listingKey]?.text}
            </div>
          </div>
        </div>
      </div>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div
              className="text-xl font-medium text-[#2D6ADC] cursor-pointer"
              onClick={() => toggleAvailabilityView(property)}
            >
              {propertyName}
            </div>
          </div>
        </div>

        {/* Icons and Description */}
        <div className="flex items-center space-x-4 text-[16px] text-[#050505] font-light">
          <div className="flex items-center">
            <div className="w-10 h-10 ">
              <IconCircular
                alt={"locaton"}
                key={"location"}
                src={
                  majorLocation?.image || "https://picsum.photos/200?random=5"
                }
              />
            </div>
            <DirectionIcon className="text-primary" />
            <span>
              {getDistanceFromLatLonInKm(
                majorLocation?.latitude,
                majorLocation?.longitude,
                geometry?.coordinates?.[0],
                geometry?.coordinates?.[1]
              ).toFixed(1)}{" "}
              km to {majorLocation?.locationName}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsWalkIcon className="text-primary" />
            <span>4 min</span>
          </div>
          <div className="flex items-center">
            <DirectionsCarIcon className="text-primary" />
            <span>30 min</span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div
            dangerouslySetInnerHTML={{ __html: unit_configuration_label }}
          ></div>
        </div>

        <div className=" flex justify-between">
          <Button
            variant="contained"
            style={{
              background: typeMapData?.[listingKey]?.color,
              borderRadius: 10,
            }}
          >
            {Math.round(price)} $/month
          </Button>
          <Button
            onClick={handleDial}
            variant="contained"
            style={{
              background: "#2D6ADC",
              borderRadius: 10,
            }}
            startIcon={<Phone />}
          >
            View phone
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ListingRentBuyItem;
