import React, { Fragment, useEffect, useRef, useState } from "react";
import { styled, Slider, SliderThumb, Button } from "@mui/material";
import { useSelector } from "react-redux";
import SwipeTabs from "./SwipeTabs";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
  Marker,
} from "@vis.gl/react-google-maps";
import { ReactComponent as Panorama } from "../../assets/icons/svg/panorama.svg";
import PropTypes from "prop-types";
import IconCircular from "../../components/IconCircular/IconCircular";
import AddLocationModal from "../../components/Listing/AddLocationModal";
import { getDistanceFromLatLonInKm, typeMapping } from "../../common/Helpers";
import MapAutoSearch from "../../components/Search/MapAutoSearch";
import MylocationList from "../../components/Listing/MylocationList";
import StreetViewSlider from "./StreetViewSlider";
import PlacesView from "./Places/PlacesView";
import StarView from "./StarView/StarView";
import MapWith3DMarker from "./MapWith3DMarker";
import BirdEyeSlider from "./BirdEyeSlider";
import { Home } from "@mui/icons-material";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

function SliderC({ valueChange, defaultRadius, activeItem }) {
  const [value, setValue] = useState(1.6); // Default value of 3 km

  useEffect(() => {
    setValue(defaultRadius);
  }, [defaultRadius]);

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other} max={10} min={0}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    console.log(newValue);
    valueChange(newValue);
  };

  return (
    <div className="w-40 absolute z-10 top-[5%] left-[20%]">
      <div
        className={
          "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
        }
      >
        <IconCircular
          className={"max-w-10 max-h-10 w-10 h-10"}
          alt={"language"}
          key={"language"}
          src={activeItem?.thumbnail || "https://picsum.photos/200"}
        />
      </div>
      <StyledSlider
        className="top-2 left-14"
        value={value}
        onChange={handleChange}
        onChangeCommitted={onSliderChangeCommited}
        min={0}
        max={100}
        step={0.1}
        valueLabelDisplay="off" // Auto display the value inside the thumb
        slots={{
          thumb: AirbnbThumbComponent, // Custom value label component
        }}
      />
    </div>
  );
}

const Area = ({ isMobileScreen = false }) => {
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const [radius, setRadius] = useState(0);
  const [beRadius, setBeRadius] = useState(0);
  const [range, setRange] = useState(0);
  const [tilt, setTilt] = useState(0);
  const [tabActive, setTabActive] = useState(null);
  const [defaultRadius, setDefaultRadius] = useState(1.6); // Default value of 3 km
  const [locations, setLocations] = useState(myLocation);
  const [searchLocation, setSearchLocation] = useState("");
  const [isOpenAddModal, setToggleAddModal] = useState(false);
  const [panoramaPos, setPanoramaPos] = useState(null);
  const [streetViewBottomSheet, setStreetViewBottomSheet] = useState(true);
  const mapRef = useRef(null);
  const mapCore = useMapsLibrary("core");
  const streetView = useMapsLibrary("streetView");
  const markerLib = useMapsLibrary("marker");

  const map = useMap();
  const { propertyName, review_score, review_nr, listingKey } =
    activeItem?.properties || {};

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }

    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)

    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;

    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    return bounds;
  }

  useEffect(() => {
    // setTimeout(()=>{
    //   setStreetViewBottomSheet(true)
    // }, 2000)
  }, []);

  useEffect(() => {
    if (!mapCore || !window.google) {
      return;
    }
    const bounds = getCircleBounds(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      radius
    );
    if (!bounds) return null;
    const filtered = myLocation.filter((loc) =>
      bounds.contains({ lat: loc.latitude, lng: loc.longitude })
    );

    setLocations(filtered);
    map && map.fitBounds(bounds);
  }, [radius]);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  const toggleMyLocation = (location) => {
    if (!mapCore || !window.google) {
      return;
    }

    const dis = getDistanceFromLatLonInKm(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      location.latitude,
      location.longitude
    );
    setRadius(dis.toFixed(1));
    setDefaultRadius(dis.toFixed(1));
  };

  function handleSearchLocation(value) {
    setSearchLocation(value);
  }

  const onTabChange = (activeTab) => {
    setTabActive(activeTab);
  };
  const filterStyle = typeMapping(listingKey);

  useEffect(() => {
    if (tabActive === "street" && window.google.maps && streetView) {
      map && map.setStreetView(null);
      const streetViewPosition = {
        lat: activeItem?.properties?.latitude,
        lng: activeItem?.properties?.longitude,
      };
      console.log(document.getElementById("map"), streetViewPosition);
      const panorama = new streetView.StreetViewPanorama(
        document.querySelector(".street-map"),
        {
          position: streetViewPosition,
          pov: { heading: 165, pitch: 0 },
          zoom: 1,
        }
      );
      panorama.addListener("position_changed", () => {
        // Get the new position
        const newPosition = panorama.getPosition();
        setPanoramaPos({ lat: newPosition.lat(), lng: newPosition.lng() });
        const bounds = new window.google.maps.LatLngBounds();
        const pos = new window.google.maps.LatLng(
          activeItem?.properties?.latitude,
          activeItem?.properties?.longitude
        );
        bounds.extend(newPosition);
        bounds.extend(pos);
        console.log(bounds, pos);
        map.fitBounds(bounds);
      });
      map && map.setStreetView(panorama);
      setDefaultRadius(0);
    }
  }, [tabActive]);

  function defaultStreetViewRadius() {
    return getDistanceFromLatLonInKm(
      panoramaPos?.lat,
      panoramaPos?.lng,
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude
    ).toFixed(2);
  }

  function birdEyeRadiusChange(radius) {
    setBeRadius(radius);
  }

  function tiltChange(tilt) {
    setTilt(tilt);
  }

  function defaultBirdEyeViewRadius() {
    return beRadius ?? 0;
  }

  const setBirdEyeRadius = (radius) => {
    setRange(radius);
  };

  return (
    <div className={isMobileScreen ? "h-[87%]" : "flex flex-col flex-1"}>
      {isMobileScreen && (
        <div className="bg-primary flex flex-col flex-1 justify-between p-2">
          <div>
            <div className="text-[19px] font-medium text-[#fff] mb-2">
              {propertyName}
            </div>
            <div>
              {activeItem?.properties?.class && (
                <div className="text-yellow-400 flex items-center">
                  {[...Array(activeItem?.properties?.class || 0)].map(
                    (_, i) => (
                      <span key={i} className="text-[#F4BB3F]">
                        ★
                      </span>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <div className="text-[19px] font-medium text-[#fff] mb-2">
                {filterStyle?.text}
              </div>
              <div className="text-[#fff] text-[14px] font-light">
                {review_nr} reviews
              </div>
            </div>
            <div className="bg-[#fff] p-2 text-[19px] flex justify-center items-center rounded-md">
              {review_score}
            </div>
          </div>
        </div>
      )}
      <SwipeTabs
        onTabChange={onTabChange}
        activeItem={activeItem}
        isMobileScreen={isMobileScreen}
      />

      {tabActive === "bird-eye" && (
        <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar relative">
          <MapWith3DMarker
            lat={activeItem?.properties?.latitude}
            lng={activeItem?.properties?.longitude}
            defaultRange={range}
            radiusChange={birdEyeRadiusChange}
            tiltChange={tiltChange}
          ></MapWith3DMarker>
          <BirdEyeSlider
            style={{
              position: "absolute",
              top: "70px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            defaultRadius={defaultBirdEyeViewRadius()}
            valueChange={(radius) => setBirdEyeRadius(radius)}
            activeItem={activeItem?.properties}
          ></BirdEyeSlider>
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "20px 20px",
              borderRadius: "30px",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              color: "white",
              display: "flex",
              gap: "15px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <span>Dark</span>
            <span>Light</span>
            <span>Real</span>
            <span>Crime</span>
            <span>Geo</span>
          </div>
        </div>
      )}

      {tabActive === "my-loc" && (
        <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar relative">
          <SliderC
            className={"absolute  left-[50%] z-30"}
            defaultRadius={defaultRadius}
            valueChange={(radius) => setRadius(radius)}
            activeItem={activeItem?.properties}
          />
          {mapCore && activeItem && (
            <Map
              disableDefaultUI={true}
              fullscreenControl={false}
              defaultZoom={15}
              zoomControlOptions={null}
              zoomControl={true}
              mapId={"map"}
              defaultCenter={{
                lat: activeItem?.properties?.latitude,
                lng: activeItem?.properties?.longitude,
              }}
              streetViewControl={false}
              className="h-full"
            >
              {activeItem && (
                <AdvancedMarker
                  position={{
                    lat: activeItem?.properties?.latitude,
                    lng: activeItem?.properties?.longitude,
                  }}
                >
                  <Home
                    sx={{ fontSize: 50, color: "red", shadow: 3 }}
                    className="w-12 h-12 shadow-lg  cursor-pointer"
                  />
                </AdvancedMarker>
              )}
              {searchLocation && (
                <AdvancedMarker
                  key={"search"}
                  position={{
                    lat: searchLocation.latitude,
                    lng: searchLocation.longitude,
                  }}
                ></AdvancedMarker>
              )}
              {(locations || []).length &&
                locations.map((item, index) => (
                  <AdvancedMarker
                    key={item._id}
                    position={{ lat: item.latitude, lng: item.longitude }}
                  >
                    <div className="flex  flex-col items-center w-14 h-14 rounded-full">
                      <img
                        className="rounded-full"
                        width={"100%"}
                        height={"100%"}
                        src={item.image}
                        alt="locaton"
                      />
                    </div>
                    <div className="ml-[2px] w-[92%] flex flex-col items-center font-bold text-[#fff] absolute bottom-[1px] rounded-bl-full rounded-br-full left-[0px] bg-[#000]">
                      {getDistanceFromLatLonInKm(
                        item?.latitude,
                        item?.longitude,
                        activeItem?.properties?.latitude,
                        activeItem?.properties?.longitude
                      ).toFixed(1)}
                      km
                      <svg
                        width="9"
                        height="10"
                        viewBox="0 0 9 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.68735 9.63086C0.869687 9.63086 1.04455 9.55843 1.17349 9.4295C1.30242 9.30056 1.37485 9.1257 1.37485 8.94336L1.37485 5.16211C1.37485 4.22367 1.81141 3.78711 2.74985 3.78711L6.90235 3.78711L6.21485 4.13086L5.33141 5.0143C5.20241 5.1433 5.12994 5.31827 5.12994 5.5007C5.12994 5.68314 5.20241 5.85811 5.33141 5.98711C5.46042 6.11611 5.63538 6.18859 5.81782 6.18859C6.00026 6.18859 6.17522 6.11611 6.30422 5.98711L8.71047 3.58086C8.7744 3.51701 8.82511 3.44119 8.8597 3.35772C8.8943 3.27426 8.91211 3.1848 8.91211 3.09445C8.91211 3.00411 8.8943 2.91464 8.8597 2.83118C8.82511 2.74772 8.7744 2.6719 8.71047 2.60805L6.30422 0.201797C6.24035 0.137921 6.16452 0.0872522 6.08106 0.0526829C5.9976 0.0181136 5.90815 0.000321388 5.81782 0.000321388C5.72748 0.000321388 5.63803 0.0181136 5.55458 0.0526829C5.47112 0.0872522 5.39529 0.137921 5.33141 0.201797C5.26754 0.265672 5.21687 0.341504 5.1823 0.424962C5.14773 0.50842 5.12994 0.59787 5.12994 0.688204C5.12994 0.778538 5.14773 0.867986 5.1823 0.951444C5.21687 1.0349 5.26754 1.11073 5.33141 1.17461L6.25954 2.06836L6.92641 2.41211L2.74985 2.41211C1.05516 2.41211 -0.000149727 3.46742 -0.000149727 5.16211V8.94336C-0.000149727 9.1257 0.0722826 9.30056 0.201214 9.4295C0.330145 9.55843 0.505014 9.63086 0.68735 9.63086Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </AdvancedMarker>
                ))}
              {isOpenAddModal && (
                <AddLocationModal
                  map={map}
                  open={isOpenAddModal}
                  handleClose={() => setToggleAddModal(!isOpenAddModal)}
                />
              )}
            </Map>
          )}
          <div className="absolute bottom-0 right-0 left-0 flex flex-row bg-secondary-l0 bg-opacity-70 p-3 gap-4 items-center">
            <Button
              onClick={() => setToggleAddModal(!isOpenAddModal)}
              sx={{
                borderRadius: "6px",
                width: "50px",
                height: "50px",
                minWidth: "50px",
                background: "none !important",
              }}
              variant="contained"
              className={`text-secondary-l0  !px-2 !py-1 !text-[10px]`}
            >
              +
            </Button>
            <div className=" w-[90%] absolute bottom-[130px]">
              <MapAutoSearch map={map} onSelectValue={handleSearchLocation} />
            </div>
            <MylocationList
              myLocation={myLocation}
              toggleLocation={toggleMyLocation}
            />
          </div>
        </div>
      )}

      {tabActive === "star" && <StarView />}

      {tabActive === "street" && (
        <Fragment>
          <div className="flex flex-col bg-secondary-l0 w-full h-[60%] rounded-t-lg z-10 overflow-y-auto hide-scrollbar relative">
            <StreetViewSlider
              className={"absolute top-[113px]  left-[50%] z-30"}
              defaultRadius={defaultStreetViewRadius()}
              valueChange={(radius) => setRadius(radius)}
              activeItem={activeItem?.properties}
            />
            {mapCore && activeItem && (
              <Map
                disableDefaultUI={true}
                fullscreenControl={false}
                defaultZoom={15}
                zoomControlOptions={null}
                zoomControl={true}
                mapId={"map-street"}
                defaultCenter={{
                  lat: activeItem?.properties?.latitude,
                  lng: activeItem?.properties?.longitude,
                }}
                streetViewControl={false}
                className="h-full street-map"
              >
                {activeItem && (
                  <AdvancedMarker
                    position={{
                      lat: activeItem?.properties?.latitude,
                      lng: activeItem?.properties?.longitude,
                    }}
                  >
                    <Home
                      sx={{ fontSize: 50, color: "red", shadow: 3 }}
                      className="w-12 h-12 shadow-lg  cursor-pointer"
                    />
                  </AdvancedMarker>
                )}
              </Map>
            )}
          </div>
          <div className="flex flex-col  inset-0  h-[40%] justify-center items-end z-20">
            <div className="bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar">
              {mapCore && activeItem && panoramaPos && (
                <Map
                  disableDefaultUI={true}
                  fullscreenControl={false}
                  defaultZoom={9}
                  zoomControlOptions={null}
                  zoomControl={true}
                  mapId={"map-street"}
                  defaultCenter={{
                    lat: activeItem?.properties?.latitude,
                    lng: activeItem?.properties?.longitude,
                  }}
                  streetViewControl={false}
                  className="h-full"
                >
                  {panoramaPos && (
                    <AdvancedMarker position={panoramaPos}>
                      <Panorama />
                    </AdvancedMarker>
                  )}
                  {activeItem && (
                    <AdvancedMarker
                      position={{
                        lat: activeItem?.properties?.latitude,
                        lng: activeItem?.properties?.longitude,
                      }}
                    >
                      <Home
                        sx={{ fontSize: 50, color: "red", shadow: 3 }}
                        className="w-12 h-12 shadow-lg  cursor-pointer"
                      />
                    </AdvancedMarker>
                  )}
                </Map>
              )}
            </div>
          </div>
        </Fragment>
      )}
      {tabActive === "places" && (
        <PlacesView
          map={map}
          activeItem={activeItem}
          isMobileScreen={isMobileScreen}
        />
      )}
    </div>
  );
};
export default Area;
