import React, { useEffect, useState } from "react";
import PlacesList from "./PlacesList";
import { styled, Slider, SliderThumb } from "@mui/material";
import PropTypes from "prop-types";
import IconCircular from "../../../components/IconCircular/IconCircular";
import MapAutoSearch from "../../../components/Search/MapAutoSearch";
import useGooglePlaces from "../../../hooks/useGooglePlaces";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Directions,
  Info,
  Schedule,
  Sms,
} from "@mui/icons-material";
import mapIcon from "../../../assets/icons/svg/map-icon.svg";
import { getDistanceFromLatLonInKm, trimString } from "../../../common/Helpers";
import CustomBottomSheet from "../../../components/CustomBottomSheet/CustomBottomSheet";
import OpeningHours from "./OpeningHours";
import ReviewComments from "./ReviewsComment";
import PlaceMapView from "./PlaceMapView";
import { useSwipeable } from "react-swipeable";
import PlacesTab from "../../Listings/PlacesTab";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};
function SliderC({ valueChange, image }) {
  const [value, setValue] = useState(10);

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other} max={20} min={0}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    valueChange(newValue);
  };

  return (
    <div className="w-40 absolute z-10 top-[2%] left-[20%]">
      <div
        className={
          "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
        }
      >
        <IconCircular
          className={"max-w-10 max-h-10 w-10 h-10"}
          alt={"language"}
          key={"language"}
          src={image || "https://picsum.photos/200"}
        />
      </div>
      <StyledSlider
        className="top-0 left-12"
        value={value}
        onChange={handleChange}
        onChangeCommitted={onSliderChangeCommited}
        min={0}
        max={20}
        step={0.1}
        valueLabelDisplay="off" // Auto display the value inside the thumb
        slots={{
          thumb: AirbnbThumbComponent, // Custom value label component
        }}
      />
    </div>
  );
}
function PlacesView({ map, activeItem, isMobileScreen }) {
  const [radius, setRadius] = useState(10);
  const [state, setState] = useState({
    isInfo: false,
    isTiming: false,
    isReview: false,
    isInfo: false,
    isMapView: false,
  });
  const [activePlaceType, setActivePlaceType] = useState({ type: "coffee" });
  const { latitude, longitude } = activeItem?.properties;
  const { places, fetchPlaces, fetchPlaceDetails, placeDetails } =
    useGooglePlaces();
  const [activeScreenPlace, setActiveScreenPlace] = useState(null);
  const [currentPlaceIndex, setCurrentPlaceIndex] = useState(0);
  const handlers = useSwipeable({
    onSwipedLeft: () => handlePrevPlace(),
    onSwipedRight: () => handleNextPlace(),
    preventScrollOnSwipe: true, // Prevent default scroll behavior
    trackMouse: true, // Enable mouse drag tracking
  });

  useEffect(() => {
    const location = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    fetchPlaces(activePlaceType?.type, location, radius);
  }, [activePlaceType, radius, activeItem]);

  useEffect(() => {
    if (places?.length > 0) {
      setActiveScreenPlace(places?.[0]);
      fetchPlaceDetails(places?.[0]?.place_id, activeItem?.properties);
    }
  }, [places]);

  console.log("placesss in view", places, placeDetails);

  const togglePlace = (placeType) => {
    if (activePlaceType?.type === placeType?.type) {
      setActivePlaceType(undefined);
    } else {
      setActivePlaceType(placeType);
    }
  };

  const handleNextPlace = () => {
    if (places?.length === currentPlaceIndex + 1) {
      setCurrentPlaceIndex(0);
      setActiveScreenPlace(places?.[0]);
      fetchPlaceDetails(places?.[0]?.place_id, activeItem?.properties);
    } else {
      const nextIndex = currentPlaceIndex + 1;
      setCurrentPlaceIndex(nextIndex);
      setActiveScreenPlace(places?.[nextIndex]);
      fetchPlaceDetails(places?.[nextIndex]?.place_id, activeItem?.properties);
    }
  };

  const handlePrevPlace = () => {
    if (currentPlaceIndex === 0) {
      setCurrentPlaceIndex(0);
      setActiveScreenPlace(places?.[0]);
      fetchPlaceDetails(places?.[0]?.place_id, activeItem?.properties);
    } else {
      const prevIndex = currentPlaceIndex - 1;
      setCurrentPlaceIndex(prevIndex);
      setActiveScreenPlace(places?.[prevIndex]);
      fetchPlaceDetails(places?.[prevIndex]?.place_id, activeItem?.properties);
    }
  };

  function toggleInfo() {
    setState({ ...state, isInfo: !state.isInfo });
  }

  function toggleTimings() {
    setState({ ...state, isTiming: !state.isTiming });
  }

  function toggleReviews() {
    setState({ ...state, isReview: !state.isReview });
  }

  function toggleMapView(e) {
    setState({ ...state, isMapView: !state.isMapView });
  }
  function closeMapView(e) {
    if (e.target.id === "placeMap") {
      setState({ ...state, isMapView: false });
    }
  }
  console.log("activeScreenPlace", places);
  const distanceFromProperty = getDistanceFromLatLonInKm(
    latitude,
    longitude,
    activeScreenPlace?.geometry?.location?.lat(),
    activeScreenPlace?.geometry?.location?.lng()
  );
  return (
    <PlacesTab
      activeItem={activeItem}
      placeDetails={placeDetails}
      places={places}
    />
  );
  return (
    <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10  relative">
      <SliderC
        image={activeItem?.properties?.thumbnail}
        className={"absolute  left-[50%] z-30"}
        valueChange={(radius) => setRadius(radius)}
      />
      <div className="absolute z-30 top-[12%] w-full flex flex-col gap-4">
        <div className=" w-full flex justify-between items-center">
          <div className="  bg-[#fff] text-[#000] rounded-l-none rounded-full w-auto flex justify-between items-center p-2 text-[15px] gap-1">
            <div>{trimString(activeScreenPlace?.name, 20)}</div>
            <div>{activeScreenPlace?.rating}</div>
            <div className="text-[#F4BB3F]">★</div>
          </div>
          <div className="mr-2 bg-[#fff]/80 text-[#000] font-semibold rounded-full w-auto flex justify-between items-center p-2 text-[12px]">
            <Directions />
            <div>{distanceFromProperty.toFixed(1)} km</div>
          </div>
        </div>
      </div>
      <div className="absolute z-30 sm:top-[19%] top-[22%] w-[100px] flex flex-col ">
        <div
          onClick={toggleReviews}
          className="ml-2 cursor-pointer bg-[#2b96b3]/80 text-[#fff] rounded-full w-[70px] flex justify-between items-center p-2 text-[12px]"
        >
          <Sms />
          <div>
            <div>{activeScreenPlace?.user_ratings_total}</div>
            <div>reviews</div>
          </div>
        </div>
      </div>
      <div className="absolute z-30 sm:top-[21%] top-[24%] right-0 w-[50px] flex flex-col ">
        <div className=" text-[#fff] rounded-full w-auto flex justify-between items-center text-[12px]">
          <div onClick={toggleMapView} className="mr-2 cursor-pointer">
            <img src={mapIcon} alt="map icon" />
          </div>
        </div>
      </div>
      <div className="absolute z-30 sm:top-[27%] top-[34%] w-[100px] flex flex-col gap-2">
        <div
          onClick={toggleInfo}
          className="cursor-pointer ml-2 bg-[#2b96b3]/80 text-[#fff] rounded-full w-[50px] flex justify-between items-center p-2 text-[12px]"
        >
          <Info />
          <div>Info</div>
        </div>
        <div
          onClick={toggleTimings}
          className="ml-2 bg-[#2b96b3]/80 text-[#fff] cursor-pointer rounded-full w-[65px] flex justify-between items-center p-2 text-[12px]"
        >
          <Schedule />
          <div>Hours</div>
        </div>
        {!isMobileScreen && (
          <div
            onClick={handlePrevPlace}
            className="cursor-pointer ml-2  bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
          >
            <ArrowBackIos className="!text-[24px]" />
          </div>
        )}
      </div>
      <div className="absolute z-30 sm:top-[41.4%] top-[44.4%] right-0 w-[44px] flex flex-col ">
        {!isMobileScreen && (
          <div
            onClick={handleNextPlace}
            className="cursor-pointer mr-2 bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
          >
            <ArrowForwardIos className="!text-[24px]" />
          </div>
        )}
      </div>

      <div
        {...handlers}
        className="flex flex-col h-full sm:max-h-[630px] max-h-[500px]  w-full overflow-y-auto hide-scrollbar relative"
      >
        {placeDetails?.photos?.length > 0 ? (
          placeDetails.photos.map((photo, index) => (
            <img
              key={`${index}`}
              src={photo.getUrl() || ""}
              alt={`view`}
              className="w-full h-full sm:max-h-[630px] max-h-[350px] object-cover"
            />
          ))
        ) : (
          <img
            src={activeScreenPlace?.photos?.[0]?.getUrl() || ""}
            alt={`view`}
            className="w-full h-full sm:max-h-[650px] max-h-[500px] object-cover"
          />
        )}
      </div>
      <div className="w-full flex justify-center">
        {/* <div className=" w-[90%] absolute sm:bottom-[160px] bottom-[80px] z-50">
          <MapAutoSearch map={map} onSelectValue={() => console.log("")} />
        </div> */}
      </div>
      <div className="absolute sm:bottom-[80px] bottom-[9px] right-0 left-0 flex flex-row justify-center  items-center">
        <div className="w-[90%] bg-[#000]/25  p-3 rounded-full">
          <PlacesList togglePlace={togglePlace} />
        </div>
      </div>
      <CustomBottomSheet isOpen={state.isTiming} onDismiss={toggleTimings}>
        <OpeningHours hours={placeDetails?.opening_hours?.weekday_text || []} />
      </CustomBottomSheet>
      <CustomBottomSheet isOpen={state.isReview} onDismiss={toggleReviews}>
        <ReviewComments
          reviews={placeDetails?.reviews || []}
          rating={activeScreenPlace?.rating}
        />
      </CustomBottomSheet>
      <CustomBottomSheet isOpen={state.isInfo} onDismiss={toggleInfo}>
        <p className="px-1">{placeDetails?.description}</p>
      </CustomBottomSheet>
      <PlaceMapView
        isMapView={state.isMapView}
        details={activeItem}
        handleBackdropClick={closeMapView}
        placeLatLng={{
          lat: activeScreenPlace?.geometry?.location?.lat(),
          lng: activeScreenPlace?.geometry?.location?.lng(),
        }}
        activeScreenPlace={activeScreenPlace}
      />
    </div>
  );
}

export default PlacesView;
