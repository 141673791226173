import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setMainFilter,
  setHotelFilters,
  clearHotelFilters,
  clearShortTermFilters,
  setRentFilters,
  clearRentFilters,
  setBuyFilters,
  clearBuyFilters,
} from "@/store/Filter/filterReducers";
import FilterButton from "./FilterButton";
import DateRangePick from "../../DateRangePick";
import BudgetRange from "../../BudgetRange";
import OccupancyForm from "../../OccupancyForm";
import dayjs from "dayjs";
import AdvanceFilter from "./AdvanceFilter";
import RentBuyAdvanceFilter from "./RentBuyAdvanceFilter";
import { getSavedFilter } from "../../../common/Helpers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "../../../store/Filter/filterReducers";
import { removeListingByType } from "../../../store/Listing/listingAction";
import MonthlyFilters from "./MonthlyFilters";

const AccommodationFilter = () => {
  const dispatch = useDispatch();
  let cachedFilters = getSavedFilter();

  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const activeFilter = useSelector(
    (state) => state.common?.commonStore?.activeFilterView
  );
  const searchText = useSelector((state) => state.filter.searchText || "");
  const filters = useSelector((state) => state.filter.filters || {});
  const [appliedFilters, setAppliedFilters] = useState(() => {
    const initialFilters = {};
    if (selectedMainFilters.includes("hotels")) {
      initialFilters.hotels = true;
    }
    return initialFilters;
  });

  useEffect(() => {
    if (cachedFilters?.selectedMainFilters?.length > 0) {
      let predefineFilter = {};
      cachedFilters.selectedMainFilters.map((sF) => {
        predefineFilter = { ...predefineFilter, [sF]: true };
      });
      setAppliedFilters(predefineFilter);
    }
  }, []);

  useEffect(() => {
    if (!selectedMainFilters || selectedMainFilters?.length <= 0) {
      dispatch(setMainFilter("hotels"));
      setAppliedFilters((prev) => ({ ...prev, hotels: true }));
    }
  }, [dispatch, selectedMainFilters]);

  const handleApply = useCallback(
    (filterKey, values) => {
      const actionMap = {
        hotels: setHotelFilters,
        monthlyStay: setMonthlyFilters,
        rent: setRentFilters,
        buy: setBuyFilters,
      };
      const action = actionMap[filterKey];
      if (action) {
        dispatch(action({ ...values, isRefatched: true }));
        dispatch(setMainFilter(filterKey));
        setAppliedFilters((prev) => ({ ...prev, [filterKey]: true }));
      }
    },
    [dispatch]
  );

  const handleClear = useCallback(
    (filterKey) => {
      const actionMap = {
        hotels: clearHotelFilters,
        shortTerm: clearShortTermFilters,
        monthlyStay: clearMonthlyFilters,
        rent: clearRentFilters,
        buy: clearBuyFilters,
      };
      const action = actionMap[filterKey];
      dispatch(removeListingByType(filterKey));
      if (action) {
        dispatch(action());
        setAppliedFilters((prev) => ({ ...prev, [filterKey]: false }));
      }
    },
    [dispatch]
  );
  console.log("activeFilter", activeFilter);
  return (
    <div className="h-full flex justify-center">
      {activeFilter?.filterKey === "hotels" && (
        <FilterButton
          label="Per Night"
          filterKey="hotels"
          initialValues={filters.hotels}
          onApply={handleApply}
          onClear={handleClear}
          applied={appliedFilters.hotels}
          setAppliedFilters={setAppliedFilters}
        >
          <DateRangePick
            filterKey="hotels"
            initialValue={[
              dayjs(filters.hotels?.startDate),
              dayjs(filters.hotels?.endDate),
            ]}
          />
          <OccupancyForm
            filterKey="hotels"
            initialOccupancy={{
              adults: filters.hotels?.adults || 1,
              children: filters.hotels?.children || 0,
              rooms: filters.hotels?.rooms || 1,
            }}
          />
          <BudgetRange
            maxValue={2000}
            minValue={0}
            filterKey="hotels"
            minKey={"minBudget"}
            maxKey={"maxBudget"}
            title={"My Budget (per night)"}
            initialValue={[
              filters.hotels?.minBudget,
              filters.hotels?.maxBudget,
            ]}
          />

          <AdvanceFilter filterKey="hotels" />
        </FilterButton>
      )}
      {activeFilter?.filterKey === "monthlyStay" && (
        <FilterButton
          label="Monthly Stay"
          filterKey="monthlyStay"
          initialValues={filters.monthlyStay}
          onApply={handleApply}
          onClear={handleClear}
          applied={appliedFilters.monthlyStay}
          setAppliedFilters={setAppliedFilters}
        >
          <div className="text-center pb-1">Monthly stay filter by</div>
          <div className="text-center pb-1 text-xs !text-[#C4FF47]">
            Must-Have Filters
          </div>
          <DateRangePick
            filterKey="monthlyStay"
            initialValue={[
              dayjs(filters.monthlyStay?.startDate),
              dayjs(filters.monthlyStay?.endDate),
            ]}
          />
          <OccupancyForm
            filterKey="monthlyStay"
            initialOccupancy={{
              adults: filters.monthlyStay?.adults || 1,
              children: filters.monthlyStay?.children || 0,
              rooms: filters.monthlyStay?.rooms || 1,
            }}
          />
          <BudgetRange
            maxValue={2000}
            minValue={0}
            filterKey="monthlyStay"
            minKey={"minBudget"}
            maxKey={"maxBudget"}
            title={"My Budget (per night)"}
            initialValue={[
              filters.monthlyStay?.minBudget,
              filters.monthlyStay?.maxBudget,
            ]}
          />
          <MonthlyFilters filterKey="monthlyStay" />
        </FilterButton>
      )}

      {activeFilter?.filterKey === "rent" && (
        <FilterButton
          label="Rent"
          filterKey="rent"
          initialValues={filters.rent}
          onApply={handleApply}
          onClear={handleClear}
          applied={appliedFilters.rent}
          setAppliedFilters={setAppliedFilters}
          //isDisabled={getCityAndCountry(searchText?.text)?.isNotAvailable}
        >
          <RentBuyAdvanceFilter
            minPrice={0}
            maxPrice={3000}
            filterKey="rent"
            initialFilters={filters}
          />
        </FilterButton>
      )}

      {activeFilter?.filterKey === "buy" && (
        <FilterButton
          label="Buy"
          filterKey="buy"
          initialValues={filters.buy}
          onApply={handleApply}
          onClear={handleClear}
          applied={appliedFilters.buy}
          setAppliedFilters={setAppliedFilters}
          //isDisabled={getCityAndCountry(searchText?.text)?.isNotAvailable}
        >
          <RentBuyAdvanceFilter
            minPrice={0}
            maxPrice={200000}
            filterKey="buy"
            initialFilters={filters}
          />
        </FilterButton>
      )}
    </div>
  );
};

export default AccommodationFilter;
