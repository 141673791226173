import React, { useEffect, useState } from "react";
import ListItem from "./ListItem";
import ListingsButton from "./ListingsButton";
import HotelListItem from "./HotelListItem";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import sortIcon from "../../assets/icons/svg/sorting-icon.svg";
import notesIcon from "../../assets/icons/svg/notes.svg";
import pinIcon from "../../assets/icons/svg/pin.svg";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavoriteList,
  deleteFavoriteItem,
  pinFavoriteItem,
  saveFavoriteItemNotes,
} from "../../store/User/userAction";
import { getCurrentUser, sortListings } from "../../common/Helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, TextareaAutosize } from "@mui/material";
import { AdvancedMarker, Map, useMapsLibrary } from "@vis.gl/react-google-maps";
import useListing from "../../hooks/useListing";
import dayjs from "dayjs";
import { setLayoutAction } from "../../store/Layout/layoutAction";
import SortButton from "../../components/SortButton/SortButton";
import usePosition from "../../hooks/usePosition";
import myLocation from "../Swipe/location.json";
import PathWithMarkers from "../Swipe/PathWithMarkers";

const sortOptions = [
  {
    id: "price",
    name: "Price (Lowest first)",
  },
  {
    id: "rating",
    name: "Rating",
  },
  {
    name: "distance from my office",
    id: "distance_office",
  },
  {
    name: "distance from me",
    id: "distance_me",
  },
];
const List = ({ activeTab }) => {
  const [listingId, setListingId] = useState(null);
  const [detail, setDetail] = useState(null);
  const [isPinned, setIsPinned] = useState(null);
  const [isLongPress, setIsLongPress] = useState(null);
  const [activeSheet, setActiveSheet] = useState(false);
  const [activeShowNotesSheet, setActiveShowNotesSheet] = useState(null);
  const [itemMapSheet, setItemMapSheet] = useState(null);
  const [notes, setNotes] = useState(null);
  const [showMapList, setShowMapList] = useState(false);
  const [saveList, setSaveList] = useState([]);
  const mapCore = useMapsLibrary("core");
  const markerLib = useMapsLibrary("marker"); // Load the marker library
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const { showDetail } = useListing();
  const { favoriteList } = useSelector((state) => state.user.userStore);

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getFavoriteList(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (favoriteList) {
      onFilter();
    }
  }, [favoriteList, activeTab]);

  function onFavDelete() {
    dispatch(deleteFavoriteItem(currentUser?.id, listingId));
  }

  function pinIt() {
    dispatch(pinFavoriteItem(currentUser?.id, listingId));
  }

  function writeNotes() {
    setActiveSheet(true);
  }

  function onFilter() {
    if (
      !favoriteList ||
      !favoriteList?.items ||
      favoriteList?.items?.length <= 0
    ) {
      return;
    }
    const pinnedItems = favoriteList.items
      .filter((item) => item.isPinned)
      .sort((a, b) => b.time - a.time);
    const unPinnedItems = favoriteList.items.filter((item) => !item.isPinned);
    const sortedItems = [...pinnedItems, ...unPinnedItems];
    if (activeTab === "all") {
      setSaveList(sortedItems);
      return;
    }
    setSaveList(sortedItems.filter((li) => li.propertyType === activeTab));
  }

  const handleLongPress = (isLongPress, listingId, isPinned) => {
    setListingId(listingId);
    setIsPinned(isPinned);
    setIsLongPress(isLongPress);
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === "notes" || e.target.id === "show-notes") {
      setActiveSheet(false);
      setActiveShowNotesSheet(null);
      setItemMapSheet(false);
      setDetail(null);
    }
  };

  const saveNotes = () => {
    const params = {
      listingId: listingId,
      notes: notes,
      userId: currentUser?.id,
    };
    dispatch(saveFavoriteItemNotes(params));
  };

  const showNotes = (notes) => {
    setActiveShowNotesSheet(notes);
  };

  const showListOnMap = () => {
    setShowMapList(true);
  };
  const showList = () => {
    setShowMapList(false);
  };

  function openListingDetail(property) {
    const { listingId, propertyType, checkin_date, checkout_date, country } =
      property;
    let checkin = checkin_date;
    let checkout = checkout_date;
    if (propertyType === "monthlyStay") {
      checkin = dayjs(checkin_date).format("YYYY-MM-DD");
      checkout = dayjs(checkout_date).format("YYYY-MM-DD");
    }
    //setActiveStepAction(dispatch, listingId);
    showDetail(
      {
        properties: {
          ...property,
          id: listingId,
          accommodationType: propertyType,
          checkinDate: checkin,
          checkoutDate: checkout,
          country,
        },
      },
      true
    );
    setLayoutAction(dispatch, 1);
  }
  function handleSorting(option) {
    let lat = latitude;
    let lng = longitude;
    if (option === "distance_office") {
      lat = myLocation?.[0]?.latitude;
      lng = myLocation?.[0]?.longitude;
    }
    const sortedList = sortListings(saveList, lat, lng, option);
    setSaveList([...sortedList]);
  }

  const getCenter = () => {
    const items = saveList || [];
    if (items.length <= 0) return { lat: 38.7092534714915, lng: -9.23 };
    return { lat: items[0].lat, lng: items[0].lng };
  };

  const showItemMapCallback = (detail) => {
    setDetail(detail);
    setItemMapSheet(true);
  };

  return (
    <div className="flex flex-col px-4 relative h-full">
      {isLongPress && (
        <div className="actions flex flex-row items-center justify-between p-2 rounded-full cursor-pointer min-w-200 max-w-200 self-center">
          <DeleteIcon onClick={() => onFavDelete()} className="text-white" />
          <img
            className="w-6 h-6"
            onClick={() => pinIt()}
            src={pinIcon}
            alt="pin icon"
          />
          <img
            className="w-6 h-6"
            onClick={() => writeNotes()}
            src={notesIcon}
            alt="notes"
          />
        </div>
      )}

      <div className="flex items-center mb-4 left-0 -top-3 justify-between w-full">
        {/* Add other buttons/icons here, like the sort icon */}
        <ListingsButton
          callback={showList}
          total={saveList?.length}
          activeTab={activeTab}
        />
        <div className="flex items-center gap-2 mr-4">
          <Search />
          <img
            className="w-8 h-8 cursor-pointer"
            src={mapIcon}
            alt="map icon"
            onClick={showListOnMap}
          />
          <SortButton
            filterKey={"hotels"}
            isOpen={true}
            applied={true}
            sortOptions={sortOptions}
            handleSorting={handleSorting}
            defaultOption={"distance_me"}
            fontSize={"20px"}
          />
        </div>
      </div>

      {showMapList && (
        <div className="bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar">
          {mapCore && (
            <Map
              disableDefaultUI={true}
              fullscreenControl={false}
              defaultZoom={12}
              zoomControlOptions={null}
              zoomControl={false}
              mapId={"map"}
              defaultCenter={getCenter()}
              streetViewControl={false}
            >
              {(saveList || []).length &&
                saveList.map((item, index) => (
                  <AdvancedMarker position={{ lat: item.lat, lng: item.lng }}>
                    <div className="flex flex-col w-10 h-10 max-h-10 rounded-lg shadow-lg bg-secondary-l0 px-1 py-2 overflow-hidden items-center">
                      <span className={`font-bold text-[14px]`}>
                        €{item.price}
                      </span>

                      <img
                        className="w-9 h-9 rounded-10"
                        src={item.image}
                        alt="marker"
                      />
                    </div>
                  </AdvancedMarker>
                ))}
            </Map>
          )}
        </div>
      )}

      {!showMapList && (
        <div
          key={"listing"}
          className="space-y-4 mb-3 w-full overflow-auto hide-scrollbar"
        >
          {(saveList || []).map((property) =>
            property?.propertyType === "Hotel" ||
            property?.propertyType === "Shortterm" ||
            property?.propertyType === "monthlyStay" ? (
              <HotelListItem
                openListingDetail={openListingDetail}
                key={property?.listingId}
                detail={property}
                longPressCallback={(isLongPress, listingId, isPinned) =>
                  handleLongPress(isLongPress, listingId, isPinned)
                }
                showNotesCallback={showNotes}
                showItemMapCallback={(detail) => showItemMapCallback(detail)}
              />
            ) : (
              <ListItem
                openListingDetail={openListingDetail}
                key={property?.listingId}
                detail={property}
                longPressCallback={(isLongPress, listingId, isPinned) =>
                  handleLongPress(isLongPress, listingId, isPinned)
                }
                showNotesCallback={showNotes}
              />
            )
          )}
        </div>
      )}

      {activeSheet && (
        <div
          id="notes"
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end"
          onClick={handleBackdropClick}
        >
          <div className="flex flex-col h-[200px] bg-secondary-l0 w-full p-4 gap-1">
            <TextareaAutosize
              onChange={(e) => setNotes(e.target.value)}
              minRows={9}
              placeholder="Write here..."
            ></TextareaAutosize>
            <Button
              sx={{ borderRadius: "6px" }}
              variant="contained"
              color="primary"
              className="text-secondary-l0 bg-primary !px-2 !py-1 !text-[14px]"
              onClick={saveNotes}
            >
              Save Notes
            </Button>
          </div>
        </div>
      )}

      {activeShowNotesSheet && (
        <div
          id="notes"
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end"
          onClick={handleBackdropClick}
        >
          <div className="flex flex-col h-[200px] bg-secondary-l0 w-full p-4 gap-1">
            {activeShowNotesSheet}
          </div>
        </div>
      )}

      {itemMapSheet && (
        <div
          id="notes"
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end"
          onClick={handleBackdropClick}
        >
          <div className="flex flex-col h-[78%] bg-secondary-l0 w-full p-4 gap-1">
            {mapCore && (
              <Map
                disableDefaultUI={true}
                fullscreenControl={false}
                defaultZoom={12}
                zoomControlOptions={null}
                zoomControl={false}
                mapId={"map"}
                defaultCenter={{
                  lat: latitude,
                  lng: longitude,
                }}
                streetViewControl={false}
              >
                <PathWithMarkers
                  markerLib={markerLib}
                  propertyCoords={{
                    lat: detail?.lat || 0,
                    lng: detail?.lng || 0,
                  }}
                ></PathWithMarkers>
              </Map>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default List;
