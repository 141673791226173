import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: "#f1f3f4",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    transition: theme.transitions.create(["box-shadow", "border-color"], {
      duration: theme.transitions.duration.short,
    }),
    "&.Mui-focused": {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
      borderColor: theme.palette.primary.main,
    },
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.grey[600],
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 12px",
    fontSize: "14px",
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  padding: "8px 16px",
  fontSize: "14px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SearchInput = ({ value, onChange, onClear, onSearch }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm")); // Detect screen size

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(value); // Emit the value when Enter is pressed
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <CustomTextField
        variant="outlined"
        placeholder="Search..."
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress} // Handle the Enter key press
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                onClick={onClear}
                edge="end"
                aria-label="clear search"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth={!isDesktop} // Make the search input full-width on mobile
      />
    </div>
  );
};

export default SearchInput;
