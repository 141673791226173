// store/Map/mapReducers.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  center: { lat: 40.748817, lng: -73.985428 },
  zoom: 11,
  selectedPlace: null,
  currentLocation: null,
  drawingMode: false,
  selectedAccommodationFilter: ["hotels"],
  leftColMenu: "map",
  mapID: "map",
  activeStep: undefined,
  majorLocations: [
    {
      name: "Teleperformance",
      logo: "https://logo.clearbit.com/teleperformance.com",
      industry: "Outsourcing",
      latitude: 38.7369,
      longitude: -9.1427,
    },
    {
      name: "office",
      logo: "https://picsum.photos/200?random=1",
      latitude: 38.7783746,
      longitude: -9.1427,
    },
    {
      name: "my mom",
      logo: "https://picsum.photos/200?random=2",
      latitude: 38.7783746,
      longitude: -9.1627,
    },
  ],
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setCenter(state, action) {
      state.center = action.payload;
    },
    setCurrentLocation(state, action) {
      state.currentLocation = action.payload;
    },
    setZoom(state, action) {
      state.zoom = action.payload;
    },
    setSelectedPlace(state, action) {
      state.selectedPlace = action.payload;
    },
    setSelectedAccommodationFilter: (state, action) => {
      state.selectedAccommodationFilter = action.payload;
    },
    setDrawingMode: (state, action) => {
      state.drawingMode = action.payload;
    },
    setLeftColMenu: (state, action) => {
      state.leftColMenu = action.payload;
    },
    setMapID: (state, action) => {
      state.mapID = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
  },
});

export const {
  setCenter,
  setZoom,
  setSelectedPlace,
  setSelectedAccommodationFilter,
  setDrawingMode,
  setLeftColMenu,
  setMapID,
  setCurrentLocation,
  setActiveStep,
} = mapSlice.actions;

export default mapSlice.reducer;
