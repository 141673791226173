import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "@/views/Home";
import Login from "@/views/Login/DesktopLogin";
import NotFound from "@/views/NotFound";
import PrivateRoute from "@/routes/PrivateRoute";
import PublicRoute from "@/routes/PublicRoute";
import { Registration } from "../views/Registration";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import ResetPassword from "../views/ForgotPassword/ResetPassword";
import ListingScreen from "../views/Home/ListingScreen";

function DesktopRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Registration />
          </PublicRoute>
        }
      />
      <Route
        path="/forget-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/listing"
        element={
          <PrivateRoute>
            <ListingScreen />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/reset-password"
        element={
          <PrivateRoute>
            <ResetPassword />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DesktopRoutes;
