const blueMain = "#1b3ffc"; // Primary blue color (extracted from the design)
const blueDark = "#357ABD"; // Darker shade of primary blue
const blueLighter = "#1bd8fe"; // Lighter shade of primary blue

const greenMain = "#33FFE7";
const greenLight = "#f5fff7";
const greenOcean = "#017e73";
const greenOceanLight = "#eef8f7";

const redMain = "#de0000";

const orangeDark = "#ff4747";
const orangeDarker = "#ff5959";
const orangeMain = "#fec301";

const grayDark = "#000000";
const grayMain = "#333333";
const grayLight = "#666666";
const grayLighter = "#d8dbdf";
const gray0 = "#ffffff";
const grayLight1 = "#f0f2f5";
const grayLight2 = "#edf2f7";

const lifeMatchColors = {
  blue: {
    light: blueLighter,
    DEFAULT: blueMain,
    dark: blueDark,
  },
  red: {
    DEFAULT: redMain,
  },
  orange: {
    DEFAULT: orangeMain,
    dark: orangeDark,
    darker: orangeDarker,
    100: "#F6F4C7",
  },
  gray: {
    lighter: grayLighter,
    DEFAULT: grayMain,
    dark: grayDark,
    light: grayLight,
    l0: gray0,
    l1: grayLight1,
    l2: grayLight2,
  },
  green: {
    light: greenLight,
    DEFAULT: greenMain,
    dark: greenOcean,
    l0: greenOceanLight,
    100: "#00ccb4",
  },
  transparent: {
    DEFAULT: "transparent",
  },
};

const palette = {
  primary: lifeMatchColors.blue,
  accent: lifeMatchColors.red,
  warn: lifeMatchColors.orange,
  success: lifeMatchColors.green,
  secondary: lifeMatchColors.gray,
  transparent: lifeMatchColors.transparent,
  green: lifeMatchColors.green,
};

export const colors = {
  ...palette,
};
