import React, { useEffect, useState } from "react";
import { styled, Slider, SliderThumb, Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
} from "@vis.gl/react-google-maps";
import PropTypes from "prop-types";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import MapAutoSearch from "../../components/Search/MapAutoSearch";
import MylocationList from "../../components/Listing/MylocationList";
import AddLocationModal from "../../components/Listing/AddLocationModal";
import PlacesListView from "../../components/Listing/PlaceListView";
import { Home } from "@mui/icons-material";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

function SliderC({ valueChange, defaultRadius, activeItem }) {
  const [value, setValue] = useState(1.6); // Default value of 3 km

  useEffect(() => {
    setValue(defaultRadius);
  }, [defaultRadius]);

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other} max={10} min={0}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    console.log(newValue);
    valueChange(newValue);
  };

  return (
    <div className="w-40 absolute z-10 top-[5%] left-[20%]">
      <div
        className={
          "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
        }
      >
        <IconCircular
          className={"max-w-10 max-h-10 w-10 h-10"}
          alt={"language"}
          key={"language"}
          src={activeItem?.thumbnail || "https://picsum.photos/200"}
        />
      </div>
      <StyledSlider
        className="top-2 left-14"
        value={value}
        onChange={handleChange}
        onChangeCommitted={onSliderChangeCommited}
        min={0}
        max={100}
        step={0.1}
        valueLabelDisplay="off" // Auto display the value inside the thumb
        slots={{
          thumb: AirbnbThumbComponent, // Custom value label component
        }}
      />
    </div>
  );
}

const ListingMyLocation = ({ isMobileScreen = false }) => {
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const placeTypes = useSelector(
    (state) => state.user?.userStore?.userStarList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const [radius, setRadius] = useState(0);
  const [places, setPlaces] = useState([]);
  const [defaultRadius, setDefaultRadius] = useState(1.6); // Default value of 3 km
  const [locations, setLocations] = useState(myLocation);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isOpenAddModal, setToggleAddModal] = useState(false);
  const mapCore = useMapsLibrary("core");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const map = useMap();

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }
    const EARTH_RADIUS = 6371;
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);
    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(maxLat, maxLng)
    );
    return bounds;
  }

  useEffect(() => {
    if (!mapCore || !window.google) {
      return;
    }
    getPlaces();
    const bounds = getCircleBounds(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      radius
    );
    if (!bounds) return null;
    const filtered = myLocation.filter((loc) =>
      bounds.contains({ lat: loc.latitude, lng: loc.longitude })
    );

    setLocations(filtered);
    map && map.fitBounds(bounds);
  }, [radius]);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  async function getPlaces() {
    let result = [];
    const service = new window.google.maps.places.PlacesService(map);
    const center = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    if (placeTypes?.placeTypes?.length > 0) {
      const placePromises = placeTypes.placeTypes.map((type) => {
        return new Promise((resolve, reject) => {
          service.nearbySearch(
            {
              location: center,
              radius: (radius || defaultRadius) * 1000,
              type: type?.type,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = getClosestPlace(results);
                resolve({ ...place, placeType: type?.type });
              } else {
                resolve(null);
              }
            }
          );
        });
      });
      result = await Promise.all(placePromises);
      result = result.filter((place) => place !== null);
    }
    setPlaces(result);
  }

  function getClosestPlace(places) {
    if (!places || places.length === 0) return null;

    let closestPlace = null;
    let minDistance = Infinity;

    places.forEach((place) => {
      const distance = getDistanceFromLatLonInKm(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPlace = place;
      }
    });

    return closestPlace;
  }

  const toggleMyLocation = (location) => {
    if (!mapCore || !window.google) {
      return;
    }

    const dis = getDistanceFromLatLonInKm(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      location.latitude,
      location.longitude
    );
    setRadius(dis.toFixed(1));
    setDefaultRadius(dis.toFixed(1));
  };

  function handleSearchLocation(value) {
    setSearchLocation(value);
  }

  function onSearchChange(value) {
    setSearchValue(value);
  }

  const handleMarkerClick = (location) => {
    if (location) {
      setSelectedLocation({
        ...location,
        latitude: location?.latitude || location?.geometry?.location.lat(),
        longitude: location?.longitude || location?.geometry?.location.lng(),
        image: location.image || location?.photos?.[0]?.getUrl(),
      });
    } else {
      setSelectedLocation(location);
    }
  };

  console.log("searchLocation>>>>", searchLocation);
  return (
    <div className="flex w-full h-full">
      <div className="w-[15%] h-full">
        <MylocationList
          myLocation={myLocation}
          toggleLocation={toggleMyLocation}
          setToggleAddModal={setToggleAddModal}
          isOpenAddModal={isOpenAddModal}
        />
      </div>
      <div
        className={`flex justify-center items-center  bg-secondary-l0 ${
          selectedLocation ? "w-[60%]" : "w-[85%]"
        } h-full rounded-t-lg z-10 relative`}
      >
        {/* <SliderC
        className={"absolute  left-[50%] z-30"}
        defaultRadius={defaultRadius}
        valueChange={(radius) => setRadius(radius)}
        activeItem={activeItem?.properties}
      /> */}

        <div className="w-[30%] left-[10%] absolute z-30 top-[30px]">
          <MapAutoSearch
            onHandleChange={onSearchChange}
            value={searchValue}
            map={map}
            onSelectValue={handleSearchLocation}
          />
        </div>
        {mapCore && activeItem && (
          <Map
            disableDefaultUI={true}
            fullscreenControl={false}
            defaultZoom={15}
            zoomControlOptions={null}
            zoomControl={true}
            mapId={"map"}
            defaultCenter={{
              lat: activeItem?.properties?.latitude,
              lng: activeItem?.properties?.longitude,
            }}
            streetViewControl={false}
            className="h-full w-full"
          >
            {activeItem && (
              <AdvancedMarker
                position={{
                  lat: activeItem?.properties?.latitude,
                  lng: activeItem?.properties?.longitude,
                }}
              >
                <Home
                  sx={{ fontSize: 50, color: "red", shadow: 3 }}
                  className="w-12 h-12 shadow-lg  cursor-pointer"
                />
              </AdvancedMarker>
            )}
            {searchLocation && (
              <AdvancedMarker
                key={"search"}
                position={{
                  lat: searchLocation.latitude,
                  lng: searchLocation.longitude,
                }}
                onClick={() => handleMarkerClick(searchLocation)}
              ></AdvancedMarker>
            )}
            {(locations || []).length &&
              locations.map((item, index) => (
                <AdvancedMarker
                  key={item._id}
                  position={{ lat: item.latitude, lng: item.longitude }}
                >
                  <div className="flex  flex-col items-center w-14 h-14 rounded-full">
                    <img
                      className="rounded-full"
                      width={"100%"}
                      height={"100%"}
                      src={item.image}
                      alt="locaton"
                    />
                  </div>
                  <div className="ml-[2px] w-[92%] flex flex-col items-center font-bold text-[#fff] absolute bottom-[1px] rounded-bl-full rounded-br-full left-[0px] bg-[#000]">
                    {getDistanceFromLatLonInKm(
                      item?.latitude,
                      item?.longitude,
                      activeItem?.properties?.latitude,
                      activeItem?.properties?.longitude
                    ).toFixed(1)}
                    km
                    <svg
                      width="9"
                      height="10"
                      viewBox="0 0 9 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.68735 9.63086C0.869687 9.63086 1.04455 9.55843 1.17349 9.4295C1.30242 9.30056 1.37485 9.1257 1.37485 8.94336L1.37485 5.16211C1.37485 4.22367 1.81141 3.78711 2.74985 3.78711L6.90235 3.78711L6.21485 4.13086L5.33141 5.0143C5.20241 5.1433 5.12994 5.31827 5.12994 5.5007C5.12994 5.68314 5.20241 5.85811 5.33141 5.98711C5.46042 6.11611 5.63538 6.18859 5.81782 6.18859C6.00026 6.18859 6.17522 6.11611 6.30422 5.98711L8.71047 3.58086C8.7744 3.51701 8.82511 3.44119 8.8597 3.35772C8.8943 3.27426 8.91211 3.1848 8.91211 3.09445C8.91211 3.00411 8.8943 2.91464 8.8597 2.83118C8.82511 2.74772 8.7744 2.6719 8.71047 2.60805L6.30422 0.201797C6.24035 0.137921 6.16452 0.0872522 6.08106 0.0526829C5.9976 0.0181136 5.90815 0.000321388 5.81782 0.000321388C5.72748 0.000321388 5.63803 0.0181136 5.55458 0.0526829C5.47112 0.0872522 5.39529 0.137921 5.33141 0.201797C5.26754 0.265672 5.21687 0.341504 5.1823 0.424962C5.14773 0.50842 5.12994 0.59787 5.12994 0.688204C5.12994 0.778538 5.14773 0.867986 5.1823 0.951444C5.21687 1.0349 5.26754 1.11073 5.33141 1.17461L6.25954 2.06836L6.92641 2.41211L2.74985 2.41211C1.05516 2.41211 -0.000149727 3.46742 -0.000149727 5.16211V8.94336C-0.000149727 9.1257 0.0722826 9.30056 0.201214 9.4295C0.330145 9.55843 0.505014 9.63086 0.68735 9.63086Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </AdvancedMarker>
              ))}
            {places.map((place, index) => (
              <AdvancedMarker
                key={index}
                position={
                  place?.geometry?.location || {
                    lat: place?.latitude,
                    lng: place?.longitude,
                  }
                }
                title={place.name}
                onClick={() => handleMarkerClick(place)}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="relative flex items-center justify-center w-10 h-10 rounded-full ">
                    <img
                      className="rounded-full"
                      width={"100%"}
                      height={"100%"}
                      src={
                        place?.image ||
                        place?.photos?.[0]?.getUrl() ||
                        place?.icon
                      }
                      alt="Place"
                    />
                    <div className="ml-[0px] w-full text-[10px] flex flex-col items-center font-bold text-[#000] absolute bottom-[0px] rounded-bl-full rounded-br-full left-[0px] bg-[#fff]">
                      {getDistanceFromLatLonInKm(
                        activeItem?.properties?.latitude,
                        activeItem?.properties?.longitude,
                        place?.geometry?.location?.lat() || place?.latitude,
                        place?.geometry?.location?.lng() || place?.longitude
                      ).toFixed(1)}
                      km
                    </div>
                  </div>
                  <div className=" text-[10px] font-bold text-[#000] bg-[#fff] text-center p-1 rounded-10">
                    {place?.name}
                  </div>
                </div>
              </AdvancedMarker>
            ))}
            {isOpenAddModal && (
              <AddLocationModal
                map={map}
                open={isOpenAddModal}
                handleClose={() => setToggleAddModal(!isOpenAddModal)}
              />
            )}
          </Map>
        )}
      </div>
      {selectedLocation && (
        <div>
          <PlacesListView
            place={selectedLocation}
            handleMarkerClick={handleMarkerClick}
            distance={getDistanceFromLatLonInKm(
              activeItem?.properties?.latitude,
              activeItem?.properties?.longitude,
              selectedLocation?.latitude,
              selectedLocation?.longitude
            ).toFixed(1)}
          />
        </div>
      )}
    </div>
  );
};
export default ListingMyLocation;
