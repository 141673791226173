import { useEffect, useMemo, useRef, useCallback, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { useSelector } from "react-redux";

const PathWithMarkers = ({ markerLib, propertyCoords }) => {
  const map = useMap();
  const markersRef = useRef([]);
  const polylineRef = useRef(null);
  const [data, setData] = useState(null);

  // Array of 3 coordinates (for example)
  const coordinates = useSelector((state) => state.map?.coordinates); // Assuming you have coordinates in your redux store
  const currentLocation = useSelector((state) => state.map?.currentLocation);
  const majorLocation = useSelector((state) => state.map?.majorLocations)[0];

  useEffect(() => {
    setData([
      { image: undefined, coord: propertyCoords },
      {
        image: "https://picsum.photos/200",
        coord: {
          lat: currentLocation.latitude,
          lng: currentLocation.longitude,
        },
      },
      {
        image: majorLocation.logo,
        coord: { lat: majorLocation.latitude, lng: majorLocation.longitude },
      },
    ]);
  }, [propertyCoords]);

  const createMarker = useCallback(
    (coord, index, image) => {
      if (!map || !window.google) return null;

      const parentDiv = document.createElement("div");
      if (image) {
        const img = document.createElement("img");
        img.src = image;
        img.style.width = "50px";
        img.style.height = "50px";
        img.style.borderRadius = "999px";
        parentDiv.appendChild(img);
        return new markerLib.AdvancedMarkerElement({
          map,
          position: coord,
          content: parentDiv,
          title: `Marker ${index + 1}`,
        });
      } else {
        return new window.google.maps.Marker({
          position: coord,
          map: map,
          label: "", // Display the order number as a label
          title: ``,
        });
      }
    },
    [map, markerLib]
  );

  const drawPolyline = useCallback(
    (pathCoords) => {
      if (!map || !window.google) return null;

      // Remove the previous polyline if it exists
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }

      // Create a new polyline
      const polyline = new window.google.maps.Polyline({
        path: pathCoords,
        geodesic: true,
        strokeColor: "blue",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      // Set the polyline on the map
      polyline.setMap(map);
      polylineRef.current = polyline;
    },
    [map]
  );

  const fitMapToBounds = useCallback(
    (pathCoords) => {
      if (!map || !window.google) return;

      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds for each coordinate
      pathCoords.forEach((coord) => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
      });

      // Adjust the map to fit the bounds of the polyline
      map.fitBounds(bounds);
    },
    [map]
  );

  useEffect(() => {
    if (!map || !data) return;

    // Clear previous markers
    markersRef.current.forEach((marker) => marker?.setMap(null));
    markersRef.current = [];
    // Create new markers
    const newMarkers = data.map((item, index) => {
      return createMarker(item.coord, index, item.image);
    });

    markersRef.current = newMarkers;

    // Create the path coordinates for the polyline
    const pathCoords = data.map((item) => item.coord);

    // Draw the polyline on the map
    drawPolyline(pathCoords);
    fitMapToBounds(pathCoords);

    return () => {
      // Cleanup markers and polyline
      markersRef.current.forEach((marker) => marker?.setMap(null));
      polylineRef.current?.setMap(null);
    };
  }, [data, map, createMarker, drawPolyline]);

  return null;
};

export default PathWithMarkers;
