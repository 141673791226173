import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import { listType } from "../../../common/Helpers";

const Sorting = ({ options, selectedOption, onOptionChange, filterKey }) => {
  const handleChange = (event) => {
    onOptionChange(event.target.value);
  };

  return (
    <Box className="w-full flex justify-center">
      <FormControl component="fieldset" className="w-[95%]">
        <Typography variant="h6" className="font-semibold mb-4 text-[#000]">
          Sort by
        </Typography>
        <RadioGroup value={selectedOption} onChange={handleChange} className="">
          {options.map((option, index) => (
            <Box
              key={index}
              className="flex justify-between items-center hover:bg-gray-100 rounded-lg "
            >
              <div className="text-xs text-[#000]/80">{option.label}</div>
              <Radio
                value={option.value}
                sx={{
                  color: listType[filterKey].color,
                  "&.Mui-checked": {
                    color: listType[filterKey].color,
                  },
                }}
              />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Sorting;
