import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePosition from "../../hooks/usePosition";
import { setCoordinates } from "../../store/Filter/filterReducers";
import LMMap from "../../components/LeftCol/Map/Map";
import useFilter from "../../hooks/useFilter";
import {
  setActiveStepAction,
  setCenterAction,
} from "../../store/Map/mapActions";
import FilterTabs from "../Saved/FilterTabs";
import List from "../Saved/List";
import useSupabase from "../../hooks/useSupabase";
import { getCurrentUser } from "../../common/Helpers";
import {
  getBlackList,
  getUserLocationsAction,
} from "../../store/User/userAction";
import AccommodationFilter from "../../components/LeftCol/AccommodationFilter";
import ListingsView from "../Listings/ListingsView";
import ListingMenu from "../../components/ListingMenu/ListingMenu";
import CustomDrawer from "../../components/CustomDrawer";
import { toggleDrawerAction } from "../../store/Common/commonActions";
import MapSearch from "../../components/LeftCol/MapSearch";
import ListingDetailView from "../Listings/ListingDetailView";
import useListing from "../../hooks/useListing";

function ListingScreen() {
  const { showDetail } = useListing();
  const activeScreen = useSelector(
    (state) => state.common?.commonStore?.activeScreen
  );
  const [activeList, setActiveList] = useState(null);
  const isDrawerOpen = useSelector(
    (state) => state.common?.isDrawerOpen || false
  );
  const { fetchUser } = useSupabase();
  const [activeTab, setActiveTab] = useState("all");
  const [isOpenDetailView, setDetailView] = useState(false);
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  useFilter();

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getBlackList(currentUser?.id));
      dispatch(getUserLocationsAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  function toggleDetailView(selectedList = null) {
    if (selectedList) {
      setActiveStepAction(dispatch, selectedList?.id);
      showDetail(selectedList);
    }
    setDetailView(!isOpenDetailView);
  }

  function onActiveTab(value) {
    setActiveTab(value);
  }

  function toggleDrawer() {
    dispatch(toggleDrawerAction());
  }

  return (
    <React.Fragment>
      <div className="bg-[#fff] w-full h-full flex flex-row ">
        <div className="flex h-screen bg-[#fff] w-full">
          <div className="flex flex-col w-[40%] h-[89%] gap-6 bg-secondary-l0 relative ">
            <div className="absolute left-4 top-[15px] z-50 w-full">
              <div className="w-full max-w-[400px]">
                <MapSearch
                  onPlaceChanged={() => console.log("Place changed")}
                />
              </div>
            </div>
            {activeScreen === "search" ? (
              <LMMap isListingScreen={true} />
            ) : (
              <div className="flex flex-col w-full h-full gap-6 bg-secondary-l0">
                <FilterTabs onActiveTab={onActiveTab} />
                <List activeTab={activeTab || "all"} />
              </div>
            )}
          </div>
          <div className="flex flex-col w-[60%] h-full  bg-secondary-l0">
            <ListingMenu isListingScreen={true} />
            <ListingsView toggleAvailabilityView={toggleDetailView} />
          </div>
        </div>
      </div>
      <CustomDrawer open={isDrawerOpen} onClose={toggleDrawer} anchor="left">
        <AccommodationFilter />
      </CustomDrawer>
      {isOpenDetailView && (
        <ListingDetailView
          open={isOpenDetailView}
          onClose={toggleDetailView}
          activeList={activeList}
        />
      )}
    </React.Fragment>
  );
}

export default ListingScreen;
