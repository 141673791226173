import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { supabase } from "../../utils/helpers/supabaseClient";
import useSupabase from "../../hooks/useSupabase";
import Input from "../../components/Input/Input";
import MuiButton from "../../components/MuiButton/MuiButton";
import { Alert } from "@mui/material";

const ResetPassword = () => {
  const [password, setNewPassword] = useState({
    newPassword: "",
    confirmPassword: "",
    isPassNotMatch: false,
  });
  const { updateUserDetails } = useSupabase();
  function handleChange(e) {
    const { name, value } = e.target;
    setNewPassword({ ...password, [name]: value, isPassNotMatch: false });
  }

  function changePassword() {
    if (password.newPassword !== password.confirmPassword) {
      setNewPassword({ ...password, isPassNotMatch: true });
      return;
    }
    updateUserDetails({ password: password.newPassword });
  }

  return (
    <div className="bg-[#7fa9db] w-full h-full flex flex-1 flex-col items-center justify-center gap-2">
      <div className="w-[20%] gap-3">
        <div className="text-center text-lg text-[#fff] mb-4">
          Reset Password
        </div>
        <div className="mb-4">
          <Input
            autoComplete="new-password"
            label="Password"
            name="newPassword"
            value={password.newPassword}
            onChange={handleChange}
            type="password"
          />
        </div>
        <div className="mb-4">
          <Input
            autoComplete="new-password"
            label="Confirm Password"
            name="confirmPassword"
            value={password.confirmPassword}
            onChange={handleChange}
            type="password"
          />
        </div>
        {password.isPassNotMatch && (
          <Alert severity="error">Password is not matching.</Alert>
        )}
        <div className="mt-4 w-full">
          <MuiButton onClick={changePassword}>Sign Up</MuiButton>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
