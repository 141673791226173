import React from "react";
import { Modal, Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Reusable Modal Component
const CustomModal = ({
  open,
  onClose,
  onConfirm,
  title = "Are you sure?",
  content = "Do you really want to proceed?",
  confirmText,
  cancelText,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="relative bg-[#fff] rounded-lg shadow-lg p-4"
        sx={{
          width: { xs: "100%", sm: "400px" },
          margin: "auto",
          top: "20%",
        }}
      >
        {/* Close Button */}
        <div className="flex !justify-between items-center w-full">
          {/* Modal Title */}
          <div className="text-md font-semibold">{title}</div>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        {/* Modal Content */}
        <p className="text-base mb-4">{content}</p>

        {/* Modal Footer with Confirm and Cancel Buttons */}
        <div className="flex justify-end gap-4 mt-6">
          {cancelText && (
            <Button variant="outlined" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button variant="contained" color="primary" onClick={onConfirm}>
              {confirmText}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
