import { Directions, LocalPhone } from "@mui/icons-material";
import React, { useState } from "react";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getDistanceFromLatLonInKm,
  gotoDirections,
} from "../../common/Helpers";
import SearchableList from "./SearchableList";
import myLocation from "./location.json";

const InfoBuyRentCard = ({ details, listingDetail }) => {
  const [isShowSearchableList, setSearchableList] = useState(false);
  const [majorLocation, setMajorLocation] = useState(myLocation[0]);
  let price = details?.properties?.price || listingDetail?.price;
  let thumbnail =
    details?.properties?.thumbnail ||
    details?.properties?.multimedia?.images?.[0]?.url;
  let listingKey =
    details?.properties?.listingKey || details?.properties?.listingType;
  let propertyName =
    details?.properties?.propertyName ||
    details?.properties?.suggestedTexts?.title;
  const listingLatLng = details?.geometry?.coordinates || [
    details?.properties?.ubication?.latitude,
    details?.properties?.ubication?.longitude,
  ];
  const { phoneNumber } = listingDetail || {};
  const filterStyle = {
    rent: { borderClr: "#94DC20", text: "For Rent" },
    buy: { borderClr: "#DC8520", text: "For Sale" },
  };
  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  function showSearchableList() {
    setSearchableList(!isShowSearchableList);
  }
  function onSelectMajorLocation(location) {
    setMajorLocation(location);
    setSearchableList(!isShowSearchableList);
  }
  const dist = getDistanceFromLatLonInKm(
    majorLocation.latitude,
    majorLocation.longitude,
    listingLatLng?.[0],
    listingLatLng?.[1]
  );
  return (
    <React.Fragment>
      <div className="  pb-0 bg-white shadow-md rounded-lg w-full">
        {/* Left Section: Hotel image, name, and rating */}
        <div className="flex items-center w-full">
          {/* Hotel Image */}
          <img
            className="w-16 h-16 rounded-md"
            src={thumbnail}
            alt="rent"
            style={{
              border: `2px solid ${filterStyle?.[listingKey]?.borderClr}`,
            }}
          />
          {/* Hotel Name, Ratings, and Reviews */}
          <div className="w-[100%]">
            <div className="flex justify-between items-center mb-2">
              <div
                style={{
                  background: `${filterStyle?.[listingKey]?.borderClr}`,
                }}
                className={`text-[#fff] !rounded-l-none rounded-md text-center text-xs py-1 px-1`}
              >
                {filterStyle?.[listingKey]?.text}
              </div>
              <div className="flex items-center space-x-2">
                <div>
                  {" "}
                  <span
                    style={{ color: `${filterStyle?.[listingKey]?.borderClr}` }}
                    className={`text-xl font-bold `}
                  >
                    €{" "}
                    {listingKey === "rent"
                      ? `${Math.floor(price)} / Month`
                      : Math.floor(price)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="!text-[#2E6ADC] text-base font-normal">
                {propertyName}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center w-[95%]">
          <div className="flex items-center my-2">
            <div className="text-xs !text-[#000]/50">
              <div
                className="w-10 h-10 cursor-pointer"
                onClick={showSearchableList}
              >
                <IconCircular
                  alt={"locaton"}
                  key={"location"}
                  src={
                    majorLocation?.logo || "https://picsum.photos/200?random=5"
                  }
                />
              </div>
            </div>
            <div className="text-xs !text-[#000] mx-1">
              {dist.toFixed(2)} km
            </div>
            <div className="text-xs !text-[#000]/50">to my office</div>
            <div className="flex items-center">
              <Directions
                onClick={() =>
                  gotoDirections(
                    [majorLocation.latitude, majorLocation.longitude],
                    [listingLatLng?.[0], listingLatLng?.[1]]
                  )
                }
                sx={{ color: "#1289F6" }}
              />
            </div>
          </div>
          <div
            onClick={handleDial}
            className="flex items-center space-x-2 rounded-full p-1 w-6 h-6 bg-[#1289F6]"
          >
            <LocalPhone sx={{ color: "#fff" }} />
          </div>
        </div>
      </div>
      {isShowSearchableList && (
        <div className="bg-secondary-l0 w-full h-[65%] p-0 rounded-t-lg z-10 overflow-y-auto hide-scrollbar absolute">
          <SearchableList onSelectMajorLocation={onSelectMajorLocation} />
        </div>
      )}
    </React.Fragment>
  );
};
export default InfoBuyRentCard;
