export const airbnbPropertyTypes = [
  { title: "House", id: "1" },
  { title: "Flat", id: "3" },
  { title: "Guest house", id: "2" },
  { title: "Hotel", id: "4" },
  { title: "Camping and glamping", id: "5" },
  { title: "Converted spaces", id: "8" },
  { title: "Farmland stays", id: "10" },
  { title: "Floating homes", id: "6" },
  { title: "Minimalist spaces", id: "11" },
  { title: "Nature stays", id: "7" },
  { title: "Repurposed vehicles", id: "9" },
];
export const airbnbAmenities = [
  { title: "Kitchen", id: "8" },
  { title: "Heating", id: "30" },
  { title: "Air conditioning", id: "5" },
  { title: "Washing machine", id: "33" },
  { title: "Dryer", id: "34" },
  { title: "Wifi", id: "4" },
  { title: "Breakfast", id: "16" },
  { title: "Indoor fireplace", id: "27" },
  { title: "Iron", id: "46" },
  { title: "Hair dryer", id: "45" },
  { title: "Dedicated workspace", id: "47" },
  { title: "TV", id: "58" },
  { title: "Cot", id: "286" },
  { title: "High chair", id: "64" },
  { title: "Smoke alarm", id: "35" },
  { title: "Carbon monoxide alarm", id: "36" },
  { title: "Private bathroom", id: "78" },
  { title: "BBQ grill", id: "99" },
  { title: "En suite bathroom", id: "275" },
  { title: "Free parking", id: "9" },
  { title: "Gym", id: "15" },
  { title: "Hot tub", id: "25" },
  { title: "Pool", id: "7" },
  { title: "EV charger", id: "97" },
  { title: "Smoking allowed", id: "11" },
  { title: "King bed", id: "1000" },
];
export const airbnbTypeOfPlace = [
  { title: "Entire home", id: "Entire home/apt" },
  { title: "Room", id: "Private room" },
  { title: "Shared room", id: "Shared room" },
];
