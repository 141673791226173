import React, { useEffect, useState } from "react";
import IconCircular from "../../components/IconCircular/IconCircular";
import { Slider, SliderThumb, styled } from "@mui/material";
import PropTypes from "prop-types";

const StyledSlider = styled(Slider)({
    color: "#9FE330", // Track color (yellow)
    height: 8,
    "& .MuiSlider-thumb": {
      height: 24,
      width: 50,
      top: "15px",
      backgroundColor: "#29ABD4", // Blue background color for the thumb
      borderRadius: "18px", // Rounded capsule shape
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "inherit",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-rail": {
      opacity: 1,
      backgroundColor: "#DFD3D3", // Light gray rail color
    },
  });
  
  function ValueLabelComponent(props) {
    const { children, value } = props;
  
    return (
      <div>
        {React.cloneElement(children, {
          style: {
            ...children.props.style,
            position: "relative",
          },
        })}
        <div
          className="text-primary"
          style={{
            position: "absolute",
            fontWeight: "bold",
            top: "-25px",
            right: 0,
            textShadow: "1px 1px 2px black",
            whiteSpace: "nowrap",
          }}
        >
          {`${value} km`}
        </div>
      </div>
    );
  }
  
  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  const BirdEyeSlider = ({ valueChange, defaultRadius, activeItem }) => {
    const [value, setValue] = useState(1.6); // Default value of 3 km
  
    useEffect(() => {
      setValue(defaultRadius);
    }, [defaultRadius]);
  
    function AirbnbThumbComponent(props) {
      const { children, ...other } = props;
      return (
        <div className="mt-2">
          <SliderThumb {...other} max={10} min={0}>
            {children}
            <span className="text-[#fff] text-[12px]">{value}km</span>
          </SliderThumb>
        </div>
      );
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const onSliderChangeCommited = (event, newValue) => {
      console.log(newValue);
      valueChange(newValue);
    };
  
    return (
      <div className="w-40 absolute z-10 top-[15%] left-[20%]">
        <div
          className={
            "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
          }
        >
          <IconCircular
            className={"max-w-10 max-h-10 w-10 h-10"}
            alt={"language"}
            key={"language"}
            src={activeItem?.thumbnail || "https://picsum.photos/200"}
          />
        </div>
        <StyledSlider
          className="top-2 left-14"
          value={value}
          onChange={handleChange}
          onChangeCommitted={onSliderChangeCommited}
          min={0}
          max={100}
          step={0.1}
          valueLabelDisplay="off" // Auto display the value inside the thumb
          slots={{
            thumb: AirbnbThumbComponent, // Custom value label component
          }}
        />
      </div>
    );
  }
  export default BirdEyeSlider;