import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "@/App";
import reportWebVitals from "./reportWebVitals";
import store from "@/store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const rootEl = document.getElementById("root");
rootEl.classList.add("flex", "flex-col", "flex-none", "overflow-hidden");
const root = ReactDOM.createRoot(rootEl);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
