import { Routes, Route } from "react-router-dom";
import { MobileHome } from "@/views/Home";
import NotFound from "@/views/NotFound";
import PrivateRoute from "@/routes/PrivateRoute";
import PublicRoute from "@/routes/PublicRoute";
import MobileLogin from "../views/Login/MobileLogin";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";

function MobileRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <MobileLogin />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <MobileHome />
          </PublicRoute>
        }
      />
      <Route
        path="/forget-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <MobileHome />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MobileRoutes;
