import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotels: [],
  loadingStore: null,
  listingDetail: null,
  nearbyPlace: null,
  listingOverview: null,
  infoPrice: null,
  swipeScreenActiveListing: null,
  listingMedia: [],
  listingMediaVideo: [],
};

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setHotels: (state, action) => {
      state.hotels = action?.payload;
    },
    setListingDetail: (state, action) => {
      state.listingDetail = action?.payload;
    },
    setListingOverview: (state, action) => {
      state.listingOverview = action?.payload;
    },
    setListingInfoPrice: (state, action) => {
      state.infoPrice = action?.payload;
    },
    setNearbyPlace: (state, action) => {
      state.nearbyPlace = action?.payload;
    },
    setListingMedia: (state, action) => {
      state.listingMedia = action?.payload;
    },
    setListingMediaVideo: (state, action) => {
      state.listingMediaVideo = action?.payload;
    },
    setRequestStatus: (state, action) => {
      state.loadingStore = { ...state.loadingStore, ...action.payload };
    },
    setSwipeActiveList: (state, action) => {
      state.swipeScreenActiveListing = action.payload;
    },
  },
});
export const {
  setHotels,
  setRequestStatus,
  setListingDetail,
  setNearbyPlace,
  setListingMedia,
  setListingOverview,
  setListingInfoPrice,
  setListingMediaVideo,
  setSwipeActiveList,
} = hotelSlice.actions;
export default hotelSlice.reducer;
