import { useState } from "react";
import { supabase } from "../utils/helpers/supabaseClient";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../components/SnackbarProvider/SnackbarProvider";

function useSupabase() {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const [supabaseState, setState] = useState({
    signupError: null,
    isProcessing: false,
    isPasswordResetEmailSent: false,
    session: null,
  });
  const [currentUser, setUser] = useState(null);
  // register user
  async function signUp({ email, password, name }) {
    setState({ ...supabaseState, isProcessing: true });
    let supabaseErr = validation(email, password);
    if (!supabaseErr.isError) {
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
          },
        },
      });
      if (error) {
        supabaseErr = {
          message: error?.message,
          isError: true,
        };
      } else {
        showSnackbar("Please verify your email and login.", "success");
      }
    }
    setState({
      ...supabaseState,
      signupError: supabaseErr,
      isProcessing: false,
    });
  }
  // login user
  async function login({ email, password }) {
    setState({ ...supabaseState, isProcessing: true });
    let supabaseErr = validation(email, password);
    if (!supabaseErr.isError) {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        supabaseErr = {
          message: error?.message,
          isError: true,
        };
      }
    }
    setState({
      ...supabaseState,
      signupError: supabaseErr,
      isProcessing: false,
    });
  }
  // login user
  async function loginWithProvider(provider) {
    let supabaseErr = {};
    const { error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        scopes: "email",
      },
    });
    if (error) {
      supabaseErr = {
        message: error?.message,
        isError: true,
      };
    }
    setState({
      ...supabaseState,
      signupError: supabaseErr,
    });
  }

  function validation(email, password) {
    let supabaseErr = { message: null, isError: false };
    if (!email) {
      supabaseErr = { message: "Enter valid email.", isError: true };
    }
    if (!password && !supabaseErr.isError) {
      supabaseErr = { message: "Enter valid password.", isError: true };
    }
    return supabaseErr;
  }

  async function handlePasswordReset(email) {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setState({
        ...supabaseState,
        passResetErr: { message: error?.message, isError: true },
      });
    } else {
      setState({
        ...supabaseState,
        isPasswordResetEmailSent: true,
      });
    }
  }

  const updateUserDetails = async (payload) => {
    const { error } = await supabase.auth.updateUser(payload);
    if (error) {
      showSnackbar(error?.message, "error");
    } else {
      showSnackbar("Updated successfully", "success");
    }
  };

  const fetchUser = async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
  };

  return {
    signUp,
    login,
    loginWithProvider,
    handlePasswordReset,
    updateUserDetails,
    supabaseState,
    currentUser,
    fetchUser,
  };
}

export default useSupabase;
