import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { trimString } from "../../../common/Helpers";
import { Card } from "@mui/material";
import { placeTypes } from "../../../components/BottomSheetTabs/places";

const ChangeStarLocation = ({
  open,
  handleClose,
  location,
  userStarList,
  onSave,
}) => {
  const [state, setState] = useState({
    byPlace: null,
  });
  const { byPlace } = state;

  const handleSave = () => {
    const saveList = userStarList.filter(
      (li) => li?.type !== location.placeType
    );
    saveList.push({ type: byPlace.type });
    onSave(saveList);
    handleClose();
  };

  function handleChange(place) {
    setState({ ...state, byPlace: { ...place, name: place.label } });
  }

  if (!open) return null;

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-[#EEEAEA] rounded-3xl  shadow-lg w-[90%]">
        <div className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] text-[#fff] flex justify-between p-1 rounded-t-2xl font-bold text-xs">
          <div>Change Location</div>
          <Close onClick={handleClose} className="cursor-pointer" />
        </div>
        <div>
          <div className="w-full h-full flex justify-center text-xs items-center space-x-4 mt-4">
            <div className="">Replace</div>
            <div className=" space-x-2">
              <div>
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={location?.image}
                  alt="location"
                />
                <div>{trimString(location?.name, 15)}</div>
              </div>
            </div>
            <div className="">By</div>
            <div className="">
              {byPlace ? (
                <div>
                  {byPlace?.iconType === "custom" ||
                  byPlace.type === "mylocation" ? (
                    <img
                      className="w-10 h-10 rounded-full object-cover"
                      src={byPlace.image || byPlace.icon}
                      alt="airport"
                    />
                  ) : (
                    <i className={byPlace.icon}></i>
                  )}
                  <div>{trimString(byPlace?.name, 15)}</div>
                </div>
              ) : (
                <div className="w-10 h-10 bg-[#fff] rounded-full"></div>
              )}
            </div>
          </div>

          <div className="mt-4 ml-2 mb-1 text-xs font-bold">
            General Locations
          </div>
          <div className="flex flex-row flex-grow w-full mb-4 overflow-x-auto hide-scrollbar">
            {placeTypes?.length > 0 &&
              placeTypes.map((place, index) => {
                return (
                  <div
                    className="flex flex-col gap-1 self-center cursor-pointer w-full items-center max-w-[100px] min-w-[80px]"
                    key={index}
                    onClick={() => handleChange(place, "place")}
                  >
                    <Card
                      sx={{
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        overflow: "hidden",
                        textAlign: "center",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {place?.iconType === "custom" ? (
                        <img
                          width={40}
                          height={40}
                          src={place.icon}
                          alt="airport"
                        />
                      ) : (
                        <i className={place.icon}></i>
                      )}
                    </Card>
                    <span className="text-xs">
                      {" "}
                      {trimString(place.label, 8)}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <div className=" w-auto px-6">
          {/* Save Button */}
          <div className="flex justify-center">
            <button
              onClick={handleSave}
              className="py-2 px-3 my-4 bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] text-[#fff]  rounded-[10px] border-none  cursor-pointer"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeStarLocation;
