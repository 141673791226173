import React, { Fragment } from "react";

const Content = ({ children }) => {
  return (
    <Fragment>
      <main className="flex flex-grow">{children}</main>
    </Fragment>
  );
};

export default Content;
