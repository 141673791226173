import React, { useEffect, useState } from "react";
import { styled, Slider, SliderThumb } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import PropTypes from "prop-types";
import IconCircular from "../../../components/IconCircular/IconCircular";
import {
  getCurrentUser,
  getDistanceFromLatLonInKm,
  trimString,
} from "../../../common/Helpers";
import MapAutoSearch from "../../../components/Search/MapAutoSearch";
import MuiButton from "../../../components/MuiButton/MuiButton";
import ChangeStarLocation from "./ChangeStarLocation";
import {
  addMyLocationAction,
  getPlaceTypesAction,
  savePlaceTypesAction,
  updatePlaceTypesAction,
} from "../../../store/User/userAction";
import CustomModal from "../../../components/CustomModal";
import { Home } from "@mui/icons-material";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

function SliderC({ valueChange, defaultRadius, activeItem }) {
  const [value, setValue] = useState(5); // Default value of 3 km

  useEffect(() => {
    setValue(defaultRadius);
  }, [defaultRadius]);

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other} max={10} min={0}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    valueChange(newValue);
  };

  return (
    <div className="w-40 absolute z-10 top-[5%] left-[20%]">
      <div
        className={
          "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
        }
      >
        <IconCircular
          className={"max-w-10 max-h-10 w-10 h-10"}
          alt={"language"}
          key={"language"}
          src={activeItem?.thumbnail || "https://picsum.photos/200"}
        />
      </div>
      <StyledSlider
        className="top-2 left-14"
        value={value}
        onChange={handleChange}
        onChangeCommitted={onSliderChangeCommited}
        min={0}
        max={10}
        step={0.1}
        valueLabelDisplay="off" // Auto display the value inside the thumb
        slots={{
          thumb: AirbnbThumbComponent, // Custom value label component
        }}
      />
    </div>
  );
}

const StarView = ({ isMobileScreen = false }) => {
  const dispatch = useDispatch();
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const placeTypes = useSelector(
    (state) => state.user?.userStore?.userStarList
  );
  const [radius, setRadius] = useState(5);
  const [places, setPlaces] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlace, setSelectedSearchplace] = useState("");
  const [locations, setLocations] = useState(myLocation);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isOpenAddModal, setToggleChangeModal] = useState(false);
  const [isOpenConfirmModal, setConfirmModal] = useState(false);
  const currentUser = getCurrentUser();
  const mapCore = useMapsLibrary("core");
  const map = useMap();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getPlaceTypesAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  useEffect(() => {
    if (!mapCore || !map || !window.google || !activeItem) return;
    getPlaces();
    const bounds = getCircleBounds(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      radius
    );
    if (!bounds) return null;
    const filtered = myLocation.filter((loc) =>
      bounds.contains({ lat: loc.latitude, lng: loc.longitude })
    );

    setLocations(filtered);
    map && map.fitBounds(bounds);
  }, [mapCore, map, activeItem, radius, placeTypes]);

  async function getPlaces() {
    let result = [];
    const service = new window.google.maps.places.PlacesService(map);
    const center = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    if (placeTypes?.placeTypes?.length > 0) {
      const placePromises = placeTypes.placeTypes.map((type) => {
        return new Promise((resolve, reject) => {
          service.nearbySearch(
            {
              location: center,
              radius: radius * 1000,
              type: type?.type,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = getClosestPlace(results);
                resolve({ ...place, placeType: type?.type });
              } else {
                resolve(null);
              }
            }
          );
        });
      });
      result = await Promise.all(placePromises);
      result = result.filter((place) => place !== null);
    }
    setPlaces(result);
  }

  function getClosestPlace(places) {
    if (!places || places.length === 0) return null;

    let closestPlace = null;
    let minDistance = Infinity;

    places.forEach((place) => {
      const distance = getDistanceFromLatLonInKm(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPlace = place;
      }
    });

    return closestPlace;
  }

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }
    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)

    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;

    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    return bounds;
  }

  function handleSearchLocation(value) {
    setSelectedSearchplace({ ...value, locationName: value?.name });
    setLocations([...locations, ...[{ ...value, locationName: value?.name }]]);
  }

  const handleMarkerClick = (location, type) => {
    setSelectedLocation({
      ...location,
      latitude: location?.latitude || location?.geometry?.location.lat(),
      longitude: location?.longitude || location?.geometry?.location.lng(),
      image: location.image || location?.photos?.[0]?.getUrl(),
    });
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };
  const toggleChangeModal = () => {
    setToggleChangeModal(!isOpenAddModal);
  };

  function onSearchChange(value) {
    setSearchValue(value);
  }

  function onUpdatePlaceTypeList(updatedList) {
    if (placeTypes?._id) {
      dispatch(
        updatePlaceTypesAction({
          placeTypes: updatedList,
          userId: currentUser?.id,
        })
      );
    } else {
      dispatch(
        savePlaceTypesAction({
          placeTypes: updatedList,
          userId: currentUser?.id,
        })
      );
    }
  }

  function savePlaceAsMyLocation() {
    dispatch(
      addMyLocationAction({
        ...searchPlace,
        userId: currentUser?.id,
        address: searchPlace?.searchValue,
      })
    );
    toggleConfirmModal();
  }
  function toggleConfirmModal() {
    setConfirmModal(!isOpenConfirmModal);
  }

  return (
    <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar relative">
      <SliderC
        className={"absolute left-[50%] z-30"}
        defaultRadius={radius}
        valueChange={(radius) => setRadius(radius)}
        activeItem={activeItem?.properties}
      />
      {mapCore && activeItem && (
        <Map
          disableDefaultUI={true}
          fullscreenControl={false}
          defaultZoom={15}
          zoomControlOptions={null}
          zoomControl={true}
          mapId={"map"}
          defaultCenter={{
            lat: activeItem?.properties?.latitude,
            lng: activeItem?.properties?.longitude,
          }}
          streetViewControl={false}
          className="h-full"
        >
          {activeItem && (
            <AdvancedMarker
              position={{
                lat: activeItem?.properties?.latitude,
                lng: activeItem?.properties?.longitude,
              }}
            >
              <Home
                sx={{ fontSize: 50, color: "red", shadow: 3 }}
                className="w-12 h-12 shadow-lg  cursor-pointer"
              />
            </AdvancedMarker>
          )}
          {(locations || []).length &&
            locations.map((item, index) => (
              <AdvancedMarker
                key={item._id}
                position={{ lat: item.latitude, lng: item.longitude }}
              >
                {/* Perfect heart shape using Tailwind */}
                <div className="relative flex items-center justify-center">
                  <div className="relative w-10 h-10">
                    {/* Top left circle */}
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-5 h-5 bg-red-500 rounded-full"></div>
                    {/* Top right circle */}
                    <div className="absolute top-0 right-1/2 transform translate-x-1/2 w-5 h-5 bg-red-500 rounded-full"></div>
                    {/* Bottom triangle */}
                    <div className="absolute top-1/4 left-0 w-10 h-10 bg-red-500 rotate-45 transform origin-bottom-left"></div>
                  </div>
                  {/* Image overlay inside the heart */}
                  <img
                    className="absolute w-10 h-10 rounded-md object-cover"
                    src={item.image}
                    alt="location"
                  />
                </div>

                {/* Distance display */}
                <div className="ml-[2px] w-[92%] text-[10px] flex flex-col items-center font-bold text-[#fff] absolute bottom-[10px] rounded-bl-full rounded-br-full left-[0px] bg-[#000]">
                  {getDistanceFromLatLonInKm(
                    item?.latitude,
                    item?.longitude,
                    activeItem?.properties?.latitude,
                    activeItem?.properties?.longitude
                  ).toFixed(1)}
                  km
                </div>
                {/* Location name */}
                <div className=" text-[10px] font-bold text-[#fff] bg-[#000] text-center rounded-10 p-1">
                  {trimString(item.locationName, 10)}
                </div>
              </AdvancedMarker>
            ))}

          {places.map((place, index) => (
            <AdvancedMarker
              key={index}
              position={
                place?.geometry?.location || {
                  lat: place?.latitude,
                  lng: place?.longitude,
                }
              }
              title={place.name}
              onClick={() => handleMarkerClick(place)}
            >
              <div className="flex flex-col items-center justify-center">
                <div className="relative flex items-center justify-center w-10 h-10 rounded-full ">
                  <img
                    className="rounded-full"
                    width={"100%"}
                    height={"100%"}
                    src={
                      place?.image ||
                      place?.photos?.[0]?.getUrl() ||
                      place?.icon
                    }
                    alt="Place"
                  />
                  <div className="ml-[0px] w-full text-[10px] flex flex-col items-center font-bold text-[#000] absolute bottom-[0px] rounded-bl-full rounded-br-full left-[0px] bg-[#fff]">
                    {getDistanceFromLatLonInKm(
                      activeItem?.properties?.latitude,
                      activeItem?.properties?.longitude,
                      place?.geometry?.location?.lat() || place?.latitude,
                      place?.geometry?.location?.lng() || place?.longitude
                    ).toFixed(1)}
                    km
                  </div>
                </div>
                <div className=" text-[10px] font-bold text-[#000] bg-[#fff] text-center p-1 rounded-10">
                  {place?.name}
                </div>
              </div>
            </AdvancedMarker>
          ))}
          {selectedLocation && (
            <InfoWindow
              position={{
                lat: selectedLocation.latitude,
                lng: selectedLocation.longitude,
              }}
              onCloseClick={handleInfoWindowClose}
            >
              <div>
                <div
                  onClick={toggleChangeModal}
                  className="p-1 text-xs text-[#fff] bg-primary mb-2 rounded-4 cursor-pointer"
                >
                  Change location
                </div>
              </div>
            </InfoWindow>
          )}
        </Map>
      )}
      <div className="absolute bottom-0 right-0 left-0 flex flex-row bg-secondary-l0 bg-opacity-70 p-3 gap-4 items-center">
        <div className="w-[90%] absolute bottom-[50px]">
          <MapAutoSearch
            map={map}
            onSelectValue={handleSearchLocation}
            onHandleChange={onSearchChange}
            value={searchValue}
            isShowSaveIcon={true}
            onSave={toggleConfirmModal}
          />
        </div>
      </div>
      {isOpenAddModal && (
        <ChangeStarLocation
          open={isOpenAddModal}
          handleClose={toggleChangeModal}
          location={selectedLocation}
          userStarList={placeTypes?.placeTypes}
          onSave={onUpdatePlaceTypeList}
        />
      )}
      <CustomModal
        open={isOpenConfirmModal}
        onClose={toggleConfirmModal}
        onConfirm={savePlaceAsMyLocation}
        title="Save Location"
        content="Are you sure you want to save this location?"
        confirmText="Yes, Save"
      />
    </div>
  );
};

StarView.propTypes = {
  isMobileScreen: PropTypes.bool,
};

export default StarView;
