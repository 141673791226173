import { useEffect, useMemo, useRef, useCallback } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import "./places.css";

const DrawPlaces = ({ places, markerLib }) => {
  const map = useMap();
  const markersRef = useRef([]); // Ref to store markers

  const infoWindow = useMemo(() => {
    if (window.google) {
      return new window.google.maps.InfoWindow();
    }
    return null;
  }, []);

  const createMarkers = useCallback(
    (results) => {
      if (!map || !infoWindow || !window.google) return [];
      const bounds = new window.google.maps.LatLngBounds();
      const newMarkers = results.map((place) => {
        if (!place.location) return null;
        const placeName = place?.displayName.slice(0, 12) + "...";
        const parentDiv = document.createElement("div");
        parentDiv.style.color = "#ffffff";
        parentDiv.style.position = "relative";
        const icon = document.createElement("img");
        icon.src = `${
          place?.photos?.[0]?.getURI({ maxWidth: 35, maxHeight: 35 }) ||
          place?.customIcon ||
          "https://picsum.photos/200?random=3"
        }`;
        icon.style.boxShadow = "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px";
        icon.style.border = "2px solid #ffffff";
        icon.style.display = "flex";
        icon.style.position = "absolute";
        icon.style.alignItems = "center";
        icon.style.justifyContent = "center";
        icon.style.width = "40px";
        icon.style.height = "40px";
        icon.style.borderRadius = "100%";
        icon.style.padding = "2px";
        icon.style.background = place.icon_background_color;
        const ribbon = document.createElement("div");
        ribbon.style.position = "absolute";
        ribbon.style.width = "120px";
        ribbon.style.minWidth = "120px";
        ribbon.style.right = "-13px";
        ribbon.style.top = "8px";
        ribbon.style.border = "2px solid #ffffff";
        ribbon.style.padding = "10px";
        ribbon.style.fontSize = "10px";
        ribbon.style.paddingTop = "5px";
        ribbon.style.paddingBottom = "5px";
        ribbon.style.borderRadius = "20px";
        ribbon.style.background = place.icon_background_color;
        ribbon.innerHTML = `${placeName} ${place?.rating} <span style="color: yellow; font-size: 15px">★</span>`;
        icon.innerHTML = `<i class="${place.customIcon}" style="font-size: 20px;"></i>`;
        parentDiv.append(ribbon);
        parentDiv.append(icon);

        const marker = new markerLib.AdvancedMarkerElement({
          map,
          position: { lat: place.location.lat(), lng: place.location.lng() },
          content: parentDiv,
          title: "A marker using a FontAwesome icon for the glyph.",
        });
        bounds.extend({ lat: place.location.lat(), lng: place.location.lng() });
        marker.addListener("click", () => {
          const img =
            place.photos?.length > 0
              ? `<img style="height: 200px;
                  width: 250px;
                  display: flex;
                  align-items: start;
                  justify-content: flex-start;
                  align-self: self-start;
                  border-radius: 10px;" src="${place?.photos?.[0]?.getURI()}" alt="${
                  place?.name
                }">`
              : "";
          infoWindow.setContent(`
          <div style="display: flex;
            flex-direction: column;
            align-items: flex-start;max-width:250px">
            <div style="display:flex; flex-direction:row; justify-content:space-between;width:100%;align-items: center;">
              <p style="padding-bottom: 5px; margin:0">${place?.displayName.slice(0, 30) + "..."}</p>
              <div style="display:flex; flex-direction:row; gap:5px"><span>${place?.rating} (${place?.reviews?.length})</span> <span style="color: #FFE234; font-size: 15px">★</span></div>
            </div>
            ${img}
          
            <div style="color: ${place?.opening_hours?.openNow ? "green": "red"};
                font-size: 14px;
                font-weight: 600;
                display: flex;
                flex-direction: row;
                margin-top: 12px;
                gap: 5px;
                align-items: center;">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_387_2921" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
                      <path d="M15 0H0V15H15V0Z" fill="white"/>
                      </mask>
                      <g mask="url(#mask0_387_2921)">
                      <path d="M7.5 0.375C11.4323 0.375 14.625 3.56771 14.625 7.5C14.625 11.4323 11.4323 14.625 7.5 14.625C3.56771 14.625 0.375 11.4323 0.375 7.5C0.375 3.56771 3.56771 0.375 7.5 0.375Z" stroke="black" stroke-width="0.375"/>
                      <path d="M4.30923 1.78529L4.59048 2.27243C4.61635 2.31724 4.60097 2.37463 4.55617 2.4005C4.51136 2.42637 4.45397 2.41099 4.4281 2.36618L4.14685 1.87904C4.12098 1.83423 4.13636 1.77685 4.18117 1.75098C4.22597 1.72511 4.28336 1.74048 4.30923 1.78529Z" fill="black"/>
                      <path d="M1.87971 4.14621L2.36685 4.42746C2.41166 4.45333 2.42704 4.51072 2.40117 4.55553C2.3753 4.60034 2.31791 4.61571 2.2731 4.58984L1.78596 4.30859C1.74115 4.28272 1.72578 4.22534 1.75165 4.18053C1.77752 4.13572 1.8349 4.12034 1.87971 4.14621Z" fill="black"/>
                      <path d="M0.956177 7.40625H1.51868C1.57042 7.40625 1.61243 7.44826 1.61243 7.5C1.61243 7.55174 1.57042 7.59375 1.51868 7.59375H0.956177C0.904436 7.59375 0.862427 7.55174 0.862427 7.5C0.862427 7.44826 0.904436 7.40625 0.956177 7.40625Z" fill="black"/>
                      <path d="M1.78596 10.6902L2.2731 10.4089C2.31791 10.383 2.3753 10.3984 2.40117 10.4432C2.42704 10.488 2.41166 10.5454 2.36685 10.5713L1.87971 10.8525C1.8349 10.8784 1.77752 10.863 1.75165 10.8182C1.72578 10.7734 1.74115 10.716 1.78596 10.6902Z" fill="black"/>
                      <path d="M4.14689 13.12L4.42814 12.6328C4.45401 12.588 4.51139 12.5727 4.5562 12.5985C4.60101 12.6244 4.61639 12.6818 4.59052 12.7266L4.30927 13.2137C4.28339 13.2585 4.22601 13.2739 4.1812 13.248C4.13639 13.2222 4.12102 13.1648 4.14689 13.12Z" fill="black"/>
                      <path d="M7.40619 14.0439V13.4814C7.40619 13.4297 7.4482 13.3877 7.49994 13.3877C7.55168 13.3877 7.59369 13.4297 7.59369 13.4814V14.0439C7.59369 14.0957 7.55168 14.1377 7.49994 14.1377C7.4482 14.1377 7.40619 14.0957 7.40619 14.0439Z" fill="black"/>
                      <path d="M10.6906 13.2137L10.4093 12.7266C10.3835 12.6818 10.3988 12.6244 10.4437 12.5985C10.4885 12.5727 10.5458 12.588 10.5717 12.6328L10.853 13.12C10.8788 13.1648 10.8635 13.2222 10.8187 13.248C10.7738 13.2739 10.7165 13.2585 10.6906 13.2137Z" fill="black"/>
                      <path d="M13.1202 10.8528L12.633 10.5716C12.5882 10.5457 12.5728 10.4883 12.5987 10.4435C12.6246 10.3987 12.682 10.3833 12.7268 10.4092L13.2139 10.6904C13.2587 10.7163 13.2741 10.7737 13.2482 10.8185C13.2224 10.8633 13.165 10.8787 13.1202 10.8528Z" fill="black"/>
                      <path d="M14.0437 7.59375H13.4812C13.4295 7.59375 13.3875 7.55174 13.3875 7.5C13.3875 7.44826 13.4295 7.40625 13.4812 7.40625H14.0437C14.0954 7.40625 14.1375 7.44826 14.1375 7.5C14.1375 7.55174 14.0954 7.59375 14.0437 7.59375Z" fill="black"/>
                      <path d="M13.2139 4.30886L12.7268 4.59011C12.682 4.61598 12.6246 4.60061 12.5987 4.5558C12.5728 4.51099 12.5882 4.4536 12.633 4.42773L13.1202 4.14648C13.165 4.12061 13.2224 4.13599 13.2482 4.1808C13.2741 4.22561 13.2587 4.28299 13.2139 4.30886Z" fill="black"/>
                      <path d="M10.853 1.87904L10.5717 2.36618C10.5459 2.41099 10.4885 2.42637 10.4437 2.4005C10.3989 2.37463 10.3835 2.31724 10.4094 2.27243L10.6906 1.78529C10.7165 1.74048 10.7739 1.72511 10.8187 1.75098C10.8635 1.77685 10.8789 1.83423 10.853 1.87904Z" fill="black"/>
                      <path d="M7.59369 0.956055V1.51855C7.59369 1.5703 7.55168 1.6123 7.49994 1.6123C7.4482 1.6123 7.40619 1.5703 7.40619 1.51855V0.956055C7.40619 0.904314 7.4482 0.862305 7.49994 0.862305C7.55168 0.862305 7.59369 0.904314 7.59369 0.956055Z" fill="black"/>
                      <path d="M12.8088 5.66435L7.55714 7.6794C7.46053 7.71647 7.35199 7.66813 7.31492 7.57151C7.27785 7.4749 7.32619 7.36636 7.42281 7.32929L12.6745 5.31424C12.7711 5.27717 12.8796 5.32551 12.9167 5.42213C12.9538 5.51874 12.9054 5.62728 12.8088 5.66435Z" fill="black"/>
                      <path d="M12.1727 9.18484L7.43199 7.63527C7.3533 7.60955 7.31029 7.52478 7.33601 7.44609C7.36174 7.36741 7.44651 7.3244 7.5252 7.35012L12.2659 8.89969C12.3446 8.92541 12.3876 9.01018 12.3618 9.08887C12.3361 9.16755 12.2514 9.21056 12.1727 9.18484Z" fill="black"/>
                      <path d="M7.5 7.125C7.70696 7.125 7.875 7.29304 7.875 7.5C7.875 7.70696 7.70696 7.875 7.5 7.875C7.29304 7.875 7.125 7.70696 7.125 7.5C7.125 7.29304 7.29304 7.125 7.5 7.125Z" fill="black"/>
                      </g>
                    </svg>
                ${
              place?.opening_hours?.openNow ? "Open" : "Closed"
            }</div>
            <div style="display:flex;flex-direction:row;gap:5px; margin-top:20px">
              <svg width="20" height="25" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99999 0.413086C10.4461 0.413086 13.24 3.20694 13.24 6.65309C13.24 7.71513 12.9744 8.71499 12.5064 9.59046L6.99999 20.6748L1.4934 9.59046C1.02561 8.71499 0.75999 7.71513 0.75999 6.65309C0.75999 3.20694 3.55385 0.413086 6.99999 0.413086ZM6.99999 8.04793C7.80412 8.04793 8.45599 7.39606 8.45599 6.59193C8.45599 5.78781 7.80412 5.13593 6.99999 5.13593C6.19586 5.13593 5.54399 5.78781 5.54399 6.59193C5.54399 7.39606 6.19586 8.04793 6.99999 8.04793Z" fill="#EC1C23"/>
              </svg>
              ${place.formattedAddress}
            </div>
            <div style="display:flex;flex-direction:row;gap:5px;align-items: center;margin-top:20px">
              <img style="height: 40px;
                  width: 40px;
                  display: flex;
                  align-items: start;
                  justify-content: flex-start;
                  align-self: self-start;
                  border-radius: 999px;" src="${place?.reviews?.[0].authorAttribution.photoURI || ''}">
              <div style="overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 1.2;
                    max-height: 2.4em;">${place?.reviews?.[0].text || ''}</div>
            </div>
          </div>
        `);
          infoWindow.open(map, marker);
          map.setZoom(16);
        });

        map.addListener("click", () => {
          infoWindow.close();
        });

        return marker;
      });

      return {
        newMarkers: newMarkers.filter(Boolean),
        bounds,
        position: {
          lat: results?.[0]?.location.lat(),
          lng: results?.[0]?.location.lng(),
        },
      };
    },
    [map, infoWindow, markerLib]
  );

  useEffect(() => {
    if (!map) return;

    // Clear previous markers
    markersRef.current.forEach((marker) => {
      marker.setMap(null);
    });

    // Create new markers and add them to the markersRef
    const { newMarkers, bounds, position } = createMarkers(places);

    // Update the markers reference with new markers
    markersRef.current = newMarkers;

    // Fit the map to the new bounds
    if (newMarkers.length > 0) {
      if (newMarkers?.length === 1) {
        map.setCenter(position);
        map.setZoom(13);
      } else {
        map.fitBounds(bounds);
      }
    }

    return () => {
      // Cleanup markers when component unmounts or when places change
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });
      markersRef.current = [];
    };
  }, [places, map, createMarkers]);

  return null;
};

export default DrawPlaces;
