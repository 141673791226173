import React from "react";
import Logo from "../../components/Logo";

function MobileSplash() {
  return (
    <div className="bg-gradient-to-b from-primary to-primary-light h-full w-full flex flex-1 flex-col items-center justify-center gap-11">
      <Logo classes={"w-25 h-25"} />
      <div className="flex flex-col gap-4 items-center">
        <span className="text-[48px] font-light text-secondary-l0 tracking-widest font-sans">
          LifeMatch
        </span>
        <span className="text-sm text-secondary-l0 tracking-widest font-sans">
          Make it Simple!
        </span>
      </div>
    </div>
  );
}
export default MobileSplash;
