import React, { useState } from "react";
import { Box } from "@mui/material";
import { listType } from "../common/Helpers";

const FilterChip = ({
  options,
  onSelect,
  defaultSelected,
  filterKey,
  label,
  fieldName,
  valueField = "name",
  labelField = "label",
}) => {
  const [selected, setSelected] = useState(defaultSelected || []);

  const handleSelect = (option) => {
    const updated = selected;
    if (onSelect) {
      onSelect(
        { target: { name: fieldName } },
        selected?.length > 0 ? !selected.includes(option[valueField]) : true,
        option[valueField]
      );
    }
    if (updated?.length > 0 && updated?.includes(option[valueField])) {
      setSelected(updated.filter((flor) => flor !== option[valueField]));
    } else {
      updated.push(option[valueField]);
      setSelected(updated);
    }
  };

  return (
    <Box className="p-4">
      <div className="mb-4 text-[#000] font-semibold">{label}</div>
      <Box className="flex flex-wrap gap-2">
        {options.map((option) => (
          <div
            key={option?.[valueField] || option?.id}
            onClick={() => handleSelect(option)}
            style={{ borderColor: listType[filterKey].color }}
            className={`rounded-[10px] cursor-pointer border border-solid px-4 py-2  text-xs transition ${
              selected?.length > 0 && selected.includes(option?.[valueField])
                ? `${listType[filterKey].bg} text-[#fff] shadow-md`
                : "bg-white text-[#000]/80"
            }`}
          >
            {option?.[labelField]}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default FilterChip;
