import React from "react";
import { Box, Typography, Chip, Button, Avatar } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const GuestReviews = ({ property }) => {
  const { review_nr, review_score, review_score_word } =
    property?.properties || "";
  // Example data
  const categories = [
    { name: "Staff", score: 9.0 },
    { name: "Facilities", score: 8.7 },
    { name: "Comfort", score: 9.0 },
    { name: "Cleanliness", score: 9.0 },
    { name: "Value for money", score: 8.1 },
    { name: "Location", score: 9.1 },
    { name: "Free WiFi", score: 9.1 },
  ];

  const topics = ["Room", "Location", "Pool", "Spa", "Breakfast"];

  const reviews = [
    {
      name: "Lainie",
      country: "Australia",
      text: "I loved the colours, the layout, the bed was so comfortable and plenty of pillows. The sauna was great.",
    },
    {
      name: "Ina",
      country: "United Kingdom",
      text: "Great communication, staff very friendly and helpful. Great atmosphere amazing service.",
    },
    {
      name: "Abdulahi",
      country: "United Kingdom",
      text: "Modern property with all the amenities. Cristina gave me excellent customer service.",
    },
  ];

  return (
    <Box className="p-8 bg-white  mb-2">
      {/* Header Section */}
      <Box className="flex justify-between items-center mb-8">
        <Box>
          <Typography variant="h6" className="text-xl font-bold">
            Guest reviews
          </Typography>
          <div className=" font-medium">
            <span className="p-1 rounded-4 bg-[#173A8f] text-[#fff]">
              {review_score}
            </span>{" "}
            {review_score_word} · {review_nr} reviews
          </div>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="!rounded-md"
        >
          See availability
        </Button>
      </Box>

      {/* Categories */}
      <Box className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-8">
        {categories.map((category) => (
          <Box key={category.name} className="flex items-center space-x-2">
            <div className="font-medium text-sm font-sans">{category.name}</div>
            <Box className="flex-grow bg-[#f5f5f5] rounded-full h-2 relative">
              <Box
                className="absolute top-0 left-0 h-2 bg-[#173A8f] rounded-full"
                style={{ width: `${category.score * 10}%` }}
              ></Box>
            </Box>
            <div className="text-sm font-semibold font-sans">
              {category.score}
            </div>
          </Box>
        ))}
      </Box>

      {/* Reviews */}
      <Box>
        <Typography variant="h6" className="mb-4 font-semibold">
          Guests who stayed here loved
        </Typography>
        <Box className="relative flex items-center space-x-4">
          <Box className="flex overflow-x-scroll space-x-4 ">
            {reviews.map((review, index) => (
              <Box
                key={index}
                className="bg-gray-50 shadow p-4 rounded-lg w-[300px] "
              >
                <Box className="flex items-center space-x-3 mb-2">
                  <Avatar>{review.name[0]}</Avatar>
                  <Box>
                    <Typography className="font-medium">
                      {review.name}
                    </Typography>
                    <Typography className="text-sm text-gray-500">
                      {review.country}
                    </Typography>
                  </Box>
                </Box>
                <Typography className="text-gray-700 text-sm mb-2">
                  {review.text}
                </Typography>
                <Button variant="text" size="small" className="text-blue-600">
                  Read more
                </Button>
              </Box>
            ))}
          </Box>
          <Box className="absolute  top-1/2 flex justify-between items-center w-full">
            <div
              style={{ border: "1px solid #E9E8E8" }}
              className=" bg-[#fff]  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-inner cursor-pointer"
            >
              <ArrowBackIosIcon className="ml-[7px]" />
            </div>
            <div
              style={{ border: "1px solid #E9E8E8" }}
              className=" bg-[#fff] mr-[20px] w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl cursor-pointer"
            >
              <ArrowForwardIosIcon />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GuestReviews;
