import React, { useState } from "react";
import { Add, Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addMyLocationAction } from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";
import { supabase } from "../../utils/helpers/supabaseClient";
import MapAutoSearch from "../Search/MapAutoSearch";

const AddLocationModal = ({ open, handleClose, map }) => {
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    address: "",
    locationName: "",
    image: "",
    latitude: "",
    longitude: "",
  });
  const { locationName, image } = state;

  const handleSave = () => {
    dispatch(addMyLocationAction({ ...state, userId: currentUser?.id }));
    handleClose();
  };

  const uploadImage = async (file) => {
    const fileName = `${Date.now()}_${file.name}`;
    const { data, error } = await supabase.storage
      .from("mylocations") // Replace with your actual bucket name
      .upload(fileName, file);

    if (error) {
      console.error("Error uploading images:", error);
      return null;
    }
    const urlData = supabase.storage.from("mylocations").getPublicUrl(fileName);
    return urlData?.data?.publicUrl;
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const imageUrl = await uploadImage(file);
    if (imageUrl) {
      setState({ ...state, image: imageUrl });
    }
  };

  const handleSelect = (value, name) => {
    let updated = {
      ...state,
      [name]: value.searchValue,
      latitude: value?.latitude,
      longitude: value?.longitude,
    };
    if (name === "locationName") {
      updated = {
        ...updated,
        address: value?.searchValue,
        image: value?.image,
        locationName: value?.name,
      };
    }
    setState(updated);
  };

  if (!open) return null;
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-[#EEEAEA] rounded-3xl  shadow-lg w-[80%]">
        <div className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] text-[#fff] flex justify-between py-2 px-2 rounded-t-2xl font-bold text-md">
          <div>Add Location</div>
          <Close onClick={handleClose} className="cursor-pointer" />
        </div>
        <div className=" w-auto px-6">
          {/* Image Container */}
          <div className="flex justify-center my-4">
            <div
              onClick={() => document.getElementById("imageUpload").click()}
              className="relative w-20 h-20 rounded-full overflow-hidden bg-gray-200 cursor-pointer"
            >
              <img
                src={image || "https://via.placeholder.com/80"}
                alt="location"
                className="w-full h-full object-cover cursor-pointer"
              />
              <button className="absolute cursor-pointer inset-0 flex items-center justify-center bg-transparent bg-opacity-50 text-[#fff]">
                <Add />
              </button>
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageUpload}
              />
            </div>
          </div>

          {/* Input Fields */}
          <div className="flex justify-center mb-4">
            <MapAutoSearch
              value={locationName}
              label="Location Name"
              isDisableSearchIcon={true}
              map={map}
              onSelectValue={(value) => handleSelect(value, "locationName")}
              onHandleChange={(value) =>
                setState({ ...state, locationName: value })
              }
            />
          </div>
          <MapAutoSearch
            value={state.address}
            label="Location Address"
            map={map}
            onSelectValue={(value) => handleSelect(value, "address")}
            onHandleChange={(value) => setState({ ...state, address: value })}
          />

          {/* Save Button */}
          <div className="flex justify-center">
            <button
              onClick={handleSave}
              className="py-2 px-3 my-4 bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] text-[#fff]  rounded-[10px] border-none  cursor-pointer"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationModal;
