import React, { useEffect } from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";

const ListingAirbnbItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  toggleAvailabilityView,
}) => {
  const { neighborhood, fetchNeighborhood } = useGooglePlaces();
  const {
    thumbnail,
    propertyName,
    propertyClass,
    priceInfoContent,
    listingKey,
    review_score_word,
    price,
    priceBreakdown,
    listingParamOverrides,
  } = property.properties;
  const geometry = property?.geometry;
  const { checkin, checkout } = listingParamOverrides;

  useEffect(() => {
    fetchNeighborhood(geometry?.coordinates?.[0], geometry?.coordinates?.[1]);
  }, []);
  const daysBetween = dayjs(checkout).diff(dayjs(checkin), "day");
  const actualPrice = price.replace(/\D/g, "");
  const pricePerDay = Number(actualPrice) / daysBetween;

  return (
    <React.Fragment>
      <div className="relative">
        <img
          src={thumbnail}
          alt={propertyName}
          className="rounded-lg h-[300px] w-[300px] max-w-[300px] max-h-[300px]"
        />
        <div className="flex justify-center items-center w-full absolute top-2 z-50">
          <div className="flex px-3 py-1 justify-center  items-center gap-1 rounded-[30px] bg-[#fff]/90 shadow-2xl">
            <div>
              <LocationOnIcon
                sx={{
                  color: "#8B0C0C",
                  fontSize: 20,
                }}
              />
            </div>
            <div className="text-[18px] text-[#0067E8] font-medium">
              {neighborhood}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center w-full absolute bottom-2 z-50">
          <div className="flex px-3 py-1 justify-center  items-center gap-1 rounded-[10px] bg-[#fff]/80 shadow-2xl">
            <div>{typeMapData?.[listingKey]?.icon}</div>
            <div className="text-[18px] text-[#0067E8] font-medium">
              {typeMapData?.[listingKey]?.text}
            </div>
          </div>
        </div>
      </div>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="text-xl font-medium text-[#2D6ADC]">
                {propertyName}
              </div>
              {propertyClass && (
                <div className="flex items-center">
                  {[...Array(propertyClass)].map((_, i) => (
                    <StarIcon
                      key={i}
                      fontSize="small"
                      style={{ color: "#FFD700" }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center gap-1">
              <div className="flex items-center">
                <StarIcon fontSize="small" style={{ color: "#FFD700" }} />
              </div>
              <div className="text-[20px] font-normal text-[#050505]">
                {review_score_word}
              </div>
            </div>
          </div>

          <div className="flex gap-20 items-center">
            <div>
              <div className="flex items-center space-x-2 mt-2">
                <div className="text-[18px] font-medium text-[#050505]">
                  {price}
                  <span className="text-[16px] font-light text-[#050505]">
                    Total
                  </span>
                </div>
              </div>
              <div className="text-[14px] text-[#050505] font-light">
                {priceInfoContent}
              </div>
            </div>
            <div className="flex items-center gap-1">
              <div>
                <LocationOnIcon
                  sx={{
                    color: "#8B0C0C",
                    fontSize: 20,
                  }}
                />
              </div>
              <div className="text-[16px] text-[#050505] font-light">
                {getDistanceFromLatLonInKm(
                  cityCenter?.lat,
                  cityCenter?.lng,
                  geometry?.coordinates?.[0],
                  geometry?.coordinates?.[1]
                ).toFixed(1)}{" "}
                km from city center
              </div>
            </div>
          </div>
        </div>

        {/* Icons and Description */}
        <div className="flex items-center space-x-4 text-[16px] text-[#050505] font-light">
          <div className="flex items-center">
            <div className="w-10 h-10 ">
              <IconCircular
                alt={"locaton"}
                key={"location"}
                src={
                  majorLocation?.image || "https://picsum.photos/200?random=5"
                }
              />
            </div>
            <DirectionIcon className="text-primary" />
            <span>
              {getDistanceFromLatLonInKm(
                majorLocation?.latitude,
                majorLocation?.longitude,
                geometry?.coordinates?.[0],
                geometry?.coordinates?.[1]
              ).toFixed(1)}{" "}
              km to {majorLocation?.locationName}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsWalkIcon className="text-primary" />
            <span>4 min</span>
          </div>
          <div className="flex items-center">
            <DirectionsCarIcon className="text-primary" />
            <span>30 min</span>
          </div>
        </div>

        <div className=" flex justify-between">
          <Button
            variant="contained"
            style={{
              background: typeMapData?.[listingKey]?.color,
              borderRadius: 10,
            }}
          >
            {Math.round(pricePerDay)} $/night
          </Button>
          <Button
            onClick={() => toggleAvailabilityView(property)}
            variant="contained"
            style={{
              background: "#2D6ADC",
              borderRadius: 10,
            }}
          >
            See availability &gt;
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ListingAirbnbItem;
