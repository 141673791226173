import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  col: 0,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setLayout: (state, action) => {
      state.col = action.payload;
    }
  },
});
export const { setLayout } = layoutSlice.actions;
export default layoutSlice.reducer;
