import { useState, useEffect } from "react";
import { getDistanceFromLatLonInKm } from "../common/Helpers";

const useGooglePlaces = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [cityCenter, setCityCenter] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);

  const fetchPlaces = (query, location, radius = 10, type = "") => {
    if (!query || !location) return;
    setLoading(true);
    setError(null);

    const dummyMap = new window.google.maps.Map(document.createElement("div"));
    const service = new window.google.maps.places.PlacesService(dummyMap);

    const request = {
      location: new window.google.maps.LatLng(location.lat, location.lng),
      radius: radius * 1000,
      keyword: query,
      type,
    };

    service.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaces(results);
      } else {
        setError(`Error fetching places: ${status}`);
      }
      setLoading(false);
    });
  };

  const fetchPlaceDetails = (placeId, activeList) => {
    setLoading(true);
    setError(null);

    const dummyMap = new window.google.maps.Map(document.createElement("div"));
    const service = new window.google.maps.places.PlacesService(dummyMap);

    service.getDetails({ placeId }, (details, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { latitude, longitude, propertyName } = activeList || "";
        const placeLat = details.geometry.location.lat();
        const placeLng = details.geometry.location.lng();
        const distance = getDistanceFromLatLonInKm(
          latitude,
          longitude,
          placeLat,
          placeLng
        ).toFixed(1); // in kilometers

        const name = details.name || "This place";
        const address = details.formatted_address || "";
        const rating = details.rating
          ? `with a rating of ${details.rating}`
          : "";
        const reviewCount = details.user_ratings_total
          ? `based on ${details.user_ratings_total} reviews`
          : "";
        const type = details.types?.[0]
          ? details.types[0].replace("_", " ")
          : "location";

        const description = `${name} is a ${type} located at ${address} ${rating} ${reviewCount}, approximately ${distance} km away from ${propertyName}.`;

        setPlaceDetails({ ...details, description });
      } else {
        setError(`Error fetching place details: ${status}`);
      }
      setLoading(false);
    });
  };

  const fetchCityCenter = async (cityName) => {
    if (!cityName) {
      setError("City name is missing.");
      return;
    }

    setLoading(true);
    setError(null);

    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      cityName
    )}&key=AIzaSyDBfPZBgU_xAaRw_-0VU1hv1TyoYR_nV6E`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setCityCenter(location);
      } else {
        setError(`City center not found for ${cityName}.`);
      }
    } catch (err) {
      setError(`Error fetching city center: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };
  const fetchNeighborhood = async (lat, lng) => {
    if (!lat || !lng) {
      setError("Latitude, Longitude, or API key is missing.");
      return;
    }

    setLoading(true);
    setError(null);

    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDBfPZBgU_xAaRw_-0VU1hv1TyoYR_nV6E`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        // Find the neighborhood from the address components
        const neighborhoodInfo = data.results.find((result) =>
          result.types.includes("neighborhood")
        );

        if (neighborhoodInfo) {
          setNeighborhood(neighborhoodInfo.formatted_address);
        } else {
          // Fallback to locality or area level information
          const localityInfo = data.results.find((result) =>
            result.types.includes("locality")
          );
          setNeighborhood(
            localityInfo?.formatted_address || "Unknown neighborhood"
          );
        }
      } else {
        setError(`Error fetching neighborhood: ${data.status}`);
      }
    } catch (err) {
      setError(`Error fetching neighborhood: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    places,
    loading,
    error,
    fetchPlaces,
    fetchPlaceDetails,
    placeDetails,
    cityCenter,
    fetchCityCenter,
    neighborhood,
    fetchNeighborhood,
  };
};

export default useGooglePlaces;
