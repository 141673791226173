import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { GoogleMapsContext, useMapsLibrary } from '@vis.gl/react-google-maps';

function usePolyline(props) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPath,
    onBoundsChange, // New prop for emitting bounds
    ...polylineOptions
  } = props;

  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onBoundsChange, // Add onBoundsChange to callbacks
  });

  const geometryLibrary = useMapsLibrary('geometry');

  const polyline = useRef(new window.google.maps.Polyline()).current;

  useMemo(() => {
    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  useMemo(() => {
    if (!encodedPath || !geometryLibrary) return;
    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [polyline, encodedPath, geometryLibrary]);

  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error('<Polyline> has to be inside a Map component.');

      return;
    }

    polyline.setMap(map);

    // Calculate bounds and call the onBoundsChange callback
    if (callbacks.current.onBoundsChange) {
      const bounds = new window.google.maps.LatLngBounds();
      const path = polyline.getPath();

      path.forEach((latLng) => {
        bounds.extend(latLng);
      });

      callbacks.current.onBoundsChange(bounds);
    }

    return () => {
      polyline.setMap(null);
    };
  }, [map, polyline]);

  useEffect(() => {
    if (!polyline) return;

    const gme = window.google.maps.event;
    [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut'],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polyline, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polyline);
    };
  }, [polyline]);

  // Return the polyline instance
  return polyline;
}

/**
 * Component to render a polyline on a map
 */
export const Polyline = forwardRef((props, ref) => {
  const polyline = usePolyline(props);

  useImperativeHandle(ref, () => polyline, []);

  return null;
});
