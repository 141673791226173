import { useEffect, useState } from "react";
// Import SVGs for arrows
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";

const IdealistaImageSlider = (props) => {
  const { isFullscreen, toggleFullscreen } = props;
  const [selectedImage, setSelectedImage] = useState(""); // Default main image
  const [currentIndex, setCurrentIndex] = useState(0); // Keeps track of the current image index
  const [listingMedia, setListingMedia] = useState(props.listingMedia);
  const listingMediaVideo = useSelector(
    (state) => state.hotels.listingMediaVideo
  );

  useEffect(() => {
    mapMedia();
  }, [listingMediaVideo, props.listingMedia]);

  function mapMedia() {
    let media = listingMedia;
    if (listingMediaVideo?.length > 0) {
      media =
        media?.length > 0
          ? [...listingMediaVideo, ...media]
          : listingMediaVideo;
    }
    setListingMedia(media);
  }

  // Function to go to the next image
  const goToNext = () => {
    const updatedIndex = (currentIndex + 1) % listingMedia.length;
    setCurrentIndex(updatedIndex);
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  };

  // Function to go to the previous image
  const goToPrevious = () => {
    const updatedIndex =
      (currentIndex - 1 + listingMedia.length) % listingMedia.length;
    setCurrentIndex(updatedIndex);
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  };

  function isVideo(url) {
    const videoExtensions = /\.(mp4|webm|ogg|avi|mov|mkv|flv|wmv|m4v)$/i;
    if (videoExtensions.test(url)) {
      return true;
    }
    return false;
  }
  const activeImg = selectedImage ? selectedImage : listingMedia?.[0]?.url;
  return (
    <div className="w-full flex justify-center">
      {/* Main Image */}
      <div className="w-[90%]">
        <div className="flex gap-2 w-full">
          <div
            className={
              isFullscreen ? "w-full   h-[515px]" : "w-full  h-[288px]"
            }
          >
            {isVideo(activeImg) ? (
              <a
                target="_blank"
                href={listingMedia?.[0]?.url}
                className="relative inline-block w-full h-full"
              >
                <video
                  src={listingMedia?.[0]?.url}
                  poster={listingMedia?.[0]?.thumbnail}
                  className="w-full h-full"
                  autoPlay
                  controls
                  loop
                />
              </a>
            ) : (
              <img
                src={
                  selectedImage ||
                  listingMedia?.[0]?.url_1440 ||
                  listingMedia?.[0]?.url ||
                  listingMedia?.[0]?.baseUrl
                }
                alt="Main Thumbnail"
                className="w-full h-full"
              />
            )}
          </div>
        </div>

        {/* Thumbnail Carousel with Arrows */}
        <div className="w-full relative mt-[9px] ">
          {/* Left Arrow */}
          {isFullscreen && (
            <div
              onClick={goToPrevious}
              className="absolute bg-[#fff] left-1 top-1/2 transform -translate-y-1/2  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl"
            >
              <ArrowBackIosIcon className="ml-[7px]" />
            </div>
          )}
          <div className="w-full h-[90.21px] flex gap-[6px] overflow-x-auto hide-scrollbar">
            {listingMedia?.length > 0 &&
              listingMedia.map((image, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedImage(
                      image?.url_1440 || image?.url || image?.baseUrl
                    );
                    setCurrentIndex(index);
                  }}
                  className="cursor-pointer  rounded-[4px]"
                >
                  {isVideo(image?.url) ? (
                    <img
                      src={image?.thumbnail}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-[124px] h-[90px]  object-cover rounded-lg"
                    />
                  ) : (
                    <img
                      src={image?.url_1440 || image?.url || image?.baseUrl}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-[124px] h-[90px]  object-cover rounded-lg"
                    />
                  )}
                </div>
              ))}
          </div>
          {isFullscreen ? (
            <div
              onClick={goToNext}
              className="absolute bg-[#fff] right-1 top-1/2 transform -translate-y-1/2  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl"
            >
              <ArrowForwardIosIcon />
            </div>
          ) : (
            <div
              onClick={toggleFullscreen}
              className="absolute cursor-pointer w-[124px] h-[90px] bg-[#000]/40 text-[#fff] right-0 top-1/2 transform -translate-y-1/2 rounded-lg flex justify-center items-center"
            >
              +{listingMedia?.length - 7} photos
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdealistaImageSlider;
