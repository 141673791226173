import React, { useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import useWikipediaInfo from "../../hooks/useWikipediaInfo";

const NeighborhoodTab = ({ placeName, isMapScreen = false }) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const places = useMapsLibrary("places");
  const wikiInfo = useWikipediaInfo(placeName);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (places) {
      const request = {
        query: placeName,
        fields: ["place_id"],
      };

      const service = new places.PlacesService(document.createElement("div"));
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === places.PlacesServiceStatus.OK && results) {
          service.getDetails(
            {
              placeId: results?.[0]?.place_id,
              fields: ["name", "photos", "geometry", "url", "adr_address"],
            },
            (place) => {
              if (place) {
                setPlaceDetails(place);
              }
            }
          );
        }
      });
    }
  }, [places, placeName]);

  return (
    <div
      style={{
        maxHeight: isMapScreen ? "calc(100vh - 250px)" : "100%",
      }}
      className={`bg-secondary-l0   ${
        isMapScreen ? "rounded-b-lg max-w-[350px]" : "rounded-t-lg w-full"
      } z-10 overflow-y-auto hide-scrollbar p-2`}
    >
      {placeDetails ? (
        <div className="flex">
          <div className="w-full">
            {/* Top Section */}
            <div className="flex justify-between items-center text-[#fff] bg-[#040881] h-[94px] px-2">
              <div className="text-[26px] font-sans">{placeDetails.name}</div>
              <div>
                <span className="text-sm text-gray-700">In {placeName}</span>
              </div>
            </div>
            {/* Place Description */}
            <div className="flex justify-center items-center">
              {wikiInfo && (
                <div>
                  <p className="text-gray-700 text-xs text-center">
                    {wikiInfo.extract}
                    <a
                      className="text-xs flex justify-center"
                      href={wikiInfo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more on Wikipedia
                    </a>
                  </p>
                </div>
              )}
            </div>
            {/* Info Section (Weather, Map, Suggestions) */}
            <div className="grid grid-cols-1 gap-4">
              <div
                className="text-xs text-center"
                dangerouslySetInnerHTML={{ __html: placeDetails?.adr_address }}
              />

              {/* Map */}
              <div className="bg-white px-4 rounded-lg shadow-sm">
                <div className="w-full h-[400px]">
                  {placeDetails.geometry && (
                    <Map
                      lat={placeDetails.geometry.location.lat()}
                      lng={placeDetails.geometry.location.lng()}
                      placeName={placeDetails.name}
                      url={placeDetails.url}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Photos Carousel */}
          <div className="w-1/2">
            <div className={"w-full h-full  max-h-[445px]"}>
              <img
                src={selectedImage || placeDetails?.photos?.[0]?.getUrl()}
                alt="Main Thumbnail"
                className="w-full h-full"
              />
            </div>
            <div className="flex overflow-x-auto hide-scrollbar w-full gap-1 mt-1">
              {placeDetails.photos &&
                placeDetails.photos.map((photo, index) => (
                  <div
                    key={index}
                    className=" w-24 h-24 cursor-pointer"
                    onClick={() => {
                      setSelectedImage(photo.getUrl());
                    }}
                  >
                    <img
                      key={index}
                      src={photo.getUrl()}
                      alt={`${placeDetails.name}`}
                      className="w-24 h-24 rounded-md"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const Map = ({ lat, lng, placeName, url }) => {
  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat, lng },
      zoom: 14,
    });

    new window.google.maps.Marker({
      position: { lat, lng },
      map,
      title: placeName,
    });

    // Add click event listener to the map
    map.addListener("click", () => {
      window.open(url, "_blank");
    });
  }, [lat, lng, placeName]);

  return <div id="map" className="w-full h-full" />;
};

export default NeighborhoodTab;
