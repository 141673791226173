import React, { useState } from "react";
import { Checkbox, Chip, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import FilterChip from "../../FilterChip";

const CustomCheckboxStyle = styled(Checkbox)(({ theme }) => ({
  color: "#fff", // Default color for the border and checkmark
  "&.Mui-checked": {
    color: "#fff", // Color when checked
  },
}));
const CustomCheckbox = ({ label, onChange, name, value }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckboxStyle name={name} onChange={onChange} checked={value} />
      }
      label={<span className="text-[11px]">{label}</span>}
    />
  );
};

const Title = ({ children, titleStyle }) => {
  return <div className={`text-xs ${titleStyle}`}>{children}</div>;
};

const propertyType = [
  { label: "Lodges", name: "property_type::221" },
  { label: "Hostel", name: "property_type::203" },
  { label: "Holiday homes", name: "property_type::220" },
];
const previousFilter = [
  { label: "4 stars", name: "class::4" },
  { label: "Hotels", name: "property_type::204" },
  { label: "Air conditioning", name: "room_facility::11" },
  { label: "Balcony", name: "room_facility::17" },
  { name: "facility::4", label: "Pets allowed" },
  { name: "facility::2", label: "Parking" },
  { name: "facility::433", label: "Swimming pool" },
];
const roomFacility = [
  { label: "Balcony", name: "room_facility::17" },
  { label: "Sea view", name: "room_facility::81" },
  { label: "Air conditioning", name: "room_facility::11" },
  { label: "TV", name: "room_facility::75" },
  { label: "Coffee/tea maker", name: "room_facility::120" },
];
const stars = [
  { label: "1 star", name: "class::1" },
  { label: "2 star", name: "class::2" },
  { label: "3 star", name: "class::3" },
  { label: "4 star", name: "class::4" },
  { label: "5 star", name: "class::5" },
];
const rating = [
  { label: "Passable: 5+", name: "reviewscorebuckets::50" },
  { label: "Pleasant: 6+", name: "reviewscorebuckets::60" },
  { label: "Good: 7+", name: "reviewscorebuckets::70" },
  { label: "Very good: 8+", name: "reviewscorebuckets::80" },
  { label: "Superb: 9+", name: "reviewscorebuckets::90" },
];
const popularFilters = [
  { label: "Distance from city center 3 km", name: "distance::3000" },
  { label: "Private bathroom", name: "room_facility::38" },
  { label: "Coffee/tea maker", name: "room_facility::120" },
  { label: "Breakfast included", name: "mealplan::breakfast_included" },
  { name: "facility::107", label: "Free WiFi" },
  { name: "facility::139", label: "Airport shuttle" },
  { name: "facility::4", label: "Pets allowed" },
  { name: "facility::2", label: "Parking" },
  { name: "facility::433", label: "Swimming pool" },
  { name: "facility::11", label: "Fitness centre" },
  { label: "Free cancellation", name: "free_cancellation::1" },
];

const AdvanceFilter = ({ onChange, filterKey }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(
    filters?.[filterKey]?.categories_filter_ids || ""
  );

  function handleChange(e, checked, name) {
    let currentCategories = updatedFilters;
    let categoriesArray = currentCategories ? currentCategories.split(",") : [];
    if (checked) {
      // If checked is true, add the name to the array (if it's not already included)
      if (!categoriesArray.includes(name)) {
        categoriesArray.push(name);
      }
    } else {
      // If checked is false, remove the name from the array
      categoriesArray = categoriesArray.filter((category) => category !== name);
    }

    // Convert the array back into a comma-separated string
    const updatedCategories = categoriesArray.join(",");
    setFilters(updatedCategories);

    // Pass the updated categories_filter_ids back through the onChange handler
    if (onChange) {
      onChange({ categories_filter_ids: updatedCategories });
    }
  }
  const checksValue = updatedFilters.split(",").map((s) => s.trim());
  return (
    <div className="w-full">
      <FilterChip
        options={propertyType}
        onSelect={handleChange}
        defaultSelected={checksValue}
        filterKey={filterKey}
        label={"Property Type"}
        fieldName={"propertyType"}
      />
      <FilterChip
        options={previousFilter}
        onSelect={handleChange}
        defaultSelected={checksValue}
        filterKey={filterKey}
        label={"My Previous Filters"}
        fieldName={"previous"}
      />
      <FilterChip
        options={roomFacility}
        onSelect={handleChange}
        defaultSelected={checksValue}
        filterKey={filterKey}
        label={"Room Facilities"}
        fieldName={"rooms"}
      />
      <div className="p-4">
        <div className=" flex items-center gap-3">
          <div className=" text-[#000] font-semibold">Star</div>
          {stars.map((check, index) => (
            <StarIcon
              onClick={() =>
                handleChange(
                  null,
                  !checksValue.includes(check.name),
                  check.name
                )
              }
              key={index}
              style={{
                color: checksValue.includes(check.name) ? "#FFD700" : "#ddd",
                cursor: "pointer",
              }}
              sx={{ fontSize: "25px" }}
            />
          ))}
        </div>
      </div>
      <FilterChip
        options={rating}
        onSelect={handleChange}
        defaultSelected={checksValue}
        filterKey={filterKey}
        label={"Rating"}
        fieldName={"rating"}
      />
      <FilterChip
        options={popularFilters}
        onSelect={handleChange}
        defaultSelected={checksValue}
        filterKey={filterKey}
        label={"Popular Filters"}
        fieldName={"popular"}
      />
    </div>
  );
};

export default AdvanceFilter;
