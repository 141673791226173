import React, { useRef, useEffect, useState } from "react";
import DirectionsIcon from "@mui/icons-material/Directions";
import PhoneIcon from "@mui/icons-material/Phone";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import myLocation from "../Swipe/location.json";
import notesIcon from "../../assets/icons/svg/notes.svg";
import {
  getDistanceFromLatLonInKm,
  gotoDirections,
} from "../../common/Helpers";

const ListItem = ({
  detail,
  openListingDetail,
  longPressCallback,
  showNotesCallback,
}) => {
  const {
    name,
    price,
    image,
    description,
    phoneNumber,
    listingId,
    latlng,
    isPinned,
    notes,
  } = detail;
  const itemRef = useRef(null);
  const timerRef = useRef(null); // To track long press timer
  const [isLongPress, setIsLongPress] = useState(false);

  const handlePressStart = () => {
    setIsLongPress(false);
    timerRef.current = setTimeout(() => {
      setIsLongPress(true);
      longPressCallback(true, listingId, isPinned);
    }, 500); // Set long press duration (500ms)
  };

  // Clear the long press timer and handle click or long press
  const handlePressEnd = () => {
    clearTimeout(timerRef.current);

    // If long press didn't trigger, treat it as a click
    if (!isLongPress) {
      console.log("clicked");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event?.target?.parentElement?.classList.contains("actions")) return;
      if (
        event?.target?.parentElement?.parentElement?.classList.contains(
          "actions"
        )
      )
        return;
      if (itemRef.current && !itemRef.current.contains(event.target)) {
        // Clear long press and reset state if clicked outside
        clearTimeout(timerRef.current);
        setIsLongPress(false);
        longPressCallback(false, listingId, isPinned);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // For mobile touch

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [itemRef, longPressCallback, listingId, isPinned]);

  const showNotes = () => {
    showNotesCallback(notes);
  };

  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };
  const majorLocation = myLocation?.[0];
  const dist = getDistanceFromLatLonInKm(
    majorLocation.latitude,
    majorLocation.longitude,
    latlng?.[0],
    latlng?.[1]
  );

  return (
    <div
      ref={itemRef}
      className={`flex space-x-4 py-4 border rounded-md shadow-sm w-full ${
        isLongPress ? "bg-secondary-l2" : ""
      }`}
      onMouseDown={handlePressStart}
      onMouseUp={handlePressEnd}
      onMouseLeave={handlePressEnd}
      onTouchStart={handlePressStart}
      onTouchEnd={handlePressEnd}
    >
      {/* Image Section */}
      <div className="relative">
        <img
          src={image}
          alt="Property"
          className="w-20 h-20 rounded-lg object-cover"
        />
        {notes && (
          <img
            onClick={showNotes}
            className="w-6 h-6 absolute right-0.5 top-0.5 cursor-pointer"
            src={notesIcon}
            alt="notes"
          />
        )}
        <div
          onClick={handleDial}
          className="absolute bottom-2 right-6 bg-primary-light text-secondary-l0 text-white rounded-full p-1 shadow-md w-4 h-4"
        >
          <PhoneIcon sx={{ fontSize: 18 }} />
        </div>
      </div>

      {/* Text Section */}
      <div className="flex flex-col flex-1 justify-between">
        {/* Title */}
        <h2
          onClick={() => openListingDetail(detail)}
          className="text-base font-normal cursor-pointer hover:text-primary"
        >
          {name}
        </h2>

        {/* Price and Distance */}
        <div className="flex flex-row justify-between space-x-4">
          <span className="text-[#0D1983] text-[15px] font-semibold">
            {Math.round(price)} €
          </span>
          <div className="flex items-center space-x-1">
            <svg
              width="21"
              height="18"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3086 3H14.3086V2C14.3086 1.46957 14.0979 0.960859 13.7228 0.585786C13.3477 0.210714 12.839 0 12.3086 0H8.30859C7.77816 0 7.26945 0.210714 6.89438 0.585786C6.51931 0.960859 6.30859 1.46957 6.30859 2V3H3.30859C2.51294 3 1.74988 3.31607 1.18727 3.87868C0.624664 4.44129 0.308594 5.20435 0.308594 6V15C0.308594 15.7956 0.624664 16.5587 1.18727 17.1213C1.74988 17.6839 2.51294 18 3.30859 18H17.3086C18.1042 18 18.8673 17.6839 19.4299 17.1213C19.9925 16.5587 20.3086 15.7956 20.3086 15V6C20.3086 5.20435 19.9925 4.44129 19.4299 3.87868C18.8673 3.31607 18.1042 3 17.3086 3ZM8.30859 2H12.3086V3H8.30859V2ZM18.3086 15C18.3086 15.2652 18.2032 15.5196 18.0157 15.7071C17.8282 15.8946 17.5738 16 17.3086 16H3.30859C3.04338 16 2.78902 15.8946 2.60149 15.7071C2.41395 15.5196 2.30859 15.2652 2.30859 15V9.39L6.98859 11C7.09478 11.0143 7.20241 11.0143 7.30859 11H13.3086C13.417 10.9979 13.5247 10.9811 13.6286 10.95L18.3086 9.39V15ZM18.3086 7.28L13.1486 9H7.46859L2.30859 7.28V6C2.30859 5.73478 2.41395 5.48043 2.60149 5.29289C2.78902 5.10536 3.04338 5 3.30859 5H17.3086C17.5738 5 17.8282 5.10536 18.0157 5.29289C18.2032 5.48043 18.3086 5.73478 18.3086 6V7.28Z"
                fill="#2200AA"
              />
            </svg>
            <DirectionsIcon
              onClick={() =>
                gotoDirections(
                  [majorLocation.latitude, majorLocation.longitude],
                  latlng
                )
              }
              className="text-primary cursor-pointer"
            />
            <span className="text-secondary-light">
              {dist ? dist.toFixed(2) : ""} km
            </span>
          </div>
        </div>

        {/* Area, Type, and Floor */}
        <div className="flex flex-row justify-between gap-1 items-center">
          <div className="flex flex-row gap-1 min-w-24 w-auto items-center">
            <img src={mapIcon} alt="map icon" />
            <span className="text-secondary-light text-sm">73 m²</span>
          </div>

          <span className="flex text-secondary-light text-sm">T2</span>
          <span className="flex text-secondary-light text-sm w-full">
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
