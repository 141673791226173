import React, { useState } from "react";
import { Button } from "@mui/material";
import StaySelection from "./StaySelection";
import MapScreen from "./MapScreen";
import ListingScreen from "./ListingScreen";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    selected: null,
    activeScreen: 1,
  });

  const { selected, activeScreen } = state;

  function onSelectStayType(selectedType) {
    setState({ ...state, selected: selectedType });
  }

  function nextScreen() {
    if (activeScreen === 2) {
      navigate("/listing");
      return;
    }
    setState({ ...state, activeScreen: activeScreen + 1 });
  }

  function previousScreen() {
    setState({ ...state, activeScreen: activeScreen - 1 });
  }

  const hasTrueValue = () => {
    if (!selected) return false;
    return Object.values(selected).includes(true);
  };

  return (
    <div className="h-full bg-[#000] w-full">
      <div className="h-full w-full">
        {activeScreen === 1 && (
          <StaySelection
            onSelectStayType={onSelectStayType}
            defaultSelected={selected}
          />
        )}
        {activeScreen === 2 && <MapScreen />}
      </div>
      <div
        className={`flex ${
          activeScreen > 1 ? "justify-between" : "justify-end"
        } items-center w-[80%] absolute bottom-[30px]`}
      >
        {activeScreen > 1 && (
          <Button
            variant="contained"
            sx={{
              minWidth: "150px",
              mt: 2,
              border: "3px solid #fff",
              borderRadius: "10px",
              backgroundColor: "#2D6ADC",
              "&:hover": {
                backgroundColor: "#1A4BA0",
              },
              "&.Mui-disabled": {
                backgroundColor: "#B0B0B0",
                border: "3px solid #D3D3D3",
                color: "#FFFFFF99",
              },
            }}
            onClick={previousScreen}
            disabled={!hasTrueValue()}
          >
            Previous
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            minWidth: "150px",
            mt: 2,
            border: "3px solid #fff",
            borderRadius: "10px",
            backgroundColor: "#2D6ADC",
            "&:hover": {
              backgroundColor: "#1A4BA0",
            },
            "&.Mui-disabled": {
              backgroundColor: "#B0B0B0",
              border: "3px solid #D3D3D3",
              color: "#FFFFFF99",
            },
          }}
          onClick={nextScreen}
          disabled={!hasTrueValue()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Home;
