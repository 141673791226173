import React, { useState } from "react";
import SortIcon from "@mui/icons-material/SwapVert";
import { Popover, List, ListItemButton, ListItemText } from "@mui/material";
import { typeMapping } from "../../common/Helpers";

const SortButton = ({
  filterKey,
  isOpen,
  applied,
  sortOptions,
  defaultOption,
  fontSize,
  handleSorting,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (handleSorting) {
      handleSorting(option);
    }
    setSelectedOption(option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;
  const filterStyle = typeMapping(filterKey);
  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        className={` h-full flex items-center justify-end cursor-pointer`}
        style={{
          borderRight: "1px solid #ccc",
          backgroundColor: isOpen
            ? filterStyle?.tabBg
            : applied
            ? filterStyle?.tabBg
            : "#ffffff",
        }}
      >
        <SortIcon
          style={{
            color: isOpen ? "#fff" : applied ? "#ffffff" : "black",
            fontSize: fontSize || "16px",
          }}
        />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: "#fff", // Semi-transparent background
              color: "white",
              padding: "10px 0",
              borderRadius: "8px",
            },
          },
        }}
      >
        <List>
          {sortOptions.map((option, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleOptionClick(option.id)}
              sx={{
                ":hover": {
                  backgroundColor: "rgba(74, 144, 226, 0.8)",
                },
                justifyContent: "center", // Center the text in the button
                backgroundColor:
                  option.id === selectedOption ? "#4A90E2" : "#fff",
                color: option.id === selectedOption ? "#fff" : "#000",
              }}
            >
              <ListItemText
                primary={option.name}
                sx={{ textAlign: "center" }}
                primaryTypographyProps={{
                  fontSize: 10,
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </React.Fragment>
  );
};

export default SortButton;
