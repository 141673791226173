import axios from "axios";
import { supabase } from "@/utils/helpers/supabaseClient";

const apiUrl = process.env.REACT_APP_API_URL;
// Create an Axios instance
const httpClient = axios.create({
  baseURL: apiUrl, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
httpClient.interceptors.request.use(
  async (config) => {
    const { data, error } = await supabase.auth.getSession();

    if (data?.session?.access_token) {
      config.headers["Authorization"] = `Bearer ${data?.session?.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle token refresh or redirect to login page
      // For example, you could dispatch a logout action or refresh token here
      console.log("Unauthorized, logging out...");
      // logoutUser();
    }
    return Promise.reject(error);
  }
);

export default httpClient;
