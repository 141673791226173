import {
  airport,
  coffee,
  atm,
  bakery,
  bank,
  bar,
  casino,
  church,
  gym,
  hairCare,
  hospital,
  parking,
  school,
  restaurant,
  supermarket,
  trainStation,
  university,
  zoo,
} from "../../assets/customIcon";

export const placeTypes = [
  {
    type: "coffee",
    label: "Coffee",
    description: "Coffee",
    icon: coffee,
    iconType: "custom",
  },
  {
    type: "accounting",
    label: "Accounting",
    description: "For all accounting-related services",
    icon: "fas fa-calculator",
  },
  {
    type: "airport",
    label: "Airport",
    description: "For airports and related services",
    icon: airport,
    iconType: "custom",
  },
  {
    type: "amusement_park",
    label: "Amusement Park",
    description: "For amusement parks",
    icon: "fas fa-ticket-alt",
  },
  {
    type: "aquarium",
    label: "Aquarium",
    description: "For aquariums and marine life exhibitions",
    icon: "fas fa-fish",
  },
  {
    type: "art_gallery",
    label: "Art Gallery",
    description: "For art galleries",
    icon: "fas fa-palette",
  },
  {
    type: "atm",
    label: "ATM",
    description: "For ATM machines",
    icon: atm,
    iconType: "custom",
  },
  {
    type: "bakery",
    label: "Bakery",
    description: "For bakeries and related food services",
    icon: bakery,
    iconType: "custom",
  },
  {
    type: "bank",
    label: "Bank",
    description: "For banks and financial institutions",
    icon: bank,
    iconType: "custom",
  },
  {
    type: "bar",
    label: "Bar",
    description: "For bars and nightclubs",
    icon: bar,
    iconType: "custom",
  },
  {
    type: "beauty_salon",
    label: "Beauty Salon",
    description: "For beauty salons and spas",
    icon: "fas fa-cut",
  },
  {
    type: "bicycle_store",
    label: "Bicycle Store",
    description: "For bicycle stores",
    icon: "fas fa-bicycle",
  },
  {
    type: "book_store",
    label: "Book Store",
    description: "For bookstores and literature",
    icon: "fas fa-book",
  },
  {
    type: "bowling_alley",
    label: "Bowling Alley",
    description: "For bowling alleys",
    icon: "fas fa-bowling-ball",
  },
  {
    type: "bus_station",
    label: "Bus Station",
    description: "For bus stations and transportation",
    icon: "fas fa-bus",
  },
  {
    type: "cafe",
    label: "Cafe",
    description: "For cafes and small eateries",
    icon: "fas fa-coffee",
  },
  {
    type: "campground",
    label: "Campground",
    description: "For camping grounds and parks",
    icon: "fas fa-campground",
  },
  {
    type: "car_dealer",
    label: "Car Dealer",
    description: "For car dealerships",
    icon: "fas fa-car",
  },
  {
    type: "car_rental",
    label: "Car Rental",
    description: "For car rentals and related services",
    icon: "fas fa-car-rental",
  },
  {
    type: "car_repair",
    label: "Car Repair",
    description: "For car repair shops",
    icon: "fas fa-tools",
  },
  {
    type: "car_wash",
    label: "Car Wash",
    description: "For car washing services",
    icon: "fas fa-car-wash",
  },
  {
    type: "casino",
    label: "Casino",
    description: "For casinos and gambling venues",
    icon: casino,
    iconType: "custom",
  },
  {
    type: "cemetery",
    label: "Cemetery",
    description: "For cemeteries and burial grounds",
    icon: "fas fa-cross",
  },
  {
    type: "church",
    label: "Church",
    description: "For churches and religious institutions",
    icon: church,
    iconType: "custom",
  },
  {
    type: "city_hall",
    label: "City Hall",
    description: "For city halls and government services",
    icon: "fas fa-city",
  },
  {
    type: "clothing_store",
    label: "Clothing Store",
    description: "For clothing and apparel stores",
    icon: "fas fa-tshirt",
  },
  {
    type: "convenience_store",
    label: "Convenience Store",
    description: "For convenience stores",
    icon: "fas fa-store",
  },
  {
    type: "courthouse",
    label: "Courthouse",
    description: "For courthouses and legal services",
    icon: "fas fa-gavel",
  },
  {
    type: "dentist",
    label: "Dentist",
    description: "For dental clinics and services",
    icon: "fas fa-tooth",
  },
  {
    type: "department_store",
    label: "Department Store",
    description: "For department stores",
    icon: "fas fa-store-alt",
  },
  {
    type: "doctor",
    label: "Doctor",
    description: "For medical services and doctors",
    icon: "fas fa-user-md",
  },
  {
    type: "electrician",
    label: "Electrician",
    description: "For electricians and electrical services",
    icon: "fas fa-plug",
  },
  {
    type: "electronics_store",
    label: "Electronics Store",
    description: "For electronics stores",
    icon: "fas fa-tv",
  },
  {
    type: "embassy",
    label: "Embassy",
    description: "For embassies and foreign offices",
    icon: "fas fa-flag",
  },
  {
    type: "fire_station",
    label: "Fire Station",
    description: "For fire stations and emergency services",
    icon: "fas fa-fire-extinguisher",
  },
  {
    type: "florist",
    label: "Florist",
    description: "For florists and flower shops",
    icon: "fas fa-seedling",
  },
  {
    type: "funeral_home",
    label: "Funeral Home",
    description: "For funeral homes and mortuaries",
    icon: "fas fa-coffin",
  },
  {
    type: "furniture_store",
    label: "Furniture Store",
    description: "For furniture stores",
    icon: "fas fa-couch",
  },
  {
    type: "gas_station",
    label: "Gas Station",
    description: "For gas stations and fueling services",
    icon: "fas fa-gas-pump",
  },
  {
    type: "gym",
    label: "Gym",
    description: "For gyms and fitness centers",
    icon: gym,
    iconType: "custom",
  },
  {
    type: "hair_care",
    label: "Hair Care",
    description: "For hair care salons",
    icon: hairCare,
    iconType: "custom",
  },
  {
    type: "hardware_store",
    label: "Hardware Store",
    description: "For hardware stores",
    icon: "fas fa-wrench",
  },
  {
    type: "hindu_temple",
    label: "Hindu Temple",
    description: "For Hindu temples and related services",
    icon: "fas fa-om",
  },
  {
    type: "hospital",
    label: "Hospital",
    description: "For hospitals and emergency care",
    icon: hospital,
    iconType: "custom",
  },
  {
    type: "insurance_agency",
    label: "Insurance Agency",
    description: "For insurance services",
    icon: "fas fa-shield-alt",
  },
  {
    type: "jewelry_store",
    label: "Jewelry Store",
    description: "For jewelry stores",
    icon: "fas fa-gem",
  },
  {
    type: "laundry",
    label: "Laundry",
    description: "For laundromats and dry cleaning services",
    icon: "fas fa-tshirt",
  },
  {
    type: "lawyer",
    label: "Lawyer",
    description: "For lawyers and legal services",
    icon: "fas fa-balance-scale",
  },
  {
    type: "library",
    label: "Library",
    description: "For libraries and book services",
    icon: "fas fa-book-reader",
  },
  {
    type: "light_rail_station",
    label: "Light Rail Station",
    description: "For light rail stations",
    icon: "fas fa-train",
  },
  {
    type: "liquor_store",
    label: "Liquor Store",
    description: "For liquor stores",
    icon: "fas fa-wine-bottle",
  },
  {
    type: "local_government_office",
    label: "Local Government Office",
    description: "For local government offices",
    icon: "fas fa-city",
  },
  {
    type: "locksmith",
    label: "Locksmith",
    description: "For locksmith services",
    icon: "fas fa-key",
  },
  {
    type: "lodging",
    label: "Lodging",
    description: "For hotels and other lodging services",
    icon: "fas fa-bed",
  },
  {
    type: "meal_delivery",
    label: "Meal Delivery",
    description: "For meal delivery services",
    icon: "fas fa-biking",
  },
  {
    type: "meal_takeaway",
    label: "Meal Takeaway",
    description: "For meal takeout services",
    icon: "fas fa-box-open",
  },
  {
    type: "mosque",
    label: "Mosque",
    description: "For mosques and Islamic religious services",
    icon: "fas fa-mosque",
  },
  {
    type: "movie_rental",
    label: "Movie Rental",
    description: "For movie rental stores",
    icon: "fas fa-film",
  },
  {
    type: "movie_theater",
    label: "Movie Theater",
    description: "For movie theaters",
    icon: "fas fa-theater-masks",
  },
  {
    type: "moving_company",
    label: "Moving Company",
    description: "For moving companies",
    icon: "fas fa-truck",
  },
  {
    type: "museum",
    label: "Museum",
    description: "For museums and exhibitions",
    icon: "fas fa-landmark",
  },
  {
    type: "night_club",
    label: "Night Club",
    description: "For nightclubs and bars",
    icon: "fas fa-music",
  },
  {
    type: "painter",
    label: "Painter",
    description: "For painters and painting services",
    icon: "fas fa-paint-brush",
  },
  {
    type: "park",
    label: "Park",
    description: "For parks and recreational areas",
    icon: parking,
    iconType: "custom",
  },
  {
    type: "parking",
    label: "Parking",
    description: "For parking services",
    icon: parking,
    iconType: "custom",
  },
  {
    type: "pet_store",
    label: "Pet Store",
    description: "For pet stores",
    icon: "fas fa-paw",
  },
  {
    type: "pharmacy",
    label: "Pharmacy",
    description: "For pharmacies and drug stores",
    icon: "fas fa-prescription-bottle",
  },
  {
    type: "physiotherapist",
    label: "Physiotherapist",
    description: "For physical therapy services",
    icon: "fas fa-hand-holding-heart",
  },
  {
    type: "plumber",
    label: "Plumber",
    description: "For plumbing services",
    icon: "fas fa-toolbox",
  },
  {
    type: "police",
    label: "Police",
    description: "For police stations",
    icon: "fas fa-shield-alt",
  },
  {
    type: "post_office",
    label: "Post Office",
    description: "For post offices and mail services",
    icon: "fas fa-envelope",
  },
  {
    type: "primary_school",
    label: "Primary School",
    description: "For primary schools",
    icon: school,
    iconType: "custom",
  },
  {
    type: "real_estate_agency",
    label: "Real Estate Agency",
    description: "For real estate services",
    icon: "fas fa-home",
  },
  {
    type: "restaurant",
    label: "Restaurant",
    description: "For restaurants and dining",
    icon: restaurant,
    iconType: "custom",
  },
  {
    type: "roofing_contractor",
    label: "Roofing Contractor",
    description: "For roofing services",
    icon: "fas fa-hard-hat",
  },
  {
    type: "rv_park",
    label: "RV Park",
    description: "For RV parks and recreational sites",
    icon: "fas fa-caravan",
  },
  {
    type: "school",
    label: "School",
    description: "For schools and educational institutions",
    icon: school,
    iconType: "custom",
  },
  {
    type: "secondary_school",
    label: "Secondary School",
    description: "For secondary schools",
    icon: school,
    iconType: "custom",
  },
  {
    type: "shoe_store",
    label: "Shoe Store",
    description: "For shoe stores",
    icon: "fas fa-shoe-prints",
  },
  {
    type: "shopping_mall",
    label: "Shopping Mall",
    description: "For shopping malls",
    icon: "fas fa-shopping-cart",
  },
  {
    type: "spa",
    label: "Spa",
    description: "For spa and relaxation centers",
    icon: "fas fa-spa",
  },
  {
    type: "stadium",
    label: "Stadium",
    description: "For stadiums and sports arenas",
    icon: "fas fa-football-ball",
  },
  {
    type: "storage",
    label: "Storage",
    description: "For storage facilities",
    icon: "fas fa-boxes",
  },
  {
    type: "store",
    label: "Store",
    description: "For stores and shops",
    icon: "fas fa-shopping-bag",
  },
  {
    type: "subway_station",
    label: "Subway Station",
    description: "For subway stations",
    icon: "fas fa-subway",
  },
  {
    type: "supermarket",
    label: "Supermarket",
    description: "For supermarkets and grocery stores",
    icon: supermarket,
    iconType: "custom",
  },
  {
    type: "synagogue",
    label: "Synagogue",
    description: "For synagogues and Jewish religious services",
    icon: "fas fa-star-of-david",
  },
  {
    type: "taxi_stand",
    label: "Taxi Stand",
    description: "For taxi stands",
    icon: "fas fa-taxi",
  },
  {
    type: "train_station",
    label: "Train Station",
    description: "For train stations",
    icon: trainStation,
    iconType: "custom",
  },
  {
    type: "transit_station",
    label: "Transit Station",
    description: "For public transit stations",
    icon: "fas fa-bus",
  },
  {
    type: "travel_agency",
    label: "Travel Agency",
    description: "For travel agencies",
    icon: "fas fa-plane",
  },
  {
    type: "university",
    label: "University",
    description: "For universities and colleges",
    icon: university,
    iconType: "custom",
  },
  {
    type: "veterinary_care",
    label: "Veterinary Care",
    description: "For veterinary services",
    icon: "fas fa-paw",
  },
  {
    type: "zoo",
    label: "Zoo",
    description: "For zoos and wildlife exhibits",
    icon: zoo,
    iconType: "custom",
  },
];
