import {
  ArrowBackIos,
  KeyboardArrowDown,
  ArrowForwardIos,
  KeyboardArrowUp,
  Favorite,
  Close,
} from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStepAction } from "../../store/Map/mapActions";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import { addToFavoriteList, addToBlackList } from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";
import { Map, useMapsLibrary } from "@vis.gl/react-google-maps";
import PathWithMarkers from "./PathWithMarkers";
import PlaceInfoCard from "./PlaceInfoCard";
import useListing from "../../hooks/useListing";
import CustomBottomSheet from "../../components/CustomBottomSheet/CustomBottomSheet";
import Area from "../Area/Area";

const LocationTag = ({ district, callback, openAreaBottomSheet }) => {
  const onLocationClick = (sheet) => {
    callback(sheet);
  };

  return (
    <div className="flex justify-between items-center absolute top-6 w-full">
      <div
        onClick={() => onLocationClick("neibhor")}
        className="flex items-center cursor-pointer bg-[#1289F6] text-[#fff] pl-2 pr-4 py-2 rounded-full rounded-l-none w-[1/2]"
      >
        <LocationOnIcon className="text-[#F9D100] m1-2" />
        <span className="!text-base !font-light">{district}</span>
      </div>
      <div className="flex items-center justify-end">
        <div className="mr-2 cursor-pointer">
          <img
            src={mapIcon}
            alt="map icon"
            onClick={() => onLocationClick("map")}
          />
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={openAreaBottomSheet}
        >
          <MapIcon className="text-[#fff] !text-[32px]" />
        </div>
      </div>
    </div>
  );
};
const ImageGallery = (props) => {
  const {
    swipeList,
    balooDir,
    details,
    isMobileScreen,
    nearbyPlace,
    listingMediaVideo,
    listingDetail,
    layout,
  } = props;
  const { showDetail } = useListing();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const activeStep = useSelector((state) => state.map.activeStep);
  const monthlyStay = useSelector((state) => state.filter.filters.monthlyStay);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [listingMedia, setListingMedia] = useState(props.listingMedia);
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const [activeSheet, setActiveSheet] = useState("");
  const [isSheetOpen, setSheetOpen] = useState(false);
  const {
    id,
    price,
    thumbnail,
    propertyName,
    review_score,
    review_nr,
    priceInfoContent,
    listingKey,
    badge,
    latitude,
    longitude,
  } = details?.properties || {};
  const mapCore = useMapsLibrary("core");
  const markerLib = useMapsLibrary("marker"); // Load the marker library
  // Handle swipe gestures for vertical scrolling
  const handlers = useSwipeable({
    onSwipedUp: () => handleNextImage(), // Swipe up to go to the next image
    onSwipedDown: () => handlePreviousImage(), // Swipe down to go to the previous image
    onSwipedLeft: () => handlePreviousListing(),
    onSwipedRight: () => handleNextListing(),
    preventScrollOnSwipe: true, // Prevent default scroll behavior
    trackMouse: true, // Enable mouse drag tracking
  });

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [activeStep]);

  useEffect(() => {
    mapMedia();
  }, [listingMediaVideo, props.listingMedia]);

  useEffect(() => {
    if (balooDir === "") return;

    if (balooDir === "left") {
      blacklist();
      if (currentImageIndex === 0) {
        setActiveStepAction(dispatch, swipeList[swipeList.length + 1]?.id);
      } else {
        const prevIndex = currentImageIndex - 1;
        setActiveStepAction(dispatch, swipeList[prevIndex]?.id);
      }
    }

    if (balooDir === "right") {
      handleFavorite();
      if (swipeList.length === currentImageIndex + 1) {
        setActiveStepAction(dispatch, swipeList[0]?.id);
      } else {
        const nextIndex = currentImageIndex + 1;
        setActiveStepAction(dispatch, swipeList[nextIndex]?.id);
      }
    }
  }, [balooDir]);

  const handleNextImage = () => {
    blacklist();
  };

  const handleNextListing = () => {
    if (swipeList?.length === currentListingIndex + 1) {
      setActiveStepAction(dispatch, swipeList[0]?.id);
      setCurrentListingIndex(0);
      showDetail(swipeList?.[0]);
    } else {
      const nextIndex = currentListingIndex + 1;
      setActiveStepAction(dispatch, swipeList[nextIndex]?.id);
      setCurrentListingIndex(nextIndex);
      showDetail(swipeList?.[nextIndex]);
    }
  };

  const handlePreviousListing = () => {
    if (currentListingIndex === 0) {
      setActiveStepAction(
        dispatch,
        swipeList?.length > 0
          ? swipeList?.[swipeList?.length - 1]?.id
          : swipeList?.[0]?.id
      );
      setCurrentListingIndex(0);
      showDetail(swipeList?.[0]);
    } else {
      const prevIndex = currentListingIndex - 1;
      setActiveStepAction(dispatch, swipeList?.[prevIndex]?.id);
      setCurrentListingIndex(prevIndex);
      showDetail(swipeList?.[prevIndex]);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(
        listingMedia?.length > 0 ? listingMedia?.length - 1 : 0
      );
    } else {
      const prevIndex = currentImageIndex - 1;
      setCurrentImageIndex(prevIndex);
    }
  };

  function mapMedia() {
    let media = props.listingMedia;
    if (listingMediaVideo?.length > 0) {
      media =
        media?.length > 0
          ? [...listingMediaVideo, ...media]
          : listingMediaVideo;
    }
    setListingMedia(media);
  }

  const handleFavorite = () => {
    dispatch(
      addToFavoriteList({
        userId: currentUser?.id,
        itemData: {
          isPinned: false,
          listingId: id,
          name: propertyName,
          phoneNumber: listingDetail?.phoneNumber || "",
          price,
          image: thumbnail,
          distance_cc: listingDetail?.distance_to_cc || "",
          review_score: review_score,
          review_word: badge,
          total_review: review_nr,
          description: priceInfoContent,
          propertyType: listingKey,
          class: details?.properties?.class,
          lat: details?.properties?.latitude,
          lng: details?.properties?.longitude,
          url: details?.properties?.listing?.webURL || listingDetail?.url || "",
          country: details?.properties?.country,
          checkin_date:
            details?.properties?.checkinDate || monthlyStay?.startDate,
          checkout_date:
            details?.properties?.checkoutDate || monthlyStay?.endDate,
          latlng: [latitude, longitude],
        },
      })
    );
  };

  const blacklist = () => {
    dispatch(
      addToBlackList({
        userId: currentUser?.id,
        itemData: {
          isPinned: false,
          listingId: details.id,
          name: propertyName,
          phoneNumber: listingDetail?.phoneNumber || "",
          price,
          image: thumbnail,
          distance_cc: listingDetail?.distance_to_cc || "",
          review_score: review_score,
          review_word: badge,
          total_review: review_nr,
          description: priceInfoContent,
          propertyType: listingKey,
          class: details?.properties?.class,
          lat: details?.properties?.latitude,
          lng: details?.properties?.longitude,
          url: details?.properties?.listing?.webURL || listingDetail?.url || "",
          country: details?.properties?.country,
          checkin_date:
            details?.properties?.checkinDate || monthlyStay?.startDate,
          checkout_date:
            details?.properties?.checkoutDate || monthlyStay?.endDate,
          latlng: [latitude, longitude],
        },
      })
    );
  };

  function isVideo(url) {
    const videoExtensions = /\.(mp4|webm|ogg|avi|mov|mkv|flv|wmv|m4v)$/i;
    if (videoExtensions.test(url)) {
      return true;
    }
    return false;
  }

  const handleBackdropClick = (e) => {
    if (e.target.id === "map" || e.target?.id === "neibhor") {
      setActiveSheet("");
    }
  };

  const toggleSheet = (sheet) => {
    setActiveSheet(sheet);
  };

  const openAreaBottomSheet = () => {
    setSheetOpen(!isSheetOpen);
  };
  return (
    <div className="bg-gray-100 h-full w-full relative">
      <div className="relative w-full h-full" {...handlers}>
        <LocationTag
          district={nearbyPlace?.neighbourhood || ""}
          callback={toggleSheet}
          openAreaBottomSheet={openAreaBottomSheet}
        />
        {/* Image */}
        {isVideo(listingMedia?.[currentImageIndex]?.url) ? (
          <a
            target="_blank"
            href={listingMedia?.[currentImageIndex]?.url}
            className="relative inline-block w-full h-full"
          >
            <video
              src={listingMedia?.[currentImageIndex]?.url}
              poster={listingMedia?.[currentImageIndex]?.thumbnail}
              className="w-full h-full"
              autoPlay
              controls
              loop
            />
          </a>
        ) : (
          <img
            src={
              listingMedia?.[currentImageIndex]?.url_1440 ||
              listingMedia?.[currentImageIndex]?.url ||
              listingMedia?.[currentImageIndex]?.baseUrl
            }
            alt={`listing ${currentImageIndex}`}
            className="w-full h-full max-h-[500px] object-cover"
          />
        )}

        {/* Image count at the center of the image */}
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-white text-sm bg-black bg-[#362B2B80] px-3 py-1 rounded-full">
          {currentImageIndex + 1} / {listingMedia?.length}
        </div>

        {/* Left Arrow */}
        {!isMobileScreen && (
          <React.Fragment>
            <div
              onClick={handlePreviousListing}
              className="absolute left-1 top-1/3 cursor-pointer  bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <ArrowBackIos className="!text-[24px]" />
            </div>
            <div
              onClick={handleNextListing}
              className="absolute right-1 top-1/3 cursor-pointer bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <ArrowForwardIos className="!text-[24px]" />
            </div>
          </React.Fragment>
        )}
        {!isMobileScreen && (
          <React.Fragment>
            <div
              onClick={handleFavorite}
              className="absolute right-1 top-[46%] cursor-pointer"
            >
              <Favorite
                className="!text-[24px] rounded-4"
                sx={{ color: "#f00", background: "#fff" }}
              />
            </div>
            <div
              onClick={handleNextImage}
              className="absolute right-1 top-[54%] cursor-pointer"
            >
              <Close
                className="!text-[24px] rounded-4"
                sx={{ color: "#000", background: "#fff" }}
              />
            </div>
          </React.Fragment>
        )}
        {!isMobileScreen && (
          <React.Fragment>
            <div
              onClick={handlePreviousImage}
              className="absolute top-10 left-1/2 cursor-pointer  bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <KeyboardArrowUp className="!text-[24px]" />
            </div>
            <div
              onClick={handleNextImage}
              className="absolute bottom-10 left-1/2  cursor-pointer bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <KeyboardArrowDown className="!text-[24px]" />
            </div>
          </React.Fragment>
        )}
      </div>
      {activeSheet === "map" && (
        <div
          id={`map`}
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end"
          onClick={handleBackdropClick}
        >
          <div className="bg-secondary-l0 w-full h-[78%] rounded-t-lg z-10 overflow-y-auto hide-scrollbar">
            {mapCore && (
              <Map
                disableDefaultUI={true}
                fullscreenControl={false}
                defaultZoom={12}
                zoomControlOptions={null}
                zoomControl={false}
                mapId={"map"}
                defaultCenter={{
                  lat: details?.properties?.latitude,
                  lng: details?.properties?.longitude,
                }}
                streetViewControl={false}
              >
                <PathWithMarkers
                  markerLib={markerLib}
                  propertyCoords={{
                    lat: details?.properties?.latitude,
                    lng: details?.properties?.longitude,
                  }}
                ></PathWithMarkers>
              </Map>
            )}
          </div>
        </div>
      )}
      {isMobileScreen && (
        <CustomBottomSheet isOpen={isSheetOpen} onDismiss={openAreaBottomSheet}>
          <Area isMobileScreen={isMobileScreen} />
        </CustomBottomSheet>
      )}
      {activeSheet === "neibhor" && (
        <div
          id={`neibhor`}
          onClick={handleBackdropClick}
          className="absolute inset-0  flex justify-center items-end"
        >
          <PlaceInfoCard placeName={nearbyPlace?.neighbourhood || ""} />
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
