import React from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { listType } from "../../common/Helpers";
import dayjs from "dayjs";

const ListDetailFooter = ({
  activeList,
  handlePreviousListing,
  handleNextListing,
  listingDetail,
}) => {
  const {
    listingKey,
    checkinDate,
    checkoutDate,
    priceBreakdown,
    priceInfoContent,
    pricingQuote,
  } = activeList?.properties || "";
  const { priceInfo, detailWebLink } = listingDetail || "";
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;

  return (
    <div className="flex items-center justify-between p-4 border-t border-gray-300">
      {/* Navigation Section */}
      <div className="flex items-center gap-2">
        <Button
          onClick={handlePreviousListing}
          variant="text"
          startIcon={<ArrowBackIcon />}
          style={{
            color: "#2D6ADC",
          }}
        >
          Previous
        </Button>

        {/* Delete and Favorite Icons */}
        <IconButton>
          <DeleteIcon className="text-[#CA0000]" />
        </IconButton>
        <IconButton>
          <FavoriteBorderIcon className="text-[#10A922]" />
        </IconButton>

        <Button
          onClick={handleNextListing}
          variant="text"
          endIcon={<ArrowForwardIcon />}
          style={{
            color: "#2D6ADC",
          }}
        >
          Next
        </Button>
      </div>

      {/* Pricing Section */}
      <div className="flex items-center gap-4">
        <div className="text-gray-700 font-semibold">
          {["rent", "buy", "sell"].includes(listingKey) ? (
            <span className="text-lg text-black">
              {priceInfo?.currencySuffix}
              {priceInfo?.amount}
            </span>
          ) : listingKey === "monthlyStay" ? (
            <span className="text-lg text-black">
              {
                pricingQuote?.structuredStayDisplayPrice?.primaryLine
                  ?.originalPrice
              }
            </span>
          ) : (
            <React.Fragment>
              <span className="text-lg text-black">
                ${Math.round(totalPrice)}
              </span>{" "}
              <span className="text-[#030202] text-[18px] font-light">
                {priceInfoContent}
              </span>
            </React.Fragment>
          )}
        </div>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#2D6ADC",
            borderRadius: "8px",
          }}
        >
          See availability &gt;
        </Button>
      </div>
    </div>
  );
};

export default ListDetailFooter;
