import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import nightIcon from "../../assets/icons/svg/night-new.svg";
import shorttermIcon from "../../assets/icons/svg/shortterm-new.svg";
import rentIcon from "../../assets/icons/svg/rent-new.svg";
import buyIcon from "../../assets/icons/svg/buy-new.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFilterView,
  toggleDrawerAction,
} from "../../store/Common/commonActions";
import { getSavedFilter, listType } from "../../common/Helpers";
import {
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setMainFilter,
  setHotelFilters,
  setRentFilters,
  setBuyFilters,
} from "@/store/Filter/filterReducers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "@/store/Filter/filterReducers";
import { removeListingByType } from "@/store/Listing/listingAction";
import FilterListIcon from "@mui/icons-material/Tune";

const ListingMenu = ({
  isDisableDrawerClose = false,
  isListingScreen = false,
}) => {
  const dispatch = useDispatch();
  let cachedFilters = getSavedFilter();
  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const filters = useSelector((state) => state.filter.filters || {});
  const [activeListing, setActiveListing] = useState(() => {
    if (selectedMainFilters.includes("hotels")) {
      return { hotels: true };
    }
    return {};
  });
  const menuItems = [
    {
      filterKey: "hotels",
      label: "Night",
      icon: nightIcon,
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
      icon: shorttermIcon,
    },
    {
      filterKey: "rent",
      label: "Rent",
      icon: rentIcon,
    },
    {
      filterKey: "buy",
      label: "Buy",
      icon: buyIcon,
    },
  ];

  useEffect(() => {
    if (cachedFilters?.selectedMainFilters?.length > 0) {
      let predefineFilter = {};
      cachedFilters.selectedMainFilters.map((sF) => {
        predefineFilter = { ...predefineFilter, [sF]: true };
      });
      setActiveListing(predefineFilter);
    }
  }, []);

  function handleFilterView(item) {
    dispatch(setActiveFilterView(item));
    !isDisableDrawerClose && dispatch(toggleDrawerAction());
  }

  function handleListingType(filterKey) {
    if (!activeListing?.[filterKey]) {
      handleApplyFilter(filterKey, filters?.[filterKey]);
    } else {
      handleClearFilter(filterKey);
    }
    setActiveListing({
      ...activeListing,
      [filterKey]: !activeListing?.[filterKey],
    });
  }

  const handleClearFilter = (filterKey) => {
    const actionMap = {
      hotels: clearHotelFilters,
      shortTerm: clearShortTermFilters,
      monthlyStay: clearMonthlyFilters,
      rent: clearRentFilters,
      buy: clearBuyFilters,
    };
    const action = actionMap[filterKey];
    dispatch(removeListingByType(filterKey));
    if (action) {
      dispatch(action());
    }
  };

  const handleApplyFilter = (filterKey, values) => {
    const actionMap = {
      hotels: setHotelFilters,
      monthlyStay: setMonthlyFilters,
      rent: setRentFilters,
      buy: setBuyFilters,
    };
    const action = actionMap[filterKey];
    if (action) {
      dispatch(action({ ...values, isRefatched: true }));
      dispatch(setMainFilter(filterKey));
    }
  };

  if (isListingScreen) {
    return (
      <div className="flex justify-around  max-h-[85px] items-center gap-6 p-4 bg-[#fff]  w-full">
        {menuItems.map((item, index) => (
          <div
            key={index}
            className="flex justify-center items-center  cursor-pointer"
          >
            <div className="flex flex-col items-center">
              <div onClick={() => handleFilterView(item)}>
                <div className="text-xs text-gray-700">Filter</div>
                <FilterListIcon />
              </div>
              <Checkbox
                checked={activeListing?.[item.filterKey] || false}
                onChange={() => handleListingType(item.filterKey)}
                sx={{
                  color: listType[item.filterKey].color || "gray", // Default color when unchecked
                  "&.Mui-checked": {
                    color: listType[item.filterKey].color || "blue", // Default color when checked
                  },
                }}
              />
            </div>
            <div className="flex flex-col items-center">
              <div onClick={() => handleFilterView(item)}>
                <img src={item.icon} alt="item.label" />
              </div>
              <div className="text-sm text-gray-700">{item.label}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex gap-6 p-4 bg-[#fff] shadow-lg rounded-t-3xl z-50 border border-solid border-[#E5E1E1] min-w-[320px]">
      {menuItems.map((item, index) => (
        <div key={index} className="flex flex-col items-center  cursor-pointer">
          <Checkbox
            checked={activeListing?.[item.filterKey] || false}
            onChange={() => handleListingType(item.filterKey)}
            sx={{
              color: listType[item.filterKey].color || "gray", // Default color when unchecked
              "&.Mui-checked": {
                color: listType[item.filterKey].color || "blue", // Default color when checked
              },
            }}
          />
          <div onClick={() => handleFilterView(item)}>
            <img src={item.icon} alt="item.label" />
          </div>
          <span className="text-sm text-gray-700">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default ListingMenu;
