import React, { useState, useEffect } from "react";
import { Box, Typography, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

const WhiteSlider = styled(Slider)({
  color: "#fff", // Set slider color to white
  "& .MuiSlider-thumb": {
    borderRadius: "50%", // Optional: round thumb
  },
  "& .MuiSlider-track": {
    borderColor: "#fff", // Track color
  },
  "& .MuiSlider-rail": {
    color: "#fff", // Rail color (unfilled portion)
  },
});

const MuiSlider = (props) => {
  const {
    value = [0, 10],
    onChange,
    displayLabel,
    min,
    max,
    onChangeCommitted,
  } = props;
  return (
    <WhiteSlider
      value={value}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      min={min}
      max={max}
      valueLabelDisplay={displayLabel} // Disable value labels
    />
  );
};

export default MuiSlider;
