import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";

const StyledInput = styled(TextField)({
  color: "#fff !important",
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff !important",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff !important",
    },
  },
});

const Input = ({ label, value, onChange, variant, type, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const iconMap = {
    visibility: <Visibility />,
    visibilityOff: <VisibilityOff />,
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  if (type === "password") {
  }
  return (
    <StyledInput
      {...props}
      fullWidth
      variant={variant || "standard"}
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? "text" : type || "text"}
      InputLabelProps={{
        sx: {
          color: "white",
        },
      }}
      InputProps={{
        sx: {
          color: "white",
        },
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff sx={{ color: "white" }} />
                ) : (
                  <Visibility sx={{ color: "white" }} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

export default Input;
