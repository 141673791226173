import React, { useEffect } from "react";
import LMMap from "../../components/LeftCol/Map/Map";
import MapSearch from "../../components/LeftCol/MapSearch";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";
import useSupabase from "../../hooks/useSupabase";
import useFilter from "../../hooks/useFilter";
import usePosition from "../../hooks/usePosition";
import { setCenterAction } from "@/store/Map/mapActions";
import { setCoordinates } from "@/store/Filter/filterReducers";
import PlaceInfoCard from "../Swipe/PlaceInfoCard";

function MapScreen() {
  const { fetchUser } = useSupabase();
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);
  useFilter();

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-full  gap-6 relative">
      <div className="absolute left-4 top-[15px] z-50 w-full max-w-[400px]">
        <div className="w-full max-w-[400px]">
          <MapSearch onPlaceChanged={() => console.log("Place changed")} />
        </div>

        {filterState?.searchText?.text && (
          <div className=" max-w-[400px] flex items-center justify-center">
            <div className="max-w-[350px]">
              <PlaceInfoCard
                placeName={filterState?.searchText?.text || ""}
                isMapScreen={true}
              />
            </div>
          </div>
        )}
      </div>
      <LMMap />
    </div>
  );
}

export default MapScreen;
