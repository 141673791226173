import React from "react";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import { typeMapping } from "../../common/Helpers";

const ListingsButton = ({ total, callback, activeTab }) => {
  const activeListingType = typeMapping(activeTab);
  return (
    <Button
      className={`flex flex-grow px-4 py-2 rounded-full shadow-xl max-w-48`}
      sx={{
        backgroundColor: activeListingType?.tabBg, // Orange background
        color: activeListingType?.color, // White text
        "&:hover": {
          backgroundColor: activeListingType?.tabBg, // Darker orange on hover
        },
        display: "flex",
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        alignItems: "center",
      }}
      startIcon={["rent", "buy", "all"].includes(activeTab) ? <HomeIcon /> : ""}
      onClick={callback}
    >
      {total} {activeListingType?.text} listings
    </Button>
  );
};

export default ListingsButton;
