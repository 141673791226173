import React, { useEffect } from "react";
import ListingOverview from "./ListingOverview";
import { Room } from "@mui/icons-material";
import MostPopularFacilities from "../../components/Listing/MosPopularFacilities";
import { useDispatch } from "react-redux";
import { getListingOverviewAction } from "../../store/Listing/listingAction";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import IconCircular from "../../components/IconCircular/IconCircular";
import CustomChip from "../../components/CustomChip";
import AirbnbRatings from "../../components/Listing/AirbnbRating";
import ThingsToKnow from "../../components/Listing/ThingsToKnow";

// Reusable Modal Component
const DetailContentAirbnb = ({
  open,
  onClose,
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  isFullScreen = false,
  toggleFullscreen,
  cityCenter,
}) => {
  const { sections } = listingDetail;
  const geometry = property?.geometry;
  const dispatch = useDispatch();
  const listingOverview =
    sections?.descriptionDefault?.descriptionSummary?.htmlText;
  const highlightsChip = sections?.highlights?.highlights;
  const chipClrs = [
    "#a1d4f2",
    "#f54291",
    "#5bc41b",
    "#ff7d45",
    "#7635ff",
    "#1f8c9a",
    "#e3f716",
    "#ad3f21",
    "#5e87a6",
    "#d450f3",
  ];

  useEffect(() => {
    if (
      property?.properties?.id !== listingOverview?.listingId &&
      (property?.properties?.accommodationType === "Hotel" ||
        property?.properties?.accommodationType === "Shortterm")
    ) {
      dispatch(
        getListingOverviewAction(
          {
            listingId: property?.properties?.id,
          },
          property?.properties?.accommodationType
        )
      );
    }
  }, []);
  return (
    <React.Fragment>
      <ListingOverview
        property={property}
        majorLocation={majorLocation}
        myLocation={myLocation}
        listingDetail={listingDetail}
        listingMedia={listingMedia}
        toggleFullscreen={toggleFullscreen}
        isFullScreen={isFullScreen}
      />
      <div className="flex items-center">
        <div className="w-2/3 mt-3">
          {sections?.location?.address && (
            <div className="flex items-center gap-0 text-[14px] font-light">
              <Room className="text-[#f00]" />
              <div>{sections?.location?.address}</div>
            </div>
          )}
          <div className="flex justify-between items-center gap-5 mt-6">
            <div className="flex items-center">
              <DirectionIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light">
                {getDistanceFromLatLonInKm(
                  cityCenter?.lat,
                  cityCenter?.lng,
                  geometry?.coordinates?.[0],
                  geometry?.coordinates?.[1]
                ).toFixed(1)}{" "}
                km from city center
              </span>
            </div>
            <div className="flex items-center">
              <DirectionsWalkIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light">4 min</span>
            </div>
            <div className="flex items-center">
              <DirectionsCarIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light text-[#291D1D]">
                30 min
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center gap-5 mt-6">
            <div className="flex items-center">
              <div className="w-10 h-10 ">
                <IconCircular
                  alt={"locaton"}
                  key={"location"}
                  src={
                    majorLocation?.image || "https://picsum.photos/200?random=5"
                  }
                />
              </div>
              <DirectionIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light">
                {getDistanceFromLatLonInKm(
                  majorLocation?.latitude,
                  majorLocation?.longitude,
                  geometry?.coordinates?.[0],
                  geometry?.coordinates?.[1]
                ).toFixed(1)}{" "}
                km to {majorLocation?.locationName}
              </span>
            </div>
            <div className="flex items-center">
              <DirectionsWalkIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light">4 min</span>
            </div>
            <div className="flex items-center">
              <DirectionsCarIcon className="text-[#2D6ADC]" />
              <span className="text-[14px] font-light text-[#291D1D]">
                30 min
              </span>
            </div>
          </div>
        </div>
        <div className="w-1/3 flex justify-center items-center mt-6">
          <div className="w-[50%] flex flex-col gap-3">
            {highlightsChip?.length > 0 &&
              highlightsChip.map((chip, i) => (
                <CustomChip
                  text={chip?.title}
                  chipBgClr={chipClrs?.[i] || ""}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="mt-[34px]">
        <div className="text-[22px] font-sans font-medium">Hotel overview</div>
        <div className="mt-[20px]">
          <div
            className="!text-[14px] font-sans font-light"
            dangerouslySetInnerHTML={{
              __html: listingOverview || "",
            }}
            style={{ whiteSpace: "pre-wrap" }}
          ></div>
        </div>
      </div>
      <div className="mt-[34px]">
        <div className="text-[22px] font-sans font-medium">
          Most popular facilities
        </div>
        <MostPopularFacilities
          listingDetail={{
            facility: listingDetail?.sections?.amenities?.seeAllAmenitiesGroups,
          }}
        />
      </div>
      <div>
        <AirbnbRatings listingDetail={listingDetail} />
      </div>
      <div>
        <ThingsToKnow sections={sections} />
      </div>
    </React.Fragment>
  );
};

export default DetailContentAirbnb;
