import React, { useState } from "react";
import { Card, Typography, Checkbox } from "@mui/material";
import { listType } from "../../common/Helpers";
import nightIcon from "../../assets/icons/svg/night-new.svg";
import shorttermIcon from "../../assets/icons/svg/shortterm-new.svg";
import rentIcon from "../../assets/icons/svg/rent-new.svg";
import buyIcon from "../../assets/icons/svg/buy-new.svg";
import {
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setMainFilter,
  setHotelFilters,
  setRentFilters,
  setBuyFilters,
} from "@/store/Filter/filterReducers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "@/store/Filter/filterReducers";
import { removeListingByType } from "@/store/Listing/listingAction";
import { useDispatch, useSelector } from "react-redux";

const StaySelection = ({ onSelectStayType, defaultSelected }) => {
  const [selected, setSelected] = useState(defaultSelected);
  const filters = useSelector((state) => state.filter.filters || {});
  const dispatch = useDispatch();

  const options = [
    {
      filterKey: "hotels",
      label: "Night",
      icon: nightIcon,
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
      icon: shorttermIcon,
    },
    {
      filterKey: "rent",
      label: "Rent",
      icon: rentIcon,
    },
    {
      filterKey: "buy",
      label: "Buy",
      icon: buyIcon,
    },
  ];

  const handleSelect = (id) => {
    if (!selected?.[id]) {
      handleApplyFilter(id, filters?.[id]);
    } else {
      handleClearFilter(id);
    }
    setSelected({ ...selected, [id]: !selected?.[id] });
    onSelectStayType({ ...selected, [id]: !selected?.[id] });
  };

  const handleClearFilter = (filterKey) => {
    const actionMap = {
      hotels: clearHotelFilters,
      shortTerm: clearShortTermFilters,
      monthlyStay: clearMonthlyFilters,
      rent: clearRentFilters,
      buy: clearBuyFilters,
    };
    const action = actionMap[filterKey];
    dispatch(removeListingByType(filterKey));
    if (action) {
      dispatch(action());
    }
  };

  const handleApplyFilter = (filterKey, values) => {
    const actionMap = {
      hotels: setHotelFilters,
      monthlyStay: setMonthlyFilters,
      rent: setRentFilters,
      buy: setBuyFilters,
    };
    const action = actionMap[filterKey];
    if (action) {
      dispatch(action({ ...values, isRefatched: true }));
      dispatch(setMainFilter(filterKey));
    }
  };

  return (
    <div className="h-full bg-[#000] flex flex-col items-center justify-center text-[#fff] w-full gap-10">
      <Typography variant="h4" className="mb-6">
        Select type of stay
      </Typography>
      <div className="flex gap-4">
        {options.map((option) => (
          <div key={option.filterKey}>
            <Card
              className={`flex flex-col !text-[#fff] w-[200px] items-center justify-center p-4 rounded-s-2xl cursor-pointer transition-transform transform ${
                listType[option.filterKey].bg
              }`}
              onClick={() => handleSelect(option.filterKey)}
            >
              <div className="text-3xl mb-2">
                <img src={option.icon} alt={option.filterKey} />
              </div>
              <Typography variant="body1" className="mb-2">
                {option.label}
              </Typography>
            </Card>
            <div className="flex items-center justify-center mt-4">
              <Checkbox
                checked={selected?.[option.filterKey] || false}
                onChange={() => handleSelect(option.filterKey)}
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "white",
                  },
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaySelection;
