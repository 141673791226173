import React, { useState } from "react";
import useSupabase from "../../hooks/useSupabase";
import Input from "../../components/Input/Input";
import MuiButton from "../../components/MuiButton/MuiButton";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { supabaseState, handlePasswordReset } = useSupabase();

  return (
    <div className="bg-gradient-to-b from-primary to-primary-light h-full w-full flex items-center justify-center gap-4">
      <div className=" flex flex-col items-center justify-center gap-5 px-2">
        <div className="!text-[#fff] text-lg text-center">Forgot Password</div>
        <Input
          autoComplete="new-password"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type={"email"}
        />
        <MuiButton onClick={() => handlePasswordReset(email)}>
          Send Password Reset Email
        </MuiButton>
        {supabaseState?.passResetErr?.isError && (
          <Alert severity="error">{supabaseState?.passResetErr?.message}</Alert>
        )}
        {supabaseState?.isPasswordResetEmailSent && (
          <Alert severity="success">
            Please check your email and reset password.
          </Alert>
        )}
        <div className="text-secondary-l0">
          <Link
            to="/"
            className="text-secondary-l0 hover:underline no-underline text-[32px] font-light !drop-shadow-2xl"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
