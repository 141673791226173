import React, { useEffect } from "react";

const BookingPrice = ({ listingDetail }) => {
  const {
    name,
    block_text,
    product_price_breakdown,
    detail_mealplan,
    bundle_extras,
    room_surface_in_m2,
    max_occupancy,
    room_name,
    paymentterms,
  } = listingDetail;
  return (
    <div className="room-details !text-xs">
      <h2>{room_name}</h2>
      <p>{name}</p>
      <p>Room Size: {room_surface_in_m2} m²</p>
      <p>Max Occupancy: {max_occupancy}</p>

      {/* Display Policies */}
      <h3>Policies</h3>
      <ul>
        {(block_text?.policies || []).map((policy, index) => (
          <li key={index}>{policy.content}</li>
        ))}
      </ul>

      {/* Meal Plan Details */}
      <h3>Meal Plan</h3>
      <ul>
        {(detail_mealplan || []).map((meal, index) => (
          <li key={index}>{meal.title}</li>
        ))}
      </ul>

      {/* Pricing Breakdown */}
      <h3>Price Breakdown</h3>
      <ul>
        {(product_price_breakdown?.items || []).map((item, index) => (
          <li key={index}>
            {item.name}: {item.item_amount.amount_rounded} ({item.details || ""}
            )
          </li>
        ))}
      </ul>

      {/* Total Price */}
      <h3>
        Total Price: {product_price_breakdown?.gross_amount.amount_rounded}
      </h3>
      <p>{paymentterms?.cancellation.description}</p>
      <p>{paymentterms?.prepayment.description}</p>

      {/* Additional Benefits */}
      <h3>Extras</h3>
      <p>{bundle_extras?.highlighted_text}</p>
      <ul>
        {(bundle_extras?.benefits || []).map((benefit, index) => (
          <li key={index}>
            {benefit.title}: {benefit.details.join(", ")}
          </li>
        ))}
      </ul>
    </div>
  );
};
const AirbnbPrice = ({ infoPrice }) => {
  if (!infoPrice) {
    return <div></div>;
  }
  const { accommodationCostFormatted, details } = infoPrice || {};
  const totalPrice = details.reduce((total, item) => total + item.amount, 0);
  return (
    <div className="p-6 border rounded-lg shadow-md bg-white w-80">
      <h2 className="text-lg font-semibold mb-4">Price Details</h2>

      <div className="mb-3">
        <strong>Accommodation Cost:</strong>
        <p className="text-gray-700">{accommodationCostFormatted}</p>
      </div>

      <div className="space-y-2">
        {details.map((detail, index) => (
          <div key={index} className="flex justify-between">
            <span className="text-gray-600">{detail.localizedTitle}</span>
            <span className="text-gray-800">{detail.amountFormatted}</span>
          </div>
        ))}
      </div>

      <hr className="my-4" />

      <div className="flex justify-between text-lg font-bold">
        <span>Total:</span>
        <span>€ {totalPrice.toFixed(2)}</span>
      </div>
    </div>
  );
};
const IdealistaPrice = ({ property }) => {
  const { priceInfo, ubication, contactInfo, moreCharacteristics } =
    property || {};

  return (
    <div className="p-6 border rounded-lg shadow-md bg-white max-w-lg">
      {/* Title */}
      <h2 className="text-2xl font-semibold mb-2">{ubication?.title}</h2>
      <p className="text-gray-600 mb-4">
        {ubication?.administrativeAreaLevel2},{" "}
        {ubication?.administrativeAreaLevel1}
      </p>

      {/* Price */}
      <div className="flex justify-between items-center bg-gray-100 p-3 rounded mb-4">
        <div>
          <span className="text-lg font-bold">
            {priceInfo?.amount} {priceInfo?.currencySuffix}
          </span>
        </div>
        <span className="text-sm text-gray-500">per month</span>
      </div>

      {/* Characteristics */}
      <div className="mb-4">
        <p className="font-semibold">Property Features:</p>
        <ul className="list-disc list-inside text-gray-700">
          <li>{moreCharacteristics?.roomNumber} Rooms</li>
          <li>{moreCharacteristics?.bathNumber} Bathrooms</li>
          <li>{moreCharacteristics?.constructedArea} m² Constructed Area</li>
          <li>{moreCharacteristics?.usableArea} m² Usable Area</li>
          <li>
            Floor: {moreCharacteristics?.floor}{" "}
            {moreCharacteristics?.isInTopFloor && "(Top floor)"}
          </li>
          <li>{moreCharacteristics?.status}</li>
        </ul>
      </div>

      {/* Contact */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <h3 className="text-lg font-semibold">Contact Information:</h3>
        <p className="text-gray-600">Name: {contactInfo?.contactName}</p>
        <p className="text-gray-600">
          Phone: {contactInfo?.phone1?.formattedPhoneWithPrefix}
        </p>
      </div>
    </div>
  );
};

const InfoPrice = ({ listingInfo, listingType, infoPrice }) => {
  let listingDetail = listingInfo?.block?.[0];

  if (listingType === "rent" || listingType === "buy") {
    return <IdealistaPrice property={listingInfo} />;
  }
  if (listingType === "monthlyStay") {
    return <AirbnbPrice infoPrice={infoPrice} />;
  }
  return <BookingPrice listingDetail={listingDetail} />;
};
export default InfoPrice;
