import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  airbnbAmenities,
  airbnbPropertyTypes,
  airbnbTypeOfPlace,
} from "../../../common/utils/airbnbFilters";
import FilterCounter from "../../FilterCounter";
import FilterChip from "../../FilterChip";

const MonthlyFilters = ({ onChange, filterKey }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(filters?.[filterKey] || "");

  function handleChange(e, checked, key) {
    const name = e.target?.name || "";
    let currentFilters = updatedFilters?.[key] || [];
    if (checked) {
      currentFilters.push(name);
    } else {
      currentFilters = currentFilters.filter((c) => c !== name);
    }
    const changedValues = { ...updatedFilters, [key]: currentFilters };
    setFilters(changedValues);
    if (onChange) {
      onChange(changedValues);
    }
  }
  function handleChangeMin(e, value, key) {
    const changedValues = { ...updatedFilters, [key]: value };
    setFilters(changedValues);
    if (onChange) {
      onChange(changedValues);
    }
  }

  return (
    <div className="  w-full ">
      <Box className="px-4 pt-4">
        <div className="my-2 text-[#000]/80 font-semibold">Rooms and Beds</div>
        <FilterCounter
          label="Bedrooms"
          min={0}
          max={10}
          defaultValue={1}
          onChange={(value) => handleChangeMin(null, value, "minBedrooms")}
        />
        <FilterCounter
          label="Beds"
          min={0}
          max={10}
          defaultValue={1}
          onChange={(value) => handleChangeMin(null, value, "minBeds")}
        />
        <FilterCounter
          label="Bathrooms"
          min={0}
          max={10}
          defaultValue={1}
          onChange={(value) => handleChangeMin(null, value, "minBathrooms")}
        />
      </Box>
      <FilterChip
        options={airbnbPropertyTypes}
        onSelect={(e, checked, name) =>
          handleChange({ target: { name: name } }, checked, e.target.name)
        }
        defaultSelected={updatedFilters?.property_type}
        filterKey={filterKey}
        label={"Property Type"}
        fieldName={"property_type"}
        valueField="title"
        labelField="title"
      />
      <FilterChip
        options={airbnbAmenities}
        onSelect={(e, checked, name) =>
          handleChange({ target: { name: name } }, checked, e.target.name)
        }
        defaultSelected={updatedFilters?.amenities}
        filterKey={filterKey}
        label={"Amenities"}
        fieldName={"amenities"}
        valueField="title"
        labelField="title"
      />
      <FilterChip
        options={airbnbTypeOfPlace}
        onSelect={(e, checked, name) =>
          handleChange({ target: { name: name } }, checked, e.target.name)
        }
        defaultSelected={updatedFilters?.type_of_place}
        filterKey={filterKey}
        label={"Type of Place"}
        fieldName={"type_of_place"}
        valueField="title"
        labelField="title"
      />
    </div>
  );
};

export default MonthlyFilters;
