import React, { useState, useEffect, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import DirectionsIcon from "@mui/icons-material/Directions";
import notesIcon from "../../assets/icons/svg/notes.svg";
import Button from "@mui/material/Button";
import { typeMapping } from "../../common/Helpers";

const HotelListItem = ({
  detail,
  longPressCallback,
  showNotesCallback,
  openListingDetail,
  showItemMapCallback
}) => {
  const itemRef = useRef(null);
  const timerRef = useRef(null); // To track long press timer
  const [isLongPress, setIsLongPress] = useState(false);

  const {
    name,
    price,
    image,
    distance_cc,
    review_score,
    review_word,
    total_review,
    description,
    listingId,
    isPinned,
    notes,
  } = detail;

  const handlePressStart = () => {
    setIsLongPress(false);
    timerRef.current = setTimeout(() => {
      setIsLongPress(true);
      longPressCallback(true, listingId, isPinned);
    }, 500); // Set long press duration (500ms)
  };

  // Clear the long press timer and handle click or long press
  const handlePressEnd = () => {
    clearTimeout(timerRef.current);

    // If long press didn't trigger, treat it as a click
    if (!isLongPress) {
      console.log("clicked");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.parentElement?.classList.contains("actions")) return;
      if (
        event.target.parentElement?.parentElement?.classList.contains("actions")
      )
        return;
      if (itemRef.current && !itemRef.current.contains(event.target)) {
        // Clear long press and reset state if clicked outside
        clearTimeout(timerRef.current);
        setIsLongPress(false);
        longPressCallback(false, listingId, isPinned);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // For mobile touch

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [itemRef, longPressCallback, listingId, isPinned]);

  const showNotes = () => {
    showNotesCallback(notes);
  };

  const [rating, reviews] =
    detail?.propertyType === "monthlyStay"
      ? review_score.match(/([\d.]+)\s\((\d+)\)/).slice(1)
      : [];
  const ratingNumber =
    detail?.propertyType === "monthlyStay" ? parseFloat(rating) : review_score;
  const reviewCount =
    detail?.propertyType === "monthlyStay"
      ? parseInt(reviews, 10)
      : total_review;
  return (
    <div
      ref={itemRef}
      className={`flex space-x-4 py-4 border rounded-md shadow-sm w-full ${
        isLongPress ? "bg-secondary-l2" : ""
      }`}
      onMouseDown={handlePressStart}
      onMouseUp={handlePressEnd}
      onMouseLeave={handlePressEnd}
      onTouchStart={handlePressStart}
      onTouchEnd={handlePressEnd}
    >
      <div className="relative">
        <img
          src={image}
          alt="Property"
          className="w-20 h-20 rounded-lg object-cover"
        />
        {notes && (
          <img
            onClick={showNotes}
            className="w-6 h-6 absolute right-0.5 top-0.5 cursor-pointer"
            src={notesIcon}
            alt="notes"
          />
        )}
        <div className="absolute top-0 left-0">
          <img src={mapIcon} alt="map icon" onClick={() => showItemMapCallback(detail)} />
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between w-full">
          <h2
            onClick={() => openListingDetail(detail)}
            className="text-base font-normal cursor-pointer hover:text-primary"
          >
            {name}
          </h2>
          <div
            style={{
              background: typeMapping(detail?.propertyType)?.tabBg,
            }}
            className="flex items-center justify-center w-8 h-8 rounded-md  text-[#fff] text-[14px] font-medium"
          >
            {ratingNumber}
          </div>
        </div>
        <div className="flex justify-between w-full mb-2">
          <div className="flex items-center space-x-1">
            <DirectionsIcon className="text-primary" />
            <span className="text-secondary-light text-[14px]">
              {Math.round(distance_cc)} km from center
            </span>
          </div>
          <div>
            <div className="text-[#2B1A1A] font-normal text-[12px] text-right">
              {review_word}
            </div>
            <div className="font-extralight text-[#2B1A1A] text-[12px]">
              {reviewCount} reviews
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div>
            <div>
              <div className="text-[#2B1A1A] font-normal">
                {detail?.propertyType === "monthlyStay"
                  ? price
                  : `€${Math.round(price)}`}
              </div>
              <div className="font-extralight text-[#2B1A1A] text-[14px]">
                {description}
              </div>
            </div>
          </div>
          <div className="flex space-x-1">
            {[...Array(detail.class)].map((_, index) => (
              <StarIcon
                key={index}
                className="text-warn"
                sx={{ fontSize: "16px" }}
              />
            ))}
          </div>
        </div>
        <a
          className="flex justify-end w-full no-underline"
          target="_blank"
          href={detail?.url}
        >
          <Button
            sx={{
              borderRadius: "6px",
              background: typeMapping(detail?.propertyType)?.tabBg,
            }}
            variant="contained"
            className={`text-secondary-l0  !px-2 !py-1 !text-[10px]`}
          >
            See availability
          </Button>
        </a>
      </div>
    </div>
  );
};

export default HotelListItem;
