import React, { useEffect, useState } from "react";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
} from "@vis.gl/react-google-maps";
import { ReactComponent as Panorama } from "../../assets/icons/svg/panorama.svg";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import StreetViewSlider from "../Area/StreetViewSlider";
import { Home } from "@mui/icons-material";

const StreetTab = ({ isMobileScreen = false, activeItem }) => {
  const [radius, setRadius] = useState(0);
  const [panoramaPos, setPanoramaPos] = useState(null);
  const mapCore = useMapsLibrary("core");
  const streetView = useMapsLibrary("streetView");

  const map = useMap();

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }

    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)

    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;

    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    return bounds;
  }

  useEffect(() => {
    if (!mapCore || !window.google) {
      return;
    }
    const bounds = getCircleBounds(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      radius
    );
    if (!bounds) return null;
    map && map.fitBounds(bounds);
  }, [radius]);

  useEffect(() => {
    if (map && window.google.maps && streetView) {
      map && map.setStreetView(null);
      const streetViewPosition = {
        lat: activeItem?.properties?.latitude,
        lng: activeItem?.properties?.longitude,
      };
      const panorama = new streetView.StreetViewPanorama(
        document.querySelector(".street-map"),
        {
          position: streetViewPosition,
          pov: { heading: 165, pitch: 0 },
          zoom: 1,
        }
      );
      mapPanorama(panorama);
      panorama.addListener("position_changed", () => {
        // Get the new position
        mapPanorama(panorama);
      });
      map && map.setStreetView(panorama);
    }
  }, [activeItem, streetView]);

  function mapPanorama(panorama) {
    const newPosition = panorama.getPosition();
    setPanoramaPos({ lat: newPosition.lat(), lng: newPosition.lng() });
    const bounds = new window.google.maps.LatLngBounds();
    const pos = new window.google.maps.LatLng(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude
    );
    bounds.extend(newPosition);
    bounds.extend(pos);
    map.fitBounds(bounds);
  }

  function defaultStreetViewRadius() {
    return getDistanceFromLatLonInKm(
      panoramaPos?.lat,
      panoramaPos?.lng,
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude
    ).toFixed(2);
  }
  console.log(
    "mapCore && activeItem && panoramaPos &&",
    mapCore,
    activeItem,
    panoramaPos
  );
  return (
    <div className={" w-full h-full relative"}>
      <div className={" w-full h-full "}>
        <div className="flex flex-col bg-secondary-l0 w-full h-full rounded-t-lg z-10 overflow-y-auto hide-scrollbar relative">
          <StreetViewSlider
            className={"absolute top-[113px]  left-[50%] z-30"}
            defaultRadius={defaultStreetViewRadius()}
            valueChange={(radius) => setRadius(radius)}
            activeItem={activeItem?.properties}
          />
          {mapCore && activeItem && (
            <Map
              disableDefaultUI={true}
              fullscreenControl={false}
              defaultZoom={15}
              zoomControlOptions={null}
              zoomControl={true}
              mapId={"map-street"}
              defaultCenter={{
                lat: activeItem?.properties?.latitude,
                lng: activeItem?.properties?.longitude,
              }}
              streetViewControl={false}
              className="h-full street-map"
            >
              {activeItem && (
                <AdvancedMarker
                  position={{
                    lat: activeItem?.properties?.latitude,
                    lng: activeItem?.properties?.longitude,
                  }}
                >
                  <Home
                    sx={{ fontSize: 50, color: "red", shadow: 3 }}
                    className="w-12 h-12 shadow-lg  cursor-pointer"
                  />
                </AdvancedMarker>
              )}
            </Map>
          )}
        </div>
      </div>
      <div className="absolute bottom-3 right-3 h-[300px] w-[400px] z-20 rounded-[20px]">
        {mapCore && activeItem && panoramaPos && (
          <Map
            disableDefaultUI={true}
            fullscreenControl={false}
            defaultZoom={15}
            zoomControlOptions={null}
            zoomControl={true}
            mapId={"map-street"}
            defaultCenter={{
              lat: activeItem?.properties?.latitude,
              lng: activeItem?.properties?.longitude,
            }}
            streetViewControl={false}
            className="h-full "
          >
            {panoramaPos && (
              <AdvancedMarker position={panoramaPos}>
                <Panorama />
              </AdvancedMarker>
            )}
            {activeItem && (
              <AdvancedMarker
                position={{
                  lat: activeItem?.properties?.latitude,
                  lng: activeItem?.properties?.longitude,
                }}
              >
                <Home
                  sx={{ fontSize: 50, color: "red", shadow: 3 }}
                  className="w-12 h-12 shadow-lg  cursor-pointer"
                />
              </AdvancedMarker>
            )}
          </Map>
        )}
      </div>
    </div>
  );
};
export default StreetTab;
