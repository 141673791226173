import React from 'react';
import PropTypes  from 'prop-types';

const IconCircular = ({ src, alt, className }) => {
  return (
    <div className={`flex overflow-hidden ${className}`}>
      <img src={src} alt={alt} className="rounded-full border border-secondary-light shadow-sm w-full h-full" />
    </div>
  );
};


IconCircular.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string
};


export default IconCircular;