import React, { useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getListingOverviewAction } from "../../store/Listing/listingAction";
import DetailContent from "./DetailContent";
import DetailContentAirbnb from "./DetailContentAirbnb";
import DetailContentIdealista from "./DetailContentIdealista";

// Reusable Modal Component
const ListingDetailFullscreen = (props) => {
  const { open, onClose, property } = props;
  const dispatch = useDispatch();
  const listingOverview = useSelector((state) => state.hotels.listingOverview);

  useEffect(() => {
    if (
      property?.properties?.id !== listingOverview?.listingId &&
      (property?.properties?.accommodationType === "Hotel" ||
        property?.properties?.accommodationType === "Shortterm")
    ) {
      dispatch(
        getListingOverviewAction(
          {
            listingId: property?.properties?.id,
          },
          property?.properties?.accommodationType
        )
      );
    }
  }, []);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="relative bg-[#fff] shadow-lg p-4 w-[60%] m-auto"
        style={{
          height: `calc(100vh - 30px)`,
        }}
      >
        <div className="flex justify-end">
          <div
            onClick={onClose}
            className="flex cursor-pointer gap-1 text-[15px] rounded-lg bg-[#D43838] h-[30px] w-[60px]  text-[#fff] justify-center items-center px-2"
          >
            <div>Close</div>
            <Close />
          </div>
        </div>
        {/* Content Section */}
        <div className="w-full flex h-full">
          <div
            className=" overflow-y-auto hide-scrollbar"
            style={{
              height: `calc(100vh - 70px)`,
            }}
          >
            {["rent", "buy"].includes(property?.properties?.listingKey) ? (
              <DetailContentIdealista {...props} isFullScreen={true} />
            ) : property?.properties?.listingKey === "monthlyStay" ? (
              <DetailContentAirbnb {...props} isFullScreen={true} />
            ) : (
              <DetailContent {...props} isFullScreen={true} />
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ListingDetailFullscreen;
