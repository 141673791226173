import { useDispatch } from "react-redux";
import {
  getListingDetailAction,
  getlistingNearbyPlaceAction,
  getlistingPhotosAction,
  swipeScreenActiveListing,
} from "../store/Listing/listingAction";

export default function useListing() {
  const dispatch = useDispatch();

  function showDetail(activeListing, isSaveListing = false) {
    if (activeListing) {
      if (isSaveListing) {
        dispatch(swipeScreenActiveListing(activeListing));
      }
      dispatch(
        getListingDetailAction(
          {
            checkin_date: activeListing?.properties?.checkinDate,
            listingId: activeListing?.properties?.id,
            checkout_date: activeListing?.properties?.checkoutDate,
            country: activeListing?.properties?.country,
          },
          activeListing?.properties?.accommodationType,
          isSaveListing
        )
      );
      if (
        activeListing?.properties?.accommodationType === "Hotel" ||
        activeListing?.properties?.accommodationType === "Shortterm"
      ) {
        dispatch(
          getlistingNearbyPlaceAction({
            id: activeListing?.properties?.id,
          })
        );
        dispatch(getlistingPhotosAction(activeListing?.properties?.id));
      }
    }
  }
  return { showDetail };
}
