import React from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { listType } from "../../common/Helpers";
import dayjs from "dayjs";

const AirbnbInfo = ({ property, majorLocation }) => {
  const {
    propertyName,
    propertyClass,
    listingKey,
    review_nr,
    review_score_word,
    price,
    listingParamOverrides,
    pricingQuote,
    listing,
  } = property?.properties || "";
  const { checkin, checkout } = listingParamOverrides;
  const daysBetween = dayjs(checkout).diff(dayjs(checkin), "day");
  const actualPrice = price.replace(/\D/g, "");
  const pricePerDay = Number(actualPrice) / daysBetween;

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="text-[32px] font-medium text-[#B80306]">
                {propertyName}
              </div>
              {propertyClass && (
                <div className="flex items-center">
                  {[...Array(propertyClass)].map((_, i) => (
                    <StarIcon
                      key={i}
                      fontSize="small"
                      style={{ color: "#FFD700" }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <div>
                <div className="text-[18px] font-normal text-[#050505]">
                  {review_score_word}
                </div>
                <div className="text-[16px] font-normal text-[#050505]">
                  {review_nr} reviews
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-5 mt-6">
          <Button
            variant="contained"
            style={{
              background: listType?.[listingKey]?.color,
              borderRadius: 10,
            }}
          >
            {Math.round(pricePerDay)} $/night
          </Button>
          <div className="flex items-center gap-2">
            <div>
              {
                pricingQuote?.structuredStayDisplayPrice?.explanationData
                  ?.priceDetails?.[0]?.items?.[0]?.description
              }
            </div>
            <a
              href={listing?.webURL}
              target="blank"
              className="bg-[#2d6adc] font-medium no-underline rounded-10 text-[#fff] px-3 py-2 text-[12px] font-sans"
            >
              Availability
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AirbnbInfo;
