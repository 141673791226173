import React, { useEffect, useState, useCallback } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
import LMMap from "../../components/LeftCol/Map/Map";
import Swipe from "../Swipe/Swipe";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { setLayoutAction } from "../../store/Layout/layoutAction";
import useFilter from "../../hooks/useFilter";
import usePosition from "../../hooks/usePosition";
import { setCoordinates } from "../../store/Filter/filterReducers";
import { setCenterAction } from "../../store/Map/mapActions";
import { useSpring, animated } from "@react-spring/web";
import { useGesture } from "react-use-gesture";
import { baloo } from "../../assets/customIcon";
import FilterTabs from "../Saved/FilterTabs";
import List from "../Saved/List";
import useSupabase from "../../hooks/useSupabase";
import { getCurrentUser, getSavedFilter } from "../../common/Helpers";
import {
  getBlackList,
  getUserLocationsAction,
} from "../../store/User/userAction";
import Area from "../Area/Area";

const apiKey = "AIzaSyDBfPZBgU_xAaRw_-0VU1hv1TyoYR_nV6E";

const MobileHome = React.memo(() => {
  const currentUser = getCurrentUser();
  const { fetchUser } = useSupabase();
  const [swipeDirection, setSwipeDirection] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [{ rotate }, api] = useSpring(() => ({ rotate: 0 }));
  const layout = useSelector((state) => state.layout.col);
  const dispatch = useDispatch();
  useFilter();

  const { latitude, longitude, error } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getBlackList(currentUser?.id));
      dispatch(getUserLocationsAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude, dispatch]);

  const handleLayout = useCallback(
    (layout) => {
      setLayoutAction(dispatch, layout);
    },
    [dispatch]
  );

  const bind = useGesture({
    onDrag: ({ movement: [mx], direction: [xDir], down }) => {
      const isSwiping = Math.abs(mx) > 10; // Trigger if swipe movement exceeds 10px

      // If swiping
      if (isSwiping) {
        // Determine the direction of swipe
        const newDirection = xDir > 0 ? "right" : "left";

        // Only change direction if it is different from the previous direction
        if (swipeDirection !== newDirection) {
          setSwipeDirection(newDirection);
          api.start({
            rotate: newDirection === "right" ? 45 : -45, // Tilt based on swipe movement
            immediate: true,
          });
        }
      }
    },
    onDragEnd: () => {
      api.start({ rotate: 0 });
      setSwipeDirection("");
    },
  });

  function onActiveTab(value) {
    setActiveTab(value);
  }
  return (
    <APIProvider apiKey={apiKey}>
      <div className="flex flex-col h-screen w-full">
        <div
          className={`bg-secondary-light w-full ${
            layout === 3 ? "h-[97%]" : "h-[87.4%]"
          }`}
        >
          {layout === 0 && (
            <div className="h-full w-full">
              <LMMap />
            </div>
          )}
          {layout === 1 && (
            <div className="flex flex-col h-full w-full  bg-secondary-l0 relative">
              <Swipe balooDir={swipeDirection} isMobileScreen={true} />
              <animated.img
                {...bind()}
                alt="baloo"
                src={baloo}
                className="absolute"
                style={{
                  left: "calc(50% - 50px)",
                  top: "calc(50% - 50px)",
                  width: "100px",
                  height: "100px",
                  transformOrigin: "bottom center", // Set rotation anchor point to the bottom
                  rotate, // Apply rotation based on swipe direction
                }}
              />
            </div>
          )}
          {layout === 2 && (
            <div className="flex flex-col w-full h-full gap-6 bg-secondary-l0">
              <FilterTabs onActiveTab={onActiveTab} />
              <List activeTab={activeTab || "all"} />
            </div>
          )}
        </div>
        <div className="flex flex-row w-full h-[5%] z-20">
          <Button
            variant="contained"
            className="flex flex-grow"
            onClick={() => handleLayout(0)}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            className="flex flex-grow"
            onClick={() => handleLayout(1)}
          >
            Swipe
          </Button>
          <Button
            variant="contained"
            className="flex flex-grow"
            onClick={() => handleLayout(2)}
          >
            Saved
          </Button>
        </div>
      </div>
    </APIProvider>
  );
});

export default MobileHome;
