import React from "react";
import { Button, CircularProgress } from "@mui/material";

const MuiButton = ({
  children,
  variant = "contained",
  color = "primary",
  onClick,
  className,
  isLoading,
  ...props
}) => {
  const buttonClasses = `
    ${className}
    !w-full
    !bg-green 
    hover:!bg-green-100 
    !text-secondary 
    !font-semibold 
    !rounded-full
  `;
  return (
    <Button
      disabled={isLoading}
      variant={variant}
      color={color}
      onClick={onClick}
      className={buttonClasses}
      {...props}
    >
      {isLoading ? (
        <span className="flex items-center gap-2">
          <CircularProgress size={25} />
          {children}...
        </span>
      ) : (
        children
      )}
    </Button>
  );
};

export default MuiButton;
