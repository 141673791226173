import { createTheme } from '@mui/material';
import { colors } from '../tailwind/colors';

// Define your custom theme
const materialTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary.DEFAULT,
      light: colors.primary.light,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.DEFAULT,
      light: colors.secondary.l0,
      dark: colors.secondary.dark,
      lighter: colors.secondary.lighter
    },
    error: {
      main: colors.accent.DEFAULT,
    },
    warning: {
      main: colors.warn.DEFAULT,
      dark: colors.warn.dark,
      darker: colors.warn.darker,
    },
    success: {
      main: colors.success.DEFAULT,
      light: colors.success.light,
      dark: colors.success.dark,
    },
    light: {
      default: colors.secondary.l0,
    },
    background: {
      default: colors.secondary.l0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: colors.primary.DEFAULT,
          '&:hover': {
            backgroundColor: colors.primary.dark,
          },
        },
        containedSecondary: {
          backgroundColor: colors.secondary.DEFAULT,
          '&:hover': {
            backgroundColor: colors.secondary.dark,
          },
        },
        containedLight: {
          backgroundColor: colors.secondary.l0,
          '&:hover': {
            backgroundColor: colors.secondary.l2,
          },
        },
        outlinedPrimary: {
          borderColor: colors.primary.DEFAULT,
          color: colors.primary.DEFAULT,
          '&:hover': {
            borderColor: colors.primary.dark,
            color: colors.primary.dark,
          },
        },
        outlinedSecondary: {
          borderColor: colors.secondary.DEFAULT,
          color: colors.secondary.DEFAULT,
          '&:hover': {
            borderColor: colors.secondary.dark,
            color: colors.secondary.dark,
          },
        },
      },
    },
  },
});

export default materialTheme;
