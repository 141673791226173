import React from "react";

const ThingsToKnow = ({ sections }) => {
  const houseRules = sections?.policies?.houseRules;
  const safety = sections?.policies?.previewSafetyAndProperties;
  const cancellationPolicies =
    sections?.policies?.cancellationPolicies?.[0]?.subtitles;
  return (
    <div className="grid grid-cols-3 gap-8 bg-white p-6 ">
      {/* House Rules Section */}
      <div>
        <h3 className="text-md font-sans mb-2">House rules</h3>
        <div className="text-xs text-gray-600 space-y-1 flex flex-col gap-2">
          {houseRules?.length > 0 &&
            houseRules.map((rule) => <div>{rule?.title}</div>)}
        </div>
      </div>

      {/* Safety & Property Section */}
      <div>
        <h3 className="text-md font-sans mb-2">Safety & property</h3>
        <div className="text-xs text-gray-600 space-y-1 flex flex-col gap-2">
          {safety?.length > 0 && safety.map((rule) => <div>{rule?.title}</div>)}
        </div>
      </div>

      {/* Cancellation Policy Section */}
      <div>
        <h3 className="text-md font-sans mb-2">Cancellation policy</h3>
        <div className="text-xs text-gray-600 space-y-1 flex flex-col gap-2">
          <div>
            Free cancellation for 48 hours. Cancel before Jan 12 for a partial
            refund.
          </div>
          {cancellationPolicies?.length > 0 &&
            cancellationPolicies.map((rule) => <div>{rule}</div>)}
        </div>
      </div>
    </div>
  );
};

export default ThingsToKnow;
