import { useEffect, useCallback } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const NearbyPlacesFetcher = ({ placeType = null, onPlacesFetched }) => {
  const selectedPlace = useSelector((state) => state.map.selectedPlace); // Get map center from state
  const placesLib = useMapsLibrary("places"); // Load the Google Places library
  const map = useMap(); // Get map instance from hook
  // Define a debounced search function
  const searchPlaces = useCallback(
    debounce(async () => {
      if (!placesLib || !map || !placeType || !window.google) {
        onPlacesFetched([])
        return;
      } 
      try {
        const bounds = selectedPlace
          ? new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(selectedPlace.south, selectedPlace.west), // Southwest corner
              new window.google.maps.LatLng(selectedPlace.north, selectedPlace.east)   // Northeast corner
            )
          : null;

        const request = {
          textQuery: typeof placeType === "object" ? placeType.type : placeType,
          locationBias: bounds || map.getBounds() || { lat: map.getCenter().lat(), lng: map.getCenter().lng() },
          useStrictTypeFiltering: true,
          fields: [
            "id",
            "accessibilityOptions",
            "addressComponents",
            "adrFormatAddress",
            "attributions",
            "businessStatus",
            "displayName",
            "displayNameLanguageCode",
            "formattedAddress",
            "googleMapsURI",
            "hasCurbsidePickup",
            "hasDelivery",
            "hasDineIn",
            "hasTakeout",
            "isReservable",
            "servesBreakfast",
            "servesLunch",
            "servesDinner",
            "servesBeer",
            "servesWine",
            "servesBrunch",
            "servesVegetarianFood",
            "iconBackgroundColor",
            "svgIconMaskURI",
            "internationalPhoneNumber",
            "location",
            "nationalPhoneNumber",
            "regularOpeningHours",
            "parkingOptions",
            "paymentOptions",
            "photos",
            "plusCode",
            "priceLevel",
            "rating",
            "reviews",
            "types",
            "userRatingCount",
            "utcOffsetMinutes",
            "viewport",
            "websiteURI",
            "editorialSummary",
            "editorialSummaryLanguageCode",
            "allowsDogs",
            "hasLiveMusic",
            "hasMenuForChildren",
            "hasOutdoorSeating",
            "hasRestroom",
            "hasWiFi",
            "isGoodForChildren",
            "isGoodForGroups",
            "isGoodForWatchingSports",
            "servesCocktails",
            "servesCoffee",
            "servesDessert",
            "primaryType",
            "primaryTypeDisplayName",
            "primaryTypeDisplayNameLanguageCode",
            "evChargeOptions",
            "fuelOptions"
        ],
        };

        //@ts-ignore
        const { places } = await placesLib.Place.searchByText(request);
        if (places && places.length) {
          const formattedPlaces = places.map((item) => ({
            ...item.toJSON(),
            location: item.location,
            reviews: item.reviews,
            opening_hours: item.regularOpeningHours,
            icon_background_color: item.iconBackgroundColor,
            rating: item.rating,
            displayName: item.displayName,
            photos: item.photos,
            customIcon: placeType.icon,
            formattedAddress: item.formattedAddress
          }));
          onPlacesFetched(formattedPlaces); // Return places via callback
        } else {
          onPlacesFetched([]); // Return empty if no results
        }
      } catch (error) {
        console.error("Error fetching places:", error);
        onPlacesFetched([]); // Return empty on error
      }
    }, 300), // Set debounce delay to 300ms
    [placesLib, map, placeType, selectedPlace, onPlacesFetched] // Add dependencies
  );

  // Effect to handle place search
  useEffect(() => {
    searchPlaces();

    return () => {
      searchPlaces.cancel(); // Cancel the debounced search on cleanup
    };
  }, [searchPlaces, placeType]);

  return null; // Renderless component, returns nothing
};

export default NearbyPlacesFetcher;
