import React from "react";
import { Sheet } from "react-modal-sheet";
import PropTypes from "prop-types";
import { animated, useSpring } from "@react-spring/web";

const CustomBottomSheet = ({
  isOpen,
  onDismiss,
  snapPoints = [1, 0.75, 0.5],
  children,
  contentHeight = "76%", // You can pass a custom height here or use a default one
}) => {
  // Folding animation with react-spring
  const animationProps = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
    config: { duration: 600, tension: 150, friction: 20 },
  });

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onDismiss}
      snapPoints={snapPoints}
      detent="content-height"
    >
      <Sheet.Container
        style={{
          position: "absolute",
          width: "100%",
          height: contentHeight,
          bottom: 0,
        }}
      >
        <Sheet.Header />
        <Sheet.Content>
          <animated.div style={animationProps} className="h-full">
            <div className="sheet-content hide-scrollbar">{children}</div>
          </animated.div>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={onDismiss} />
    </Sheet>
  );
};

CustomBottomSheet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  snapPoints: PropTypes.array,
  children: PropTypes.node.isRequired,
  contentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Custom height prop
};

export default CustomBottomSheet;
