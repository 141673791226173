import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import FilterChip from "../../FilterChip";
import PriceRangeSlider from "../../BudgetRange/PriceRangeSlider";
import FilterCounter from "../../FilterCounter";
import { listType } from "../../../common/Helpers";

const homeTypes = [
  { label: "Houses", name: "homes" },
  { label: "Apartments", name: "bedrooms" },
  { label: "New Homes", name: "newDevelopments" },
];
const floors = [
  { label: "Top floor", name: "topFloor" },
  { label: "Middle floors", name: "intermediateFloor" },
  { label: "Ground floor", name: "groundFloor" },
];
const moreFilters = [
  { label: "Private bathroom", name: "privateToilet" },
  { label: "Air condition", name: "airConditioning" },
  { label: "Gay partners", name: "gayPartners" },
  { label: "Has house keeper", name: "hasHouseKeeper" },
  { label: "Elevator", name: "elevator" },
  { label: "Terrace", name: "terrace" },
  { label: "Swimming pool", name: "swimmingPool" },
  { label: "Garden", name: "garden" },
  { label: "Luxury", name: "luxury" },
  { label: "Builtin wardrobes", name: "builtinWardrobes" },
  { label: "Store room", name: "storeRoom" },
  { label: "Children allowed", name: "childrenAllowed" },
];
const RentBuyAdvanceFilter = ({ onChange, filterKey, minPrice, maxPrice }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(filters?.[filterKey] || "");
  console.log("filtersssss", updatedFilters);

  function handleChange(e, checked, value) {
    const name = e.target?.name || "";
    console.log("changessssss", name, checked, value);
    let currentFilters = updatedFilters;
    if (value) {
      if (name === "propertyType" && value === "any") {
        delete currentFilters?.[name];
      } else {
        currentFilters = { ...currentFilters, [name]: value };
      }
    } else {
      if (!checked) {
        if (name in currentFilters) {
          delete currentFilters[name];
        }
      } else {
        currentFilters = { ...currentFilters, [name]: checked };
      }
    }
    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }
  function handleChangeFloor(e, checked, value) {
    const name = value || "";
    let currentFilters = updatedFilters;
    let floor = currentFilters?.floorHeights
      ? currentFilters?.floorHeights.split(",")
      : [];
    if (checked) {
      // If checked is true, add the name to the array (if it's not already included)
      if (!floor.includes(name)) {
        floor.push(name);
      }
    } else {
      // If checked is false, remove the name from the array
      floor = floor.filter((flor) => flor !== name);
    }

    // Convert the array back into a comma-separated string
    const updatedFloor = floor.join(",");
    currentFilters = { ...currentFilters, floorHeights: updatedFloor };
    if (!updatedFloor) {
      delete currentFilters.floorHeights;
    }

    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }

  const handleCounterChange = (value) => {
    console.log("Updated count:", value);
  };

  function handleChangePrice(e, value) {
    let currentFilters = updatedFilters;
    currentFilters = {
      ...currentFilters,
      minPrice: value[0],
      maxPrice: value[1],
    };

    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }

  return (
    <div className="  w-full ">
      <PriceRangeSlider
        min={minPrice}
        max={maxPrice}
        step={1}
        onChange={handleChangePrice}
        defaultValue={[
          updatedFilters?.minPrice || minPrice,
          updatedFilters?.maxPrice || maxPrice,
        ]}
        filterKey={filterKey}
        label={"Property Price"}
        leftPrefix={"$"}
      />
      <FilterChip
        options={homeTypes}
        onSelect={handleChange}
        defaultSelected={updatedFilters?.propertyType}
        filterKey={filterKey}
        label={"Property Type"}
        fieldName={"propertyType"}
      />
      <PriceRangeSlider
        min={10}
        max={60}
        step={10}
        onChange={() => console.log()}
        defaultValue={[20, 50]}
        filterKey={filterKey}
        label={"Property Size"}
        rightPrefix="sqft"
      />
      <Box className="px-4 pt-4">
        <div className="my-2 text-[#000]/80 font-semibold">Rooms and Beds</div>
        <FilterCounter
          label="Bedrooms"
          min={0}
          max={5}
          defaultValue={2}
          onChange={handleCounterChange}
        />
        <FilterCounter
          label="Beds"
          min={0}
          max={5}
          defaultValue={2}
          onChange={handleCounterChange}
        />
        <FilterCounter
          label="Bathrooms"
          min={0}
          max={5}
          defaultValue={2}
          onChange={handleCounterChange}
        />
      </Box>
      <FilterChip
        options={floors}
        onSelect={handleChangeFloor}
        defaultSelected={updatedFilters?.floorHeights}
        filterKey={filterKey}
        label={"Floor"}
        fieldName={"floorHeights"}
      />
      <Box className="p-4">
        <div className="mb-4 text-[#000] font-semibold">More Filters</div>
        <Box className="flex flex-wrap gap-2">
          {moreFilters.map((option) => (
            <div
              key={option?.name}
              onClick={() =>
                handleChange(
                  { target: { name: option.name } },
                  updatedFilters?.[option.name] ? false : true
                )
              }
              style={{ borderColor: listType[filterKey].color }}
              className={`rounded-[10px] cursor-pointer border border-solid px-4 py-2  text-xs transition ${
                updatedFilters?.[option.name]
                  ? `${listType[filterKey].bg} text-[#fff] shadow-md`
                  : "bg-white text-[#000]/80"
              }`}
            >
              {option?.label}
            </div>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default RentBuyAdvanceFilter;
