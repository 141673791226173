import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FilterCounter = ({
  label,
  min = 0,
  max = 10,
  defaultValue = 0,
  onChange,
}) => {
  const [count, setCount] = useState(defaultValue);

  const increment = () => {
    if (count < max) {
      const newCount = count + 1;
      setCount(newCount);
      if (onChange) onChange(newCount);
    }
  };

  const decrement = () => {
    if (count > min) {
      const newCount = count - 1;
      setCount(newCount);
      if (onChange) onChange(newCount);
    }
  };

  return (
    <Box className="flex items-center justify-between gap-4 mb-4">
      <div className="text-[#000]">{label}</div>
      <Box className="flex items-center gap-6">
        <IconButton
          onClick={increment}
          className="!border !border-solid !border-[#0000008a] !rounded-full "
        >
          <AddIcon />
        </IconButton>
        <div className="text-[#0000008a]">{count}</div>
        <IconButton
          onClick={decrement}
          className="!border !border-solid !border-[#0000008a] !rounded-full "
        >
          <RemoveIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FilterCounter;
