import React from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm, listType } from "../../common/Helpers";

const HotelInfo = ({ property, majorLocation }) => {
  const {
    propertyName,
    propertyClass,
    listingKey,
    review_nr,
    review_score,
    review_score_word,
    price,
  } = property?.properties || "";
  const geometry = property?.geometry;

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="text-[32px] font-medium text-[#2D6ADC]">
                {propertyName}
              </div>
              {propertyClass && (
                <div className="flex items-center">
                  {[...Array(propertyClass)].map((_, i) => (
                    <StarIcon
                      key={i}
                      fontSize="small"
                      style={{ color: "#FFD700" }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <div>
                <div className="text-[18px] font-normal text-[#050505]">
                  {review_score_word}
                </div>
                <div className="text-[16px] font-normal text-[#050505]">
                  {review_nr} reviews
                </div>
              </div>
              <div className="!bg-[#1E4BB1] !text-[#fff] px-2 py-1  text-sm font-bold rounded-md h-full">
                {review_score}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-5 mt-6">
          <Button
            variant="contained"
            style={{
              background: listType?.[listingKey]?.color,
              borderRadius: 10,
            }}
          >
            {Math.round(price)} $/night
          </Button>
          <div className="flex items-center">
            <div className="w-10 h-10 ">
              <IconCircular
                alt={"locaton"}
                key={"location"}
                src={
                  majorLocation?.image || "https://picsum.photos/200?random=5"
                }
              />
            </div>
            <DirectionIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light">
              {getDistanceFromLatLonInKm(
                majorLocation?.latitude,
                majorLocation?.longitude,
                geometry?.coordinates?.[0],
                geometry?.coordinates?.[1]
              ).toFixed(1)}{" "}
              km to {majorLocation?.locationName}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsWalkIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light">4 min</span>
          </div>
          <div className="flex items-center">
            <DirectionsCarIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light text-[#291D1D]">
              30 min
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default HotelInfo;
