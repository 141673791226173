import React, { useRef, useState } from "react";
import { Card, CardMedia, Typography, Popover } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteMylocationAction,
  updateMylocationAction,
} from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";

const MylocationList = ({
  myLocation,
  toggleLocation,
  setToggleAddModal,
  isOpenAddModal,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const buttonRefs = useRef([]);
  const currentUser = getCurrentUser();

  const handleLongPressStart = (e, index, location) => {
    e.preventDefault();
    const timeout = setTimeout(() => {
      setAnchorEl(buttonRefs.current[index]);
      setSelectedLocation(location);
    }, 600); // 600ms for long press
    setLongPressTimeout(timeout);
  };

  const handleLongPressEnd = () => {
    if (longPressTimeout) {
      clearTimeout(longPressTimeout);
      setLongPressTimeout(null);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedLocation(null);
  };

  const handleMajorLocation = () => {
    dispatch(
      updateMylocationAction(currentUser?.id, selectedLocation?._id, {
        isMajor: true,
      })
    );
    handleClosePopover();
  };

  const handleDeleteLocation = () => {
    dispatch(deleteMylocationAction(currentUser?.id, selectedLocation?._id));
    handleClosePopover();
  };

  const toggleMyLocation = (location) => {
    toggleLocation(location);
  };

  const open = Boolean(anchorEl);
  const id = open ? "long-press-popover" : undefined;

  return (
    <div className="flex-1 h-full items-center flex-col justify-center w-[90%] overflow-y-auto hide-scrollbar">
      <div
        onClick={() => setToggleAddModal(!isOpenAddModal)}
        className={` bg-[#F3E7E7] flex flex-col justify-center cursor-pointer rounded-[5px] shadow text-center mb-3 w-full h-full max-h-[94px] max-w-[142px]`}
      >
        <div className="text-[34px]">+</div>
        <div className="text-[18px]">Add location</div>
      </div>
      {myLocation.map((location, index) => {
        return (
          <div
            className="flex flex-col gap-1 self-center cursor-pointer w-full items-center max-h-[94px] max-w-[142px]"
            key={index}
          >
            <Card
              ref={(el) => (buttonRefs.current[index] = el)} // Assign ref to each card
              onMouseDown={(e) => handleLongPressStart(e, index, location)}
              onMouseUp={handleLongPressEnd}
              onTouchStart={(e) => handleLongPressStart(e, index, location)}
              onTouchEnd={handleLongPressEnd}
              onClick={() => toggleMyLocation(location)}
              sx={{
                borderRadius: "5px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fill",
                }}
                image={location?.image}
                alt={location.locationName}
              />
            </Card>
            <Typography
              fontWeight={"medium"}
              className="text-center"
              variant="caption"
            >
              {location.locationName}
            </Typography>
          </div>
        );
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="p-4">
          <Typography
            onClick={handleMajorLocation}
            className="cursor-pointer !text-xs hover:text-gray-300 mb-2"
          >
            Major Location
          </Typography>
          <Typography
            onClick={handleDeleteLocation}
            className="cursor-pointer !text-xs hover:text-red-800"
          >
            Delete Location
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default MylocationList;
