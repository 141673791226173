import React, { useState } from "react";
import Button from "@mui/material/Button";
import { typeMapping } from "../../common/Helpers";

const FilterTabs = ({ onActiveTab }) => {
  const [activeTab, setActiveTab] = useState("all"); // Default active tab
  const activeListingType = typeMapping(activeTab);
  const tabs = [
    { label: "All", value: "all" },
    { label: "Per Night", value: "Hotel" },
    { label: "Monthly Stay", value: "monthlyStay" },
    { label: "Rent", value: "rent" },
    { label: "Buy", value: "buy" },
  ];

  const handleTabChange = (value) => {
    setActiveTab(value);
    onActiveTab(value);
  };

  return (
    <div className="flex justify-start w-full gap-[1px] flex-nowrap overflow-x-auto hide-scrollbar h-[35px] bg-[#7FA9DB]">
      {tabs.map((tab) => {
        return (
          <Button
            key={tab.value}
            className="flex-1"
            onClick={() => handleTabChange(tab.value)}
            sx={{
              backgroundColor:
                activeTab === tab.value ? activeListingType?.tabBg : "#ffffff", // Orange for active, white for inactive
              color:
                activeTab === tab.value ? activeListingType?.color : "#000000", // White text for active, black for inactive
              "&:hover": {
                backgroundColor:
                  activeTab === tab.value
                    ? activeListingType?.tabBg
                    : "#f0f0f0", // Slightly darker orange on hover for active
              },
              cursor: "pointer",
              padding: "6px 8px",

              whiteSpace: "nowrap",
            }}
          >
            <div className="text-[12px]">{tab.label}</div>
          </Button>
        );
      })}
    </div>
  );
};

export default FilterTabs;
