import { useState } from "react";

const useForm = (initialValues) => {
  const [formState, setFormState] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const resetForm = () => setFormState(initialValues);

  return {
    formState,
    handleChange,
    resetForm,
  };
};

export default useForm;
