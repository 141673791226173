import React, { useState } from "react";
import Logo from "../Logo";
import IconCircular from "../IconCircular/IconCircular";
import { FiMoreVertical } from "react-icons/fi";
import { Popover, Divider } from "@mui/material";
import { supabase } from "../../utils/helpers/supabaseClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setActiveScreenAction } from "../../store/Common/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";
import searchIcon from "../../assets/icons/svg/search.svg";
import handIcon from "../../assets/icons/svg/hand.svg";
import heartIcon from "../../assets/icons/svg/heart.svg";
import { TravelExplore } from "@mui/icons-material";

const Header = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeScreen, setActiveScreen] = useState("search");
  const layout = useSelector((state) => state.layout.col);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Handle logout success if needed
      localStorage.removeItem("user");
    }
    handleClose(); // Close the popover after logout
  };

  function handleChangePass() {
    navigate("/reset-password");
  }
  function handleToggleScreen() {
    setActiveScreen(activeScreen === "save" ? "search" : "save");
    dispatch(
      setActiveScreenAction(activeScreen === "save" ? "search" : "save")
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (layout === 3) {
    return null;
  }
  const activeStyle = { border: "4px solid #BA18FF" };
  console.log("locationsssss", location);
  return (
    <header className="flex flex-col bg-gradient-to-r from-[#1B3FFC] to-[#1BD8FE] h-[75px] min-h-[75px] text-white px-4">
      <div className="flex flex-row flex-1 w-full justify-between items-center">
        <div className="flex items-center gap-2">
          <Link
            to={"/home"}
            className="flex flex-row items-center gap-3 cursor-pointer no-underline"
          >
            <Logo classes={"w-12 h-12 cur"} />
            <span className="text-secondary-l0 font-semibold !no-underline">
              LifeMatch
            </span>
          </Link>
        </div>

        {/* Center-aligned MapSearch with max width */}
        <div className="flex-1 mx-4 flex justify-center items-center">
          {/* <div className="w-full max-w-[400px]">
            <MapSearch onPlaceChanged={() => console.log("Place changed")} />
          </div> */}
          <div
            style={location.pathname === "/home" ? activeStyle : {}}
            className="flex justify-center items-center p-[10px] gap-1 rounded-full bg-[#fff] text-[18px] text-[#0C0425] font-light"
          >
            <TravelExplore />
            <div>New search</div>
          </div>
          <div className="w-3 h-1 bg-[#E674F5]"></div>
          <div
            style={location.pathname === "/listing" ? activeStyle : {}}
            className="flex justify-center items-center p-[10px] gap-1 rounded-full bg-[#fff] text-[18px] text-[#0C0425] font-light"
          >
            <img src={searchIcon} alt="search" />
            <div>Last search</div>
          </div>
          <div className="w-3 h-1 bg-[#E674F5]"></div>
          <div className="flex justify-center items-center p-[10px] gap-1 rounded-full bg-[#fff] text-[18px] text-[#0C0425] font-light">
            <img src={handIcon} alt="hand" />
            <div>Swipe</div>
          </div>
          <div className="w-3 h-1 bg-[#E674F5]"></div>
          <div className="flex justify-center items-center p-[10px] gap-1 rounded-full bg-[#fff] text-[18px] text-[#0C0425] font-light">
            <img src={heartIcon} alt="heart" />
            <div>My list</div>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="flex flex-row items-center gap-3">
            <div className="flex justify-center items-center gap-2">
              <IconCircular
                alt={"language"}
                className={"w-10 h-10 cursor-pointer"}
                key={"language"}
                src={"https://picsum.photos/200"}
              />
              <div>
                <div className="text-[#fff] font-bold text-xs">
                  {currentUser?.user_metadata?.name}
                </div>
                <div className="text-[#fff] text-xs">Premium level</div>
              </div>
            </div>
            <FiMoreVertical
              className="text-secondary-l0 cursor-pointer"
              size={30}
              onClick={handleMoreClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="flex flex-col p-2">
                <div
                  onClick={handleChangePass}
                  className="text-xs cursor-pointer mb-2"
                >
                  Change password
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  onClick={handleLogout}
                  className="text-xs cursor-pointer mb-2"
                >
                  Logout
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
