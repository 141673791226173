
import { useEffect, useMemo, useRef, useCallback } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import "./places.css";
import { useSelector } from "react-redux";

const CurrentLocationMarker = ({ markerLib }) => {
  const map = useMap();
  const markersRef = useRef(null); // Ref to store markers
  const currentLocation = useSelector((state) => state.map?.currentLocation);


  const createMarker = useCallback(
    (lat, lng) => {
        if (!map || !window.google) return [];
        console.log(lat, lng);
        const parentDiv = document.createElement("div");
        parentDiv.style.position = "relative";
        parentDiv.style.width = '20px';
        parentDiv.style.height = '20px';
        parentDiv.style.backgroundColor = 'rgba(255,255,255, 0.5)';
        parentDiv.style.borderRadius = '999px';
        parentDiv.style.border = '5px solid red';

        return new markerLib.AdvancedMarkerElement({
            map,
            position: { lat: lat, lng: lng },
            content: parentDiv,
            title: "A marker using a FontAwesome icon for the glyph.",
        });
    },
    [map, markerLib]
  );

  useEffect(() => {
    if (!map || !currentLocation) return;
    console.log(currentLocation);
    // Clear previous markers
    if(markersRef.current)
        markersRef.current.setMap(null);

    // Create new markers and add them to the markersRef
    const marker = createMarker(currentLocation.latitude, currentLocation.longitude);

    // Update the markers reference with new markers
    markersRef.current = marker;

    return () => {
        
      // Cleanup markers when component unmounts or when places change
      markersRef.current.setMap(null);
      markersRef.current = null;
    };
  }, [currentLocation, map, createMarker]);

  return null;
};

export default CurrentLocationMarker;
