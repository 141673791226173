import React from "react";
import Input from "../../components/Input/Input";
import MuiButton from "../../components/MuiButton/MuiButton";
import MobileSplash from "../Splash/MobileSplash";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";
import useSupabase from "../../hooks/useSupabase";
import useForm from "../../hooks/useForm";
import { Alert } from "@mui/material";
import SocialMediaProvider from "../../components/SocialMediaProvider/SocialMediaProvider";

function Login() {
  const { login, loginWithProvider, supabaseState } = useSupabase();
  const { formState, handleChange } = useForm({ email: "", password: "" });

  function userLogin() {
    login(formState);
  }

  return (
    <div className="flex  h-full ">
      <div className="w-[40%] mr-[0.5px]">
        <MobileSplash />
      </div>
      <div className="w-[60%]">
        <div className="bg-gradient-to-b from-primary to-primary-light h-full w-full flex flex-1 flex-col items-center justify-center gap-4">
          <Logo classes={"w-20 h-20"} />
          <div className="text-secondary-l0/90 text-[25px] font-light !drop-shadow-2xl">
            LifeMatch
          </div>
          <div className="flex flex-col gap-8 items-center  w-[50%] ">
            <div className="flex flex-col justify-between gap-6 w-full items-center">
              <div className="flex flex-col w-full gap-6">
                <Input
                  autoComplete="new-password"
                  label="Email"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  type={"email"}
                />
                <Input
                  autoComplete="new-password"
                  label="Password"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  type="password"
                />
                {supabaseState.signupError?.isError && (
                  <Alert severity="error">
                    {supabaseState.signupError?.message}
                  </Alert>
                )}
                <Link to={"/forget-password"} className="text-xs  !text-right">
                  <span className="!text-white"> Forget password</span>
                </Link>
                <div className="mt-2 w-full">
                  <MuiButton
                    onClick={userLogin}
                    isLoading={supabaseState.isProcessing}
                  >
                    Login
                  </MuiButton>
                </div>
              </div>
              <span className="flex flex-col self-center text-secondary-l0 !drop-shadow-2xl my-2">
                or Login with
              </span>
              <div className="flex items-center gap-6 justify-between bg-warn-100 rounded-full p-2">
                <SocialMediaProvider loginWithProvider={loginWithProvider} />
              </div>
            </div>
            <div className="text-secondary-l0">
              <Link
                to="/signup"
                className="text-secondary-l0 hover:underline no-underline text-[32px] font-light !drop-shadow-2xl"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
