import { useState } from "react";
// Import SVGs for arrows
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ImageSlider = ({ isFullscreen, listingMedia, toggleFullscreen }) => {
  const [selectedImage, setSelectedImage] = useState(""); // Default main image
  const [currentIndex, setCurrentIndex] = useState(0); // Keeps track of the current image index

  // Function to go to the next image
  const goToNext = () => {
    const updatedIndex = (currentIndex + 1) % listingMedia.length;
    setCurrentIndex(updatedIndex);
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  };

  // Function to go to the previous image
  const goToPrevious = () => {
    const updatedIndex =
      (currentIndex - 1 + listingMedia.length) % listingMedia.length;
    setCurrentIndex(updatedIndex);
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  };

  return (
    <div className="w-full">
      {/* Main Image */}
      <div className="flex gap-2 w-full">
        {!isFullscreen && (
          <div className="w-1/3">
            <div className="mb-2 w-full h-[139px]">
              <img
                src={
                  listingMedia?.[1]?.url_1440 ||
                  listingMedia?.[1]?.url ||
                  listingMedia?.[1]?.baseUrl
                }
                alt="Main Thumbnail"
                className="w-full h-full"
              />
            </div>
            <div className=" w-full h-[139px]">
              <img
                src={
                  listingMedia?.[2]?.url_1440 ||
                  listingMedia?.[2]?.url ||
                  listingMedia?.[2]?.baseUrl
                }
                alt="Main Thumbnail"
                className="w-full h-full"
              />
            </div>
          </div>
        )}
        <div
          className={isFullscreen ? "w-full   h-[515px]" : "w-full  h-[288px]"}
        >
          <img
            src={
              selectedImage ||
              listingMedia?.[0]?.url_1440 ||
              listingMedia?.[0]?.url ||
              listingMedia?.[0]?.baseUrl
            }
            alt="Main Thumbnail"
            className="w-full h-full"
          />
        </div>
      </div>

      {/* Thumbnail Carousel with Arrows */}
      <div className="w-full relative mt-[9px] ">
        {/* Left Arrow */}
        {isFullscreen && (
          <div
            onClick={goToPrevious}
            className="absolute bg-[#fff] left-1 top-1/2 transform -translate-y-1/2  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl"
          >
            <ArrowBackIosIcon className="ml-[7px]" />
          </div>
        )}
        <div className="w-full h-[90.21px] flex gap-[6px] overflow-x-auto hide-scrollbar">
          {listingMedia?.length > 0 &&
            listingMedia.map((image, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedImage(
                    image?.url_1440 || image?.url || image?.baseUrl
                  ); // Update main image
                  setCurrentIndex(index); // Update the current index
                }}
                className="cursor-pointer  rounded-[4px]"
              >
                <img
                  src={image?.url_1440 || image?.url || image?.baseUrl}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-[124px] h-[90px]  object-cover rounded-lg"
                />
              </div>
            ))}
        </div>
        {isFullscreen ? (
          <div
            onClick={goToNext}
            className="absolute bg-[#fff] right-1 top-1/2 transform -translate-y-1/2  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl"
          >
            <ArrowForwardIosIcon />
          </div>
        ) : (
          <div
            onClick={toggleFullscreen}
            className="absolute cursor-pointer w-[124px] h-[90px] bg-[#000]/40 text-[#fff] right-0 top-1/2 transform -translate-y-1/2 rounded-lg flex justify-center items-center"
          >
            +{listingMedia?.length - 7} photos
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;
