import { setCommonStore, setToggleDrawer } from "@/store/Common/commonReducers";

export const setActiveScreenAction = (screen) => async (dispatch) => {
  dispatch(setCommonStore({ activeScreen: screen }));
};

export const setActiveListAction = (activeList) => async (dispatch) => {
  dispatch(setCommonStore({ activeList: activeList }));
};
export const setActiveFilterView = (filterItem) => async (dispatch) => {
  dispatch(setCommonStore({ activeFilterView: filterItem }));
};
export const toggleDrawerAction = () => async (dispatch) => {
  dispatch(setToggleDrawer());
};
