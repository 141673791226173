import React from "react";
import { Link } from "react-router-dom";
import Input from "../../components/Input/Input";
import MuiButton from "../../components/MuiButton/MuiButton";
import Logo from "../../components/Logo";
import SocialMediaProvider from "../../components/SocialMediaProvider/SocialMediaProvider";
import { Alert } from "@mui/material";
import useSupabase from "../../hooks/useSupabase";
import useForm from "../../hooks/useForm";

function MobileRegistration() {
  const { signUp, loginWithProvider, supabaseState } = useSupabase();
  const { formState, handleChange } = useForm({
    email: "",
    password: "",
    name: "",
  });

  function registerUser() {
    signUp(formState);
  }

  return (
    <div
      className="h-full w-full flex flex-1 flex-col items-center justify-center gap-4"
      style={{
        background:
          "linear-gradient(to bottom, var(--color-primary), var(--color-primary-light))",
      }}
    >
      <Logo classes={"w-20 h-20"} />
      <div className="text-secondary-l0/90 text-[25px] font-light !drop-shadow-2xl">
        LifeMatch
      </div>
      <div className="flex flex-col gap-8 items-center w-[80%] ">
        <div className="flex flex-col justify-between gap-6 w-full items-center">
          <div className="flex flex-col w-full gap-6">
            <Input
              label="Name"
              name="name"
              value={formState.name}
              onChange={handleChange}
            />
            <Input
              autoComplete="new-password"
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              type={"email"}
            />
            <Input
              autoComplete="new-password"
              label="Password"
              name="password"
              value={formState.password}
              onChange={handleChange}
              type="password"
            />
            {supabaseState.signupError?.isError && (
              <Alert severity="error">
                {supabaseState.signupError?.message}
              </Alert>
            )}
            <div className="mt-2 w-full">
              <MuiButton
                onClick={registerUser}
                isLoading={supabaseState.isProcessing}
              >
                Sign Up
              </MuiButton>
            </div>
          </div>
          <span className="flex flex-col self-center text-secondary-l0 !drop-shadow-2xl my-2">
            or Sign Up with
          </span>
          <div className="flex flex-row gap-6 justify-between">
            <SocialMediaProvider loginWithProvider={loginWithProvider} />
          </div>
        </div>
        <div className="text-secondary-l0">
          <Link
            to="/"
            className="text-secondary-l0 hover:underline no-underline text-[32px] font-light !drop-shadow-2xl"
          >
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileRegistration;
