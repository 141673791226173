import React from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm, listType } from "../../common/Helpers";
import CustomChip from "../CustomChip";
import glassIcon from "../../assets/icons/svg/360-icon.svg";

const IdealistaInfo = ({ property, majorLocation, listingDetail }) => {
  const { propertyName, listingKey, price, detailedType } =
    property?.properties || "";
  const geometry = property?.geometry;
  const { priceInfo } = listingDetail || "";
  const { constructedArea, floor, lift, has360VHS } =
    listingDetail?.moreCharacteristics || "";

  return (
    <div className="w-full flex justify-center">
      {/* Details Section */}
      <div className=" p-4 flex flex-col justify-between w-[90%]">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="text-[32px] font-medium text-[#2D6ADC]">
                {propertyName}
              </div>
            </div>
            {has360VHS && (
              <div>
                <img src={glassIcon} alt="360" />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center gap-5 mt-6">
          <div className="flex items-center">
            <div className="w-10 h-10 ">
              <IconCircular
                alt={"locaton"}
                key={"location"}
                src={
                  majorLocation?.image || "https://picsum.photos/200?random=5"
                }
              />
            </div>
            <DirectionIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light">
              {getDistanceFromLatLonInKm(
                majorLocation?.latitude,
                majorLocation?.longitude,
                geometry?.coordinates?.[0],
                geometry?.coordinates?.[1]
              ).toFixed(1)}{" "}
              km to {majorLocation?.locationName}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsWalkIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light">4 min</span>
          </div>
          <div className="flex items-center">
            <DirectionsCarIcon className="text-[#2D6ADC]" />
            <span className="text-[18px] font-light text-[#291D1D]">
              30 min
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center mt-6">
          <CustomChip text={`${constructedArea} m²`} chipBgClr="#F0C0C0" />
          <CustomChip text={detailedType?.subTypology} chipBgClr="#E4F2BD" />
          <CustomChip
            text={`${floor ? `${floor} floor` : ""} ${
              lift ? "with" : "without"
            } lift`}
            chipBgClr="#C1E1E5"
          />
          <Button
            variant="contained"
            style={{
              background: listType?.[listingKey]?.color,
              borderRadius: 10,
            }}
          >
            {Math.round(priceInfo?.amount)} {priceInfo?.currencySuffix}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default IdealistaInfo;
